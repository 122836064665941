import {
  ArrowBack,
  Balance,
  Delete,
  Print,
  Restore,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";
import Navigation from "../Navigation";
import SmallFooter from "../ui/SmallFooter";
import AuthContext from "../contexts/AuthContext";
import Login from "../login/Login";
import Unavailable from "../ui/Unavailable";
import AlertContext from "../contexts/AlertContext";
import CustomDialog from "../ui/CustomDialog";

import { renderStateChip } from "./ReportTable";

const ReportDetail: React.FC = () => {
  const messageTime = 1500;
  const commentRowNumber = 3;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { refresh } = useContext(UiContext);
  const data = useParams();
  const api = useApiClient();
  const reportId = data.reportId;
  const clientId = data.companyId;
  const selectedMenu = data.menuId;
  const ctx = useContext(AuthContext);
  const { showSuccess } = useContext(AlertContext);

  const menuPath = "/app/" + clientId + "/admin/reports/" + reportId + "/";

  const [publicComment, setPublicComment] = useState<string>(null);
  const [publicAssessment, setPublicAssessment] = useState<string>(null);
  const [internalAssessment, setInternalAssessment] = useState<string>(null);

  const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);
  const [showCloseWithActionDialog, setShowCloseWithActionDialog] =
    useState<boolean>(false);
  const [showStartDialog, setShowStartDialog] = useState<boolean>(false);
  const [showDismissDialog, setShowDismissDialog] = useState<boolean>(false);
  const [showReopenDialog, setShowReopenDialog] = useState<boolean>(false);
  const [showPublicCommentDialog, setShowPublicCommentDialog] =
    useState<boolean>(false);
  const [showPublicAssessmentDialog, setShowPublicAssessmentDialog] =
    useState<boolean>(false);
  const [showInternalAssessmentDialog, setShowInternalAssessmentDialog] =
    useState<boolean>(false);
  const [getReportCall, getReportState] = useSafeAsync(api.getReportById);
  const [getReportHistoryCall, getReportHistoryState] = useSafeAsync(
    api.getReportHistory
  );
  const [getAllUsersForClientCall, getAllUsersForClientState] = useSafeAsync(
    api.findAllUsersByClientId
  );
  const [updateReportStatusCall, updateReportStatusState] = useSafeAsync(
    api.updateReportStatus
  );
  const [updateReportCategoryCall, updateReportCategoryState] = useSafeAsync(
    api.updateReportCategory
  );
  const [updateReportPriorityCall, updateReportPriorityState] = useSafeAsync(
    api.updateReportPriority
  );
  const [updateReportPublicCommentCall, updateReportPublicCommentState] =
    useSafeAsync(api.updateReportPublicComment);
  const [updateReportPublicAssessmentCall, updateReportPublicAssessmentState] =
    useSafeAsync(api.updateReportPublicAssessment);
  const [
    updateReportInternalAssessmentCall,
    updateReportInternalAssessmentState,
  ] = useSafeAsync(api.updateReportInternalAssessment);

  const isReportEditable = (status: string) => {
    if (status === "UNDER_REVIEW") {
      return true;
    } else {
      return false;
    }
  };

  const showReportDetailHandler = () => {
    navigate(menuPath + "0");
  };
  const showAssessmentHandler = () => {
    navigate(menuPath + "1");
  };
  const showHistoryHandler = () => {
    navigate(menuPath + "2");
  };

  const goBackHandler = () => {
    navigate("/app/" + clientId + "/admin");
  };

  const startReportDialogHandler = () => {
    setShowStartDialog(true);
  };

  const closeStartReportDialog = () => {
    setShowStartDialog(false);
  };

  const startReportHandler = async (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => {
    // zmenime status reportu
    const result = await updateReportStatusCall({
      clientId: clientId,
      reportId: reportId,
      status: "UNDER_REVIEW",
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  const closeReportDialogHandler = () => {
    if (getReportState.value.status !== "CLOSED") {
      setShowCloseDialog(true);
    }
  };

  const closeReportDialog = () => {
    setShowCloseDialog(false);
  };

  const closeReportHandler = async (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => {
    setShowCloseDialog(false);
    /* if (getReportState.value.status !== "UNDER_REVIEW") return; */
    // zmenime status reportu
    const result = await updateReportStatusCall({
      clientId: clientId,
      reportId: reportId,
      status: "CLOSED",
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  const closeWithActionReportDialogHandler = () => {
    if (getReportState.value.status !== "CLOSED_WITH_ACTION") {
      setShowCloseWithActionDialog(true);
    }
  };

  const closeWithActionReportDialog = () => {
    setShowCloseWithActionDialog(false);
  };

  const closeWithActionReportHandler = async (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => {
    setShowCloseWithActionDialog(false);
    /* if (getReportState.value.status !== "UNDER_REVIEW") return; */
    // zmenime status reportu
    const result = await updateReportStatusCall({
      clientId: clientId,
      reportId: reportId,
      status: "CLOSED_WITH_ACTION",
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  const dismissReportDialogHandler = () => {
    setShowDismissDialog(true);
  };

  const closeDismissReportDialog = () => {
    setShowDismissDialog(false);
  };

  const dismissReportHandler = async (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => {
    setShowDismissDialog(false);
    /* if (getReportState.value.status !== "UNDER_REVIEW") return; */
    // zmenime status reportu
    const result = await updateReportStatusCall({
      clientId: clientId,
      reportId: reportId,
      status: "DISMISSED",
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  const reopenReportDialogHandler = () => {
    setShowReopenDialog(true);
  };

  const closeReopenReportDialog = () => {
    setShowReopenDialog(false);
  };

  const reopenReportHandler = async (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => {
    setShowReopenDialog(false);
    /* if (getReportState.value.status !== "UNDER_REVIEW") return; */
    // zmenime status reportu
    const result = await updateReportStatusCall({
      clientId: clientId,
      reportId: reportId,
      status: "UNDER_REVIEW",
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  const saveReportSettingsHandler = async (data: any) => {
    let isDone = false;
    // ulozime kategorii, prioritu a odpovednou osobu
    const resultCategory = await updateReportCategoryCall({
      clientId: clientId,
      reportId: reportId,
      category: data.category !== "NOT_SET" ? data.category : null,
    });
    if (typeof resultCategory === "object" && resultCategory) {
      isDone = true;
    }

    const resultPriority = await updateReportPriorityCall({
      clientId: clientId,
      reportId: reportId,
      priority: data.priority !== "NOT_SET" ? data.priority : null,
    });
    if (typeof resultPriority === "object" && resultPriority) {
      isDone = true;
    }
    if (isDone) {
      showSuccess(t("whistleblow.message.save"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  const allResponsiblePersons = useMemo(() => {
    if (getAllUsersForClientState.value == null) {
      return [];
    }
    return getAllUsersForClientState.value.data.map((user) => {
      return { id: user.id, label: user.email };
    });
  }, [getAllUsersForClientState.value]);

  const showPublicCommentDialogHandler = (data: { publicComment: string }) => {
    // zobrazime dialog pred ulozenim komentare
    setPublicComment(data.publicComment);
    setShowPublicCommentDialog(true);
  };

  const closePublicCommentDialog = () => {
    setShowPublicCommentDialog(false);
  };

  const publicCommentHandler = async (
    comment: string,
    isCheckedNotificationSend?: boolean
  ) => {
    // updatneme public comment
    const result = await updateReportPublicCommentCall({
      clientId: clientId,
      reportId: reportId,
      publicComment: comment,
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.commentSave"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  const showPublicAssessmentDialogHandler = (data: {
    publicAssessment: string;
  }) => {
    // zobrazime dialog pred ulozenim komentare
    setPublicAssessment(data.publicAssessment);
    setShowPublicAssessmentDialog(true);
  };

  const closePublicAssessmentDialog = () => {
    setShowPublicAssessmentDialog(false);
  };

  const publicaAssessmentHandler = async (
    comment: string,
    isCheckedNotificationSend?: boolean
  ) => {
    // updatneme public assessment
    const result = await updateReportPublicAssessmentCall({
      clientId: clientId,
      reportId: reportId,
      publicAssessment: comment,
      silently: isCheckedNotificationSend ? false : true,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.assessmentSave"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  const showInternalAssessmentDialogHandler = (data: {
    internalAssessment: string;
  }) => {
    // zobrazime dialog pred ulozenim komentare
    setInternalAssessment(data.internalAssessment);
    setShowInternalAssessmentDialog(true);
  };

  const closeInternalAssessmentDialog = () => {
    setShowInternalAssessmentDialog(false);
  };

  const internalAssessmentHandler = async (
    comment: string,
    isCheckedNotificationSend?: boolean
  ) => {
    // update internal assessment
    const result = await updateReportInternalAssessmentCall({
      clientId: clientId,
      reportId: reportId,
      internalAssessment: comment,
      /* silently: isCheckedNotificationSend ? false : true, */
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.assessmentSave"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  //"AML" | "DATA_PROTECTION" | "SAFETY" | "FINANCIAL_MISCONDUCT" | "WORKPLACE_MISCONDUCT" |
  // "CORRUPTION" | "REGULATORY_AND_LEGAL_VIOLATION" | "CYBERSECURITY" | "ENVIRONMENT_AND_SUSTAINABILITY" | "DISCRIMINATION_AND_HARASSMENT" |
  // "PROCUREMENT_AND_CONTRACTING" | "INTELLECTUAL_PROPERTY" | "CONFLICT_OF_INTEREST" | "HEALTH_AND_SAFETY" | "OTHER"
  const allCategories = [
    { id: "AML", label: t("whistleblow.category.aml") },
    { id: "DATA_PROTECTION", label: t("whistleblow.category.dataProtection") },
    { id: "SAFETY", label: t("whistleblow.category.safety") },
    {
      id: "FINANCIAL_MISCONDUCT",
      label: t("whistleblow.category.financialMisconduct"),
    },
    {
      id: "WORKPLACE_MISCONDUCT",
      label: t("whistleblow.category.workplaceMisconduct"),
    },
    { id: "CORRUPTION", label: t("whistleblow.category.corruption") },
    {
      id: "REGULATORY_AND_LEGAL_VIOLATION",
      label: t("whistleblow.category.reqularAndLegalViolation"),
    },
    { id: "CYBERSECURITY", label: t("whistleblow.category.cyberSecurity") },
    {
      id: "ENVIRONMENT_AND_SUSTAINABILITY",
      label: t("whistleblow.category.environment"),
    },
    {
      id: "DISCRIMINATION_AND_HARASSMENT",
      label: t("whistleblow.category.discrimination"),
    },
    {
      id: "PROCUREMENT_AND_CONTRACTING",
      label: t("whistleblow.category.procurement"),
    },
    {
      id: "INTELLECTUAL_PROPERTY",
      label: t("whistleblow.category.intelectualProperty"),
    },
    {
      id: "CONFLICT_OF_INTEREST",
      label: t("whistleblow.category.conflictOfInterest"),
    },
    {
      id: "HEALTH_AND_SAFETY",
      label: t("whistleblow.category.healthAndSafety"),
    },
    { id: "OTHER", label: t("whistleblow.category.other") },
    { id: "NOT_SET", label: t("whistleblow.category.notSet") },
  ];

  const allPriorities = [
    { id: "LOW", label: t("whistleblow.priority.low") },
    { id: "MEDIUM", label: t("whistleblow.priority.medium") },
    { id: "HIGH", label: t("whistleblow.priority.high") },
    { id: "NOT_SET", label: t("whistleblow.priority.notSet") },
  ];

  //"WEB" | "EMAIL" | "CALL" | "PERSONAL" | "POSTAL"
  const allChannels = [
    { id: "WEB", label: t("whistleblow.channel.web") },
    { id: "EMAIL", label: t("whistleblow.channel.email") },
    { id: "CALL", label: t("whistleblow.channel.call") },
    { id: "PERSONAL", label: t("whistleblow.channel.personal") },
    { id: "POSTAL", label: t("whistleblow.channel.written") },
  ];

  const findCategoryName = (id: string) => {
    for (let i = 0; i < allCategories.length; i++) {
      if (allCategories[i].id === id) {
        return allCategories[i].label;
      }
    }
    return "";
  };

  const findChannelName = (id: string) => {
    for (let i = 0; i < allChannels.length; i++) {
      if (allChannels[i].id === id) {
        return allChannels[i].label;
      }
    }
    return "";
  };

  const findUserById = (id: number) => {
    if (getAllUsersForClientState.value == null) {
      return { firstName: "", lastName: "", email: "" };
    }
    for (let i = 0; i < getAllUsersForClientState.value.data.length; i++) {
      if (id === getAllUsersForClientState.value.data[i].id) {
        return getAllUsersForClientState.value.data[i];
      }
    }
    return {
      firstName: "",
      lastName: t("whistleblow.common.system"),
      email: "",
    };
  };

  function historyStatus(status: string, newValue: string) {
    if (status === "PRIORITY_CHANGE") {
      if (newValue == null) {
        return (
          t("whistleblow.history.priorityChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.priority.notSet") +
          '"'
        );
      } else if (newValue === "LOW") {
        return (
          t("whistleblow.history.priorityChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.priority.low") +
          '"'
        );
      } else if (newValue === "HIGH") {
        return (
          t("whistleblow.history.priorityChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.priority.high") +
          '"'
        );
      } else if (newValue === "MEDIUM") {
        return (
          t("whistleblow.history.priorityChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.priority.medium") +
          '"'
        );
      }
      return t("whistleblow.history.priorityChange");
    } else if (status === "CATEGORY_CHANGE") {
      if (newValue == null) {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.notSet") +
          '"'
        );
      } else if (newValue === "AML") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.aml") +
          '"'
        );
      } else if (newValue === "DATA_PROTECTION") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.dataProtection") +
          '"'
        );
      } else if (newValue === "SAFETY") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.safety") +
          '"'
        );
      } else if (newValue === "FINANCIAL_MISCONDUCT") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.financialMisconduct") +
          '"'
        );
      } else if (newValue === "WORKPLACE_MISCONDUCT") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.workplaceMisconduct") +
          '"'
        );
      } else if (newValue === "CORRUPTION") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.corruption") +
          '"'
        );
      } else if (newValue === "REGULATORY_AND_LEGAL_VIOLATION") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.reqularAndLegalViolation") +
          '"'
        );
      } else if (newValue === "CYBERSECURITY") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.cyberSecurity") +
          '"'
        );
      } else if (newValue === "ENVIRONMENT_AND_SUSTAINABILITY") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.environment") +
          '"'
        );
      } else if (newValue === "DISCRIMINATION_AND_HARASSMENT") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.discrimination") +
          '"'
        );
      } else if (newValue === "PROCUREMENT_AND_CONTRACTING") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.procurement") +
          '"'
        );
      } else if (newValue === "INTELLECTUAL_PROPERTY") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.intelectualProperty") +
          '"'
        );
      } else if (newValue === "CONFLICT_OF_INTEREST") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.conflictOfInterest") +
          '"'
        );
      } else if (newValue === "HEALTH_AND_SAFETY") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.healthAndSafety") +
          '"'
        );
      } else if (newValue === "OTHER") {
        return (
          t("whistleblow.history.categoryChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.category.other") +
          '"'
        );
      }

      return t("whistleblow.history.categoryChange");
    } else if (status === "ASSIGNED_TO_CHANGE") {
      return t("whistleblow.history.assignedToChange");
    } else if (status === "STATUS_CHANGE") {
      if (newValue === "NEW") {
        return (
          t("whistleblow.history.statusChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          " " +
          t("whistleblow.status.new") +
          '"'
        );
      } else if (newValue === "UNDER_REVIEW") {
        return (
          t("whistleblow.history.statusChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.status.underReview") +
          '"'
        );
      } else if (newValue === "DISMISS") {
        return (
          t("whistleblow.history.statusChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.status.dismiss") +
          '"'
        );
      } else if (newValue === "CLOSE") {
        return (
          t("whistleblow.history.statusChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.status.close") +
          '"'
        );
      } else if (newValue === "CLOSED_WITH_ACTION") {
        return (
          t("whistleblow.history.statusChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          t("whistleblow.status.closedWithAction") +
          '"'
        );
      }
      return t("whistleblow.history.statusChange");
    } else if (status === "PUBLIC_COMMENT_CHANGE") {
      if (newValue != null) {
        return (
          t("whistleblow.history.publicCommentChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          newValue +
          '"'
        );
      }
      return t("whistleblow.history.publicCommentChange");
    } else if (status === "INTERNAL_COMMENT_CHANGE") {
      if (newValue != null) {
        return (
          t("whistleblow.history.internalCommentChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          newValue +
          '"'
        );
      }
      return t("whistleblow.history.internalCommentChange");
    } else if (status === "PUBLIC_ASSESSMENT_CHANGE") {
      if (newValue != null) {
        return (
          t("whistleblow.history.publicAssessmentChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          newValue +
          '"'
        );
      }
      return t("whistleblow.history.publicAssessmentChange");
    } else if (status === "INTERNAL_ASSESSMENT_CHANGE") {
      if (newValue != null) {
        return (
          t("whistleblow.history.internalAssessmentChange") +
          " " +
          t("whistleblow.common.historyNewValue") +
          ' "' +
          newValue +
          '"'
        );
      }
      return t("whistleblow.history.internalAssessmentChange");
    } else if (status === "ASSESSMENT_STARTED") {
      return t("whistleblow.history.assessmentStarted");
    } else if (status === "CREATED") {
      return t("whistleblow.history.created");
    } else if (status === "CLOSED") {
      return t("whistleblow.history.closed");
    } else if (status === "DISMISSED") {
      return t("whistleblow.history.dismissed");
    } else if (status === "CLOSED_WITH_ACTION") {
      return t("whistleblow.history.closedWithAction");
    } else {
      return "-";
    }
  }

  useEffect(() => {
    if (ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN") {
      getReportCall({ clientId: clientId, reportId: reportId });
      getReportHistoryCall({ clientId: clientId, reportId: reportId });
      getAllUsersForClientCall({ clientId: clientId });
    }
  }, [
    getReportCall,
    getReportHistoryCall,
    getAllUsersForClientCall,
    clientId,
    reportId,
    ctx.isLoggedIn,
  ]);

  return (
    <Stack className="main">
      <Navigation />
      {!ctx.isLoggedIn && <Login />}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO == null && <Unavailable />}
      {ctx.isLoggedIn && (
        <Stack className="px_main" sx={{ mt: 14 }}>
          <Stack className="printHide" direction="row">
            <Button
              variant="outlined"
              color="secondary"
              onClick={goBackHandler}
              sx={{}}
            >
              <ArrowBack sx={{ mr: 1 }} />
              {t("whistleblow.common.back")}
            </Button>
            <div className="space"></div>
          </Stack>
          <Stack
            sx={{
              flexDirection: { sm: "column", md: "row" },
            }}
          >
            <List
              className="printHide"
              sx={{
                mt: 2,
                display: { xs: "flex", sm: "flex", md: "inline-block" },
                flexDirection: { xs: "row", sm: "row", md: "column" },
                flexGrow: { xs: 1, sm: 1, md: 0 },
                width: { sm: "auto", md: "300px" },
              }}
            >
              <ListItemButton
                id="report"
                onClick={showReportDetailHandler}
                selected={selectedMenu === "0"}
              >
                <Typography variant="textBase">
                  {t("whistleblow.report.detail.report")}
                </Typography>
              </ListItemButton>
              <ListItemButton
                id="assessment"
                onClick={showAssessmentHandler}
                selected={selectedMenu === "1"}
              >
                <Typography variant="textBase">
                  {t("whistleblow.report.detail.assessment")}
                </Typography>
              </ListItemButton>
              <ListItemButton
                id="history"
                onClick={showHistoryHandler}
                selected={selectedMenu === "2"}
              >
                <Typography variant="textBase">
                  {t("whistleblow.report.detail.history")}
                </Typography>
              </ListItemButton>
            </List>
            <Stack
              className="form_900"
              sx={{ ml: { sm: 0, md: 2 }, mt: 2, justifyItems: "center" }}
            >
              {getReportState.value && (
                <LoadingGuard state={getReportState}>
                  <Stack
                    sx={{
                      mb: 3,
                      flexDirection: { sm: "column", md: "row" },
                      alignItems: { sm: "left", md: "center" },
                    }}
                  >
                    <Typography
                      variant="apph2Bold"
                      sx={{ mr: { sm: 0, md: 2 }, mb: { xs: 2, sm: 2, md: 0 } }}
                    >
                      {t("whistleblow.report.detail.report") +
                        " ID " +
                        getReportState.value.id}
                    </Typography>
                    {getReportState.value.status &&
                      renderStateChip(getReportState.value.status, false)}
                  </Stack>
                </LoadingGuard>
              )}
              {selectedMenu === "0" && getReportState.value && (
                <LoadingGuard state={getReportState}>
                  <Stack>
                    <Stack
                      direction="row"
                      className="printHide"
                      sx={{ display: { xs: "none", sm: "flex" } }}
                    >
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => window.print()}
                      >
                        <Print />
                      </Button>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ mt: 2, flexDirection: { xs: "column", sm: "row" } }}
                    >
                      <Stack
                        sx={{ mr: { xs: 0, sm: 4.5 }, mt: { xs: 3, sm: 0 } }}
                      >
                        <Typography variant="textBold">
                          {t("whistleblow.report.table.recieved")}
                        </Typography>
                        <Typography variant="textBase">
                          {getReportState.value.receivedDate
                            ? dayjs(getReportState.value.receivedDate).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : dayjs(getReportState.value.createDate).format(
                                "DD.MM.YYYY HH:mm"
                              )}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{ mr: { xs: 0, sm: 4.5 }, mt: { xs: 3, sm: 0 } }}
                      >
                        <Typography variant="textBold">
                          {t("whistleblow.report.table.channel")}
                        </Typography>
                        <Typography variant="textBase">
                          {findChannelName(getReportState.value.channel)}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{ mr: { xs: 0, sm: 4.5 }, mt: { xs: 3, sm: 0 } }}
                      >
                        <Typography variant="textBold">
                          {t("whistleblow.report.table.deadline")}
                        </Typography>
                        <Typography variant="textBase">
                          {getReportState.value.dueDate
                            ? dayjs(getReportState.value.dueDate).format(
                                "DD.MM.YYYY HH:mm"
                              )
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{ mr: { xs: 0, sm: 4.5 }, mt: { xs: 3, sm: 0 } }}
                      >
                        <Typography variant="textBold">
                          {t("whistleblow.report.table.accessCode")}
                        </Typography>
                        <Typography variant="textBase">
                          {getReportState.value.code
                            ? getReportState.value.code
                            : "-"}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.newReport.form.textOfTheReport")}
                      </Typography>
                      <Typography variant="textBase" sx={{ flexWrap: "wrap" }}>
                        {getReportState.value.incidentDescription}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.person")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.reporterName
                          ? getReportState.value.reporterName
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.birthDay")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.reporterBirthPlainDate
                          ? getReportState.value.reporterBirthPlainDate.day +
                            "." +
                            getReportState.value.reporterBirthPlainDate.month +
                            "." +
                            getReportState.value.reporterBirthPlainDate.year
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.email")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.reporterEmail
                          ? getReportState.value.reporterEmail
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.phone")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.reporterPhone
                          ? getReportState.value.reporterPhone
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.address")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.reporterContactAddress
                          ? getReportState.value.reporterContactAddress
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.involved")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.personsInvolved
                          ? getReportState.value.personsInvolved
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.witnesses")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.witnesses
                          ? getReportState.value.witnesses
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.incidentDate")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.incidentDate
                          ? dayjs(getReportState.value.incidentDate).format(
                              "DD.MM.YYYY HH:mm"
                            )
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.incidentLocation")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.incidentLocation
                          ? getReportState.value.incidentLocation
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.incidentValue")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.incidentValue
                          ? getReportState.value.incidentValue
                          : "-"}
                      </Typography>
                    </Stack>
                    <Stack sx={{ mt: 3, mb: 10 }}>
                      <Typography variant="textBold">
                        {t("whistleblow.report.detail.evidence")}
                      </Typography>
                      <Typography variant="textBase">
                        {getReportState.value.evidence
                          ? getReportState.value.evidence
                          : "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </LoadingGuard>
              )}
              {selectedMenu === "1" && getReportHistoryState.value && (
                <LoadingGuard state={getReportState}>
                  <LoadingGuard state={getAllUsersForClientState}>
                    <Stack>
                      <Stack direction="row">
                        {getReportState.value &&
                          getReportState.value.status === "NEW" && (
                            <AsyncButton
                              onClick={startReportDialogHandler}
                              sx={{ mt: 3 }}
                            >
                              <Balance />
                              <Typography variant="textBase" sx={{ ml: 1 }}>
                                {t("whistleblow.assessment.startAssessment")}
                              </Typography>
                            </AsyncButton>
                          )}
                        {getReportState.value &&
                          getReportState.value.status !== "NEW" && (
                            <Stack
                              sx={{
                                mt: 3,
                                flexDirection: { md: "column", lg: "row" },
                              }}
                            >
                              {getReportState.value.status !== "CLOSED" && (
                                <AsyncButton
                                  variant="outlined"
                                  color="secondary"
                                  onClick={closeReportDialogHandler}
                                  sx={{}}
                                >
                                  <Balance />
                                  <Typography variant="textBase" sx={{ ml: 1 }}>
                                    {t("whistleblow.assessment.close")}
                                  </Typography>
                                </AsyncButton>
                              )}
                              {getReportState.value.status !==
                                "CLOSED_WITH_ACTION" && (
                                <AsyncButton
                                  variant="outlined"
                                  color="success"
                                  onClick={closeWithActionReportDialogHandler}
                                  sx={{
                                    ml: { md: 0, lg: 2 },
                                    mt: { xs: 2, sm: 2, md: 2, lg: 0 },
                                  }}
                                >
                                  <Balance />
                                  <Typography variant="textBase" sx={{ ml: 1 }}>
                                    {t(
                                      "whistleblow.assessment.closedWithAction"
                                    )}
                                  </Typography>
                                </AsyncButton>
                              )}
                              {getReportState.value.status !== "DISMISSED" && (
                                <AsyncButton
                                  variant="contained"
                                  color="secondary"
                                  onClick={dismissReportDialogHandler}
                                  sx={{
                                    ml: { md: 0, lg: 2 },
                                    mt: { xs: 2, sm: 2, md: 2, lg: 0 },
                                  }}
                                >
                                  <Delete />
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    {t("whistleblow.assessment.dismiss")}
                                  </Typography>
                                </AsyncButton>
                              )}
                              {(getReportState.value.status === "CLOSED" ||
                                getReportState.value.status === "DISMISSED" ||
                                getReportState.value.status ===
                                  "CLOSED_WITH_ACTION") && (
                                <AsyncButton
                                  variant="contained"
                                  color="primary"
                                  onClick={reopenReportDialogHandler}
                                  sx={{
                                    ml: { md: 0, lg: 2 },
                                    mt: { xs: 2, sm: 2, md: 2, lg: 0 },
                                  }}
                                >
                                  <Restore />
                                  <Typography variant="body2" sx={{ ml: 1 }}>
                                    {t("whistleblow.assessment.reopen")}
                                  </Typography>
                                </AsyncButton>
                              )}
                            </Stack>
                          )}
                      </Stack>
                      {getReportState.value && getAllUsersForClientState.value && (
                        <Stack sx={{ mb: 10 }}>
                          <FormContainer
                            defaultValues={{
                              category: getReportState.value.category
                                ? getReportState.value.category
                                : "NOT_SET",
                              priority: getReportState.value.priority
                                ? getReportState.value.priority
                                : "NOT_SET",
                              responsiblePerson: allResponsiblePersons[0],
                            }}
                            onSuccess={saveReportSettingsHandler}
                          >
                            <Stack sx={{ mt: 4.5 }}>
                              <Stack
                                sx={{
                                  flexDirection: { xs: "column", sm: "row" },
                                }}
                              >
                                <SelectElement
                                  id="category"
                                  name="category"
                                  label={t("whistleblow.report.table.category")}
                                  options={allCategories}
                                  sx={{ width: { xs: "100%", sm: "50%" } }}
                                  SelectProps={{
                                    readOnly: !isReportEditable(
                                      getReportState.value.status
                                    ),
                                  }}
                                />
                                <SelectElement
                                  id="priority"
                                  name="priority"
                                  label={t("whistleblow.report.table.priority")}
                                  options={allPriorities}
                                  sx={{
                                    ml: { xs: 0, sm: 2 },
                                    mt: { xs: 2, sm: 0 },
                                    width: { xs: "100%", sm: "50%" },
                                  }}
                                  SelectProps={{
                                    readOnly: !isReportEditable(
                                      getReportState.value.status
                                    ),
                                  }}
                                />
                              </Stack>
                              {/* <Stack className="form_200" sx={{ ml: 5 }}>
                             <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {t("whistleblow.report.table.priority")}
                          </Typography> 
                            <SelectElement
                              id="responsiblePerson"
                              name="responsiblePerson"
                              label={t(
                                "whistleblow.assessment.responsiblePerson"
                              )}
                              options={allResponsiblePersons}
                              sx={{}}
                              SelectProps={{ readOnly: !isEditable }}
                            />
                          </Stack> */}
                              {isReportEditable(
                                getReportState.value.status
                              ) && (
                                <Stack direction="row" sx={{ mt: 3 }}>
                                  <div className="space" />
                                  <AsyncButton>
                                    {t("whistleblow.common.saveChanges")}
                                  </AsyncButton>
                                </Stack>
                              )}
                            </Stack>
                          </FormContainer>
                          <FormContainer
                            defaultValues={{
                              publicComment: getReportState.value.publicComment
                                ? getReportState.value.publicComment
                                : "",
                            }}
                            onSuccess={showPublicCommentDialogHandler}
                          >
                            <Stack sx={{ mt: 5 }}>
                              <TextFieldElement
                                id="publicComment"
                                name="publicComment"
                                label={t(
                                  "whistleblow.assessment.publicComment"
                                )}
                                multiline
                                rows={5}
                                inputProps={{
                                  readOnly: !isReportEditable(
                                    getReportState.value.status
                                  ),
                                }}
                              />
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                {t("whistleblow.assessment.publicCommentHint")}
                              </Typography>
                              {isReportEditable(
                                getReportState.value.status
                              ) && (
                                <Stack direction="row" sx={{ mt: 2 }}>
                                  <div className="space"></div>
                                  <AsyncButton>
                                    {t("whistleblow.assessment.publish")}
                                  </AsyncButton>
                                </Stack>
                              )}
                            </Stack>
                          </FormContainer>
                          <FormContainer
                            defaultValues={{
                              publicAssessment: getReportState.value
                                .publicAssessment
                                ? getReportState.value.publicAssessment
                                : "",
                            }}
                            onSuccess={showPublicAssessmentDialogHandler}
                          >
                            <Stack sx={{ mt: 5 }}>
                              <TextFieldElement
                                id="publicAssessment"
                                name="publicAssessment"
                                label={t(
                                  "whistleblow.assessment.publicAssessment"
                                )}
                                multiline
                                rows={5}
                                inputProps={{
                                  readOnly: !isReportEditable(
                                    getReportState.value.status
                                  ),
                                }}
                              />
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                {t(
                                  "whistleblow.assessment.publicAssessmentHint"
                                )}
                              </Typography>
                              {isReportEditable(
                                getReportState.value.status
                              ) && (
                                <Stack direction="row" sx={{ mt: 2 }}>
                                  <div className="space"></div>
                                  <AsyncButton>
                                    {t("whistleblow.assessment.publish")}
                                  </AsyncButton>
                                </Stack>
                              )}
                            </Stack>
                          </FormContainer>
                          <FormContainer
                            defaultValues={{
                              internalAssessment: getReportState.value
                                .internalAssessment
                                ? getReportState.value.internalAssessment
                                : "",
                            }}
                            onSuccess={showInternalAssessmentDialogHandler}
                          >
                            <Stack sx={{ mt: 5 }}>
                              <TextFieldElement
                                id="internalAssessment"
                                name="internalAssessment"
                                label={t(
                                  "whistleblow.assessment.internalAssessment"
                                )}
                                multiline
                                rows={5}
                                inputProps={{
                                  readOnly: !isReportEditable(
                                    getReportState.value.status
                                  ),
                                }}
                              />
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                {t(
                                  "whistleblow.assessment.internalAssessmentHint"
                                )}
                              </Typography>
                              {isReportEditable(
                                getReportState.value.status
                              ) && (
                                <Stack direction="row" sx={{ mt: 2 }}>
                                  <div className="space"></div>
                                  <AsyncButton>
                                    {t("whistleblow.assessment.publish")}
                                  </AsyncButton>
                                </Stack>
                              )}
                            </Stack>
                          </FormContainer>
                        </Stack>
                      )}
                    </Stack>
                    {showStartDialog && (
                      <CustomDialog
                        openDialog={showStartDialog}
                        closed={closeStartReportDialog}
                        title={t("whistleblow.dialog.startTitle")}
                        subtitle={t("whistleblow.dialog.startDescription")}
                        buttonText={t("whistleblow.common.save")}
                        onButtonClick={startReportHandler}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showCloseDialog && (
                      <CustomDialog
                        openDialog={showCloseDialog}
                        closed={closeReportDialog}
                        title={t("whistleblow.dialog.closeTitle")}
                        subtitle={t("whistleblow.dialog.closeDescription")}
                        buttonText={t("whistleblow.common.save")}
                        onButtonClick={closeReportHandler}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showCloseWithActionDialog && (
                      <CustomDialog
                        openDialog={showCloseWithActionDialog}
                        closed={closeWithActionReportDialog}
                        title={t("whistleblow.dialog.closeWithActionTitle")}
                        subtitle={t(
                          "whistleblow.dialog.closeWithActionDescription"
                        )}
                        buttonText={t("whistleblow.common.save")}
                        onButtonClick={closeWithActionReportHandler}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showDismissDialog && (
                      <CustomDialog
                        openDialog={showDismissDialog}
                        closed={closeDismissReportDialog}
                        title={t("whistleblow.dialog.dismissTitle")}
                        subtitle={t("whistleblow.dialog.dismissDescription")}
                        buttonText={t("whistleblow.common.save")}
                        onButtonClick={dismissReportHandler}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showReopenDialog && (
                      <CustomDialog
                        openDialog={showReopenDialog}
                        closed={closeReopenReportDialog}
                        title={t("whistleblow.dialog.reopenTitle")}
                        subtitle={t("whistleblow.dialog.reopenDescription")}
                        buttonText={t("whistleblow.common.save")}
                        onButtonClick={reopenReportHandler}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showPublicCommentDialog && (
                      <CustomDialog
                        openDialog={showPublicCommentDialog}
                        closed={closePublicCommentDialog}
                        title={t(
                          "whistleblow.dialog.publishPublicCommentTitle"
                        )}
                        subtitle={t(
                          "whistleblow.dialog.publishPublicCommentDescription"
                        )}
                        buttonText={t("whistleblow.common.publish")}
                        commentText={publicComment}
                        onButtonClick={publicCommentHandler}
                        rowNumber={commentRowNumber}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showPublicAssessmentDialog && (
                      <CustomDialog
                        openDialog={showPublicAssessmentDialog}
                        closed={closePublicAssessmentDialog}
                        title={t(
                          "whistleblow.dialog.publishPublicAssessmentTitle"
                        )}
                        subtitle={t(
                          "whistleblow.dialog.publishPublicAssessmentDescription"
                        )}
                        buttonText={t("whistleblow.common.publish")}
                        commentText={publicAssessment}
                        onButtonClick={publicaAssessmentHandler}
                        rowNumber={commentRowNumber}
                        notificationEmail={
                          getReportState.value.notificationEmail
                            ? getReportState.value.notificationEmail
                            : null
                        }
                      />
                    )}
                    {showInternalAssessmentDialog && (
                      <CustomDialog
                        openDialog={showInternalAssessmentDialog}
                        closed={closeInternalAssessmentDialog}
                        title={t(
                          "whistleblow.dialog.publishInternalAssessmentTitle"
                        )}
                        subtitle={t(
                          "whistleblow.dialog.publishInternalAssessmentDescription"
                        )}
                        buttonText={t("whistleblow.common.publish")}
                        commentText={internalAssessment}
                        onButtonClick={internalAssessmentHandler}
                        rowNumber={commentRowNumber}
                      />
                    )}
                  </LoadingGuard>
                </LoadingGuard>
              )}
              {selectedMenu === "2" && (
                <LoadingGuard state={getReportHistoryState}>
                  <LoadingGuard state={getAllUsersForClientState}>
                    {getReportHistoryState.value != null &&
                      getAllUsersForClientState.value != null && (
                        <Stack sx={{ mb: 8 }}>
                          {getReportHistoryState.value.length === 0 && (
                            <Typography variant="textBase">
                              {t("whistleblow.common.noHistoryFound")}
                            </Typography>
                          )}
                          {getReportHistoryState.value.length > 0 &&
                            getReportHistoryState.value.map((historyItem) => {
                              return (
                                <React.Fragment
                                  key={
                                    historyItem.id
                                      ? historyItem.id
                                      : historyItem.datePerformed
                                  }
                                >
                                  <Stack
                                    sx={{
                                      flexDirection: {
                                        sm: "column",
                                        lg: "row",
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="textBase"
                                      sx={{
                                        mr: { sm: 0, lg: 1 },
                                        mb: { xs: 1, sm: 1, lg: 0 },
                                        minWidth: "200px",
                                      }}
                                    >
                                      {dayjs(historyItem.datePerformed).format(
                                        "DD.MM.YYYY HH:mm"
                                      )}
                                    </Typography>
                                    {getAllUsersForClientState.value && (
                                      <Typography
                                        variant="textBase"
                                        sx={{
                                          mr: { sm: 0, lg: 1 },
                                          mb: { xs: 1, sm: 1, lg: 0 },
                                          minWidth: "200px",
                                        }}
                                      >
                                        {findUserById(historyItem.authorUserId)
                                          .lastName
                                          ? findUserById(
                                              historyItem.authorUserId
                                            ).lastName
                                          : findUserById(
                                              historyItem.authorUserId
                                            ).email}{" "}
                                        {findUserById(historyItem.authorUserId)
                                          .firstName
                                          ? findUserById(
                                              historyItem.authorUserId
                                            ).firstName
                                          : ""}
                                      </Typography>
                                    )}
                                    <Typography variant="textBase">
                                      {historyStatus(
                                        historyItem.type,
                                        historyItem.newValue
                                      )}
                                    </Typography>
                                  </Stack>
                                  <Divider sx={{ my: 1 }} />
                                </React.Fragment>
                              );
                            })}
                        </Stack>
                      )}
                  </LoadingGuard>
                </LoadingGuard>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      <div className="space"></div>
      <SmallFooter />
    </Stack>
  );
};

export default ReportDetail;
