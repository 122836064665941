import { Add, AddRounded, CancelRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../models/settings";
import AppBarCustom, { BreadCrumbsItem } from "../ui/AppBarCustom";
import CustomDialog from "../ui/CustomDialog";

const AdminSettings: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
    table: {
      mt: 2,
      minHeight: "640px",
    },
    tableBox: {
      mt: 3,
      width: "100%",
    },
    mainColumn: {
      fontWeight: "bold",
      width: "80%",
    },
    actionColumn: {
      fontWeight: "bold",
      width: "10%",
    },
    iconButton: {
      mr: 1,
    },
    comment: {
      mt: 2,
      width: "396px",
    },
    buttons: {
      mt: 2,
    },
  });

  const columnWidth = "350px";
  const messageTime = 1500;
  const { refresh } = useContext(UiContext);
  const { t } = useTranslation();
  const required = useRequiredValidation();
  const phoneValidation = usePhoneNumberValidation();
  const api = useApiClient();
  const ctx = useContext(AuthContext);
  const data = useParams();
  const clientId = data.companyId;
  const { showSuccess } = useContext(AlertContext);
  const minLenght = useMinLengthValidation(8);

  const [changePasswordCall, changePasswordState] = useSafeAsync(
    api.userChangePassword
  );
  const [updateUserCall, updateUserState] = useSafeAsync(api.updateUser);

  const changePasswordHandler = async (data: {
    oldPassword: string;
    newPassword: string;
  }) => {
    // zmenime uzivateli heslo
    const result = await changePasswordCall({
      clientId: clientId,
      id: ctx.loggedUser.userDTO.id,
      userId: ctx.loggedUser.userDTO.id,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  const updateUserInfoHandler = async (data: {
    firstName: string;
    lastName: string;
    phone: string;
  }) => {
    // updatneme User info
    const result = await updateUserCall({
      clientId: clientId,
      userId: ctx.loggedUser.userDTO.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      role: "ADMIN",
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", messageTime);
      ctx.autoLogin();
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  const updateUserNotificationsHandler = (data: any) => {
    // updatneme posilani notifikaci
  };

  return (
    <Stack className="px_main" sx={{ mt: 14, mb: 8, alignSelf: "center" }}>
      <Typography variant="apph1Bold">
        {t("whistleblow.account.title")}
      </Typography>
      <Typography variant="apph2Bold" sx={{ my: 2 }}>
        {t("whistleblow.account.profile")}
      </Typography>
      <Divider />
      <Stack
        sx={{
          my: 2,
          alignItems: "left",
          flexDirection: "column",
        }}
      >
        {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
          {t("whistleblow.account.login")}
        </Typography> */}
        <TextField
          id="login"
          label={t("whistleblow.account.login")}
          defaultValue={ctx.loggedUser.userDTO.email}
          disabled
          inputProps={{ readOnly: true }}
          sx={{ width: { sm: "auto", md: columnWidth } }}
        />
      </Stack>
      <Divider />
      <FormContainer defaultValues={{}} onSuccess={changePasswordHandler}>
        <Stack direction="row" sx={{ my: 2, alignItems: "center" }}>
          {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.oldPassword")}
          </Typography> */}
          <PasswordElement
            id="oldPassword"
            name="oldPassword"
            label={t("whistleblow.account.oldPassword")}
            validation={{ ...required, ...minLenght }}
            sx={{ width: { sm: "auto", md: columnWidth } }}
          />
        </Stack>
        <Stack
          sx={{
            my: 2,
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.newPassword")}
          </Typography> */}
          <PasswordElement
            id="newPassword"
            name="newPassword"
            label={t("whistleblow.account.newPassword")}
            validation={{ ...required, ...minLenght }}
            sx={{ width: { sm: "auto", md: columnWidth } }}
          />
          <Stack
            direction="row"
            sx={{ width: { sm: "auto", md: columnWidth } }}
          >
            <div className="space" />
            <AsyncButton sx={{ mt: 2 }}>
              <Typography variant="textBase">
                {t("whistleblow.common.save")}
              </Typography>
            </AsyncButton>
          </Stack>
        </Stack>
      </FormContainer>
      <Divider />
      <Typography variant="apph2Bold" sx={{ mt: 4, mb: 2 }}>
        {t("whistleblow.account.personalInfo")}
      </Typography>
      <Divider />
      <FormContainer
        defaultValues={{
          firstName: ctx.loggedUser.userDTO.firstName,
          lastName: ctx.loggedUser.userDTO.lastName,
          phone: ctx.loggedUser.userDTO.phone,
        }}
        onSuccess={updateUserInfoHandler}
      >
        <Stack sx={{ mt: 2, mb: 2, alignItems: "left" }}>
          {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.firstName")}
          </Typography> */}
          <TextFieldElement
            name="firstName"
            label={t("whistleblow.account.firstName")}
            validation={required}
            sx={{ width: { sm: "auto", md: columnWidth } }}
          />
        </Stack>
        <Stack sx={{ my: 2, alignItems: "left" }}>
          {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.lastName")}
          </Typography> */}
          <TextFieldElement
            name="lastName"
            label={t("whistleblow.account.lastName")}
            validation={required}
            sx={{ width: { sm: "auto", md: columnWidth } }}
          />
        </Stack>
        <Stack
          sx={{
            my: 2,
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          {/* <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.phone")}
          </Typography> */}
          <TextFieldElement
            name="phone"
            label={t("whistleblow.account.phone")}
            validation={{ ...required, ...phoneValidation }}
            sx={{ width: { sm: "auto", md: columnWidth } }}
          />
          <Stack
            direction="row"
            sx={{ mt: 2, width: { sm: "auto", md: columnWidth } }}
          >
            <div className="space" />
            <AsyncButton state={updateUserState}>
              <Typography variant="textBase">
                {t("whistleblow.common.save")}
              </Typography>
            </AsyncButton>
          </Stack>
        </Stack>
      </FormContainer>
      {/*
      <Divider />
       <Stack direction="row" sx={{ mt: 2, mb: 2, alignItems: "center" }}>
           <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.email")}
          </Typography> 
          <TextField
            id="email"
            label={t("whistleblow.account.email")}
            defaultValue={ctx.loggedUser.userDTO.email}
            sx={{ width: { sm: "auto", md: columnWidth } }}
            inputProps={{ readOnly: true }}
            disabled
          />
        </Stack>      
      <Divider /> 
      <Typography variant="apph2Bold" sx={{ mt: 4, mb: 2 }}>
        {t("whistleblow.account.notifications")}
      </Typography>
    <Divider />
      <FormContainer
        defaultValues={{}}
        onSuccess={updateUserNotificationsHandler}
      >
        <Stack sx={{ mt: 2, alignItems: "baseline" }}>
          <Typography variant="textMedium" className="form_200" sx={{ mr: 2 }}>
            {t("whistleblow.account.byEmail")}
          </Typography>
          <Stack className="form_400">
              <CheckboxElement
              name="newReport"
              label={t("whistleblow.account.newReport")}
              helperText={t("whistleblow.account.newReportHelp")}
            />
            <CheckboxElement
              name="comments"
              label={t("whistleblow.account.comments")}
              helperText={t("whistleblow.account.commentsHelp")}
            /> 
            <CheckboxElement
              name="status"
              label={t("whistleblow.account.status")}
              helperText={t("whistleblow.account.statusHelp")}
            />
          </Stack>
        </Stack>
        <Stack direction="row" sx={{ mt: 2 }}>
          <div className="space"></div>
          <AsyncButton sx={{}}>
            <Typography variant="textBase">
              {t("whistleblow.common.save")}
            </Typography>
          </AsyncButton>
        </Stack>
      </FormContainer> */}
    </Stack>
  );
};

export default AdminSettings;
