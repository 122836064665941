import {
  Balance,
  LockOutlined,
  Send,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Box, Button, Link, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as LinkDom, useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../api/useApiClient";
import { useSafeAsync } from "../hooks/useSafeAsync";
import { defineStyles } from "../styles/defineStyles";
import i18n from "../i18n";

import Navigation from "./Navigation";
import FooterAnonymous from "./ui/FooterAnonymous";
import { LoadingGuard } from "./form/LoadingGuard";
import Unavailable from "./ui/Unavailable";

const CircleNumber: React.FC<{ count: number; ml?: number; mr?: number }> = (
  props
) => {
  const theme = useTheme();
  return (
    <Stack direction="column" sx={{ alignSelf: "start" }}>
      <div className="space"></div>
      <Box
        sx={{
          background: "#F6C25C",
          borderRadius: "62px",
          width: { xs: "90px", sm: "124px" },
          height: { xs: "90px", sm: "124px" },
          mr: props.mr ? props.mr : 0,
          ml: props.ml ? props.ml : 0,
          position: "relative",
        }}
      >
        <Typography
          variant="brownfox"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "36px",
          }}
        >
          {props.count}
        </Typography>
      </Box>
      <div className="space"></div>
    </Stack>
  );
};

const AnonymousPage: React.FC = () => {
  const styles = defineStyles({
    section: {
      background: "white",
      color: "black",
      alignItems: "center",
      px: 3,
      py: 6,
    },
    title: {
      fontWeight: 800,
      textTransform: "uppercase",
    },
    sectionPart: {
      mt: 6,
      maxWidth: "600px",
    },
  });

  const mBig = 14;
  const mSmall = 4.5;
  const mMiddle = 9;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = useParams();
  const clientId = data.companyId;
  const api = useApiClient();
  const [clientDetailCall, clientDetailState] = useSafeAsync(
    api.getClientInfoPublicById
  );

  const newReportHandler = () => {
    navigate("./newReport");
  };

  useEffect(() => {
    clientDetailCall({ id: clientId });
  }, [clientId, clientDetailCall]);

  return (
    <React.Fragment>
      <Navigation isLandingPage={true} />
      <LoadingGuard state={clientDetailState}>
        {!clientDetailState.value && <Unavailable />}
        {clientDetailState.value && (
          <React.Fragment>
            <Stack
              direction="column"
              className="px_main"
              sx={{
                background: "#37474f",
                color: "white",
                alignItems: "center",
              }}
            >
              <Typography
                variant="apph1Bold"
                sx={{ mt: mBig, mb: 1, textAlign: "center" }}
              >
                {t("whistleblow.report.title", "Compliance Reporting System")}
              </Typography>
              <Typography variant="apph2" sx={{ mt: 1, textAlign: "center" }}>
                {t(
                  "whistleblow.report.subTitle1",
                  "Anonymous and secure system for submitting"
                )}{" "}
                {t("whistleblow.report.subTitle2", "compliance reports.")}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  mt: mSmall,
                  alignItems: { xs: "center", sm: "baseline" },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Link href="#howItWorks" color="inherit">
                  <Typography variant="textMedium">
                    {t("whistleblow.common.learnHowItWorks")}
                  </Typography>
                </Link>
                <Button
                  variant="contained"
                  sx={{
                    mt: { xs: 3, sm: 0 },
                    ml: { xs: 0, sm: 3 },
                    justifyItems: "center",
                    textTransform: "none",
                  }}
                  onClick={newReportHandler}
                >
                  <Typography variant="textMedium">
                    {t("whistleblow.common.SubmitAReport", "Submint a report")}
                  </Typography>
                  <Send sx={{ ml: 2, height: "24px", width: "24px" }} />
                </Button>
              </Stack>
              <Stack sx={{ mt: mSmall, mb: mSmall }}>
                <LinkDom to="./status" className="linkUnderlineOnHover">
                  <Typography variant="textMedium">
                    {t(
                      "whistleblow.report.checkTheStatus",
                      "Check the status of a report"
                    )}
                  </Typography>
                </LinkDom>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  mb: 6,
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    mr: { xs: 0, sm: 0, md: 4 },
                    mb: { xs: 2, sn: 2, md: 0 },
                  }}
                >
                  <VerifiedUserOutlined sx={{ mr: 1 }} />
                  <Typography variant="textMedium">
                    {t("whistleblow.report.confidential", "confidential")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mr: { xs: 0, sm: 0, md: 4 },
                    mb: { xs: 2, sn: 2, md: 0 },
                  }}
                >
                  <LockOutlined sx={{ mr: 1 }} />
                  <Typography variant="textMedium">
                    {t("whistleblow.report.secure", "Secure")}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    mb: { xs: 2, sn: 2, md: 0 },
                  }}
                >
                  <Balance sx={{ mr: 1 }} />
                  <Typography variant="textMedium">
                    {t("whistleblow.report.independent", "Independent")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <section id="about">
              <Stack direction="column" sx={styles.section}>
                <Typography variant="apph2Bold" sx={styles.title}>
                  {t("whistleblow.report.about", "About")}
                </Typography>
                <Typography
                  variant="textBase"
                  className="form_600"
                  sx={{ mt: mSmall, textAlign: "center" }}
                >
                  {t("whistleblow.common.aboutDescription1")}
                  {/* {clientDetailState.value
                    ? clientDetailState.value.name
                    : clientId}
                  {t("whistleblow.common.aboutDescription2")} */}
                </Typography>
                <Typography
                  variant="textBase"
                  className="form_600"
                  sx={{ mt: mSmall, textAlign: "center" }}
                >
                  {t("whistleblow.common.aboutDescription2")}
                </Typography>
                <Typography
                  variant="textBase"
                  className="form_600"
                  sx={{ mt: mSmall, textAlign: "center" }}
                >
                  {t("whistleblow.common.aboutDescription3").length > 0 && (
                    <>
                      {clientDetailState.value
                        ? clientDetailState.value.name
                        : clientId}{" "}
                      {t("whistleblow.common.aboutDescription3")}
                    </>
                  )}
                </Typography>
              </Stack>
            </section>
            <section id="howItWorks">
              <Stack direction="column" sx={styles.section}>
                <Typography variant="apph2Bold" sx={styles.title}>
                  {t("whistleblow.common.howItWorks", "How it works")}
                </Typography>
                <Stack
                  direction="row"
                  className="form_600"
                  sx={{ mt: mMiddle }}
                >
                  <CircleNumber count={1} mr={mSmall} />
                  <Stack direction="column">
                    <Typography variant="apph3Semibold">
                      {t(
                        "whistleblow.report.howItWorksStep1Title",
                        "Submit a report"
                      )}
                    </Typography>
                    <div style={{ display: "inline-block", marginTop: "16px" }}>
                      <Typography variant="textBase" component="span">
                        {t(
                          "whistleblow.report.howItWorksStep1DescriptionPart1"
                        )}{" "}
                      </Typography>
                      {/* <LinkDom
                        to="./newReport"
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        <Typography
                          variant="textMedium"
                          component="span"
                          sx={{ textDecoration: "underline" }}
                        >
                          {t(
                            "whistleblow.common.submitAReport",
                            "submit a report"
                          )}{" "}
                        </Typography>
                      </LinkDom>
                      <Typography variant="textBase" component="span">
                        {t(
                          "whistleblow.report.howItWorksStep1DescriptionPart2",
                          " of incident or suspicion regarding violations and breaches of norms, standards, or law by anyone in ACME Inc."
                        )}
                      </Typography> */}
                    </div>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  className="form_600"
                  sx={{ mt: mMiddle }}
                >
                  <Stack direction="column">
                    <Typography variant="apph3Semibold">
                      {t(
                        "whistleblow.report.howItWorksStep2Title",
                        "Get the access code"
                      )}
                    </Typography>
                    <Typography variant="textBase" sx={{ mt: 2 }}>
                      {t(
                        "whistleblow.report.howItWorksStep2DescriptionPart1",
                        "After submitting your report the system provides confirmation and generates the unique access code."
                      )}
                    </Typography>
                    {/* <Typography variant="textBase">
                      {t(
                        "whistleblow.report.howItWorksStep2DescriptionPart2",
                        "You can use this code to securly check the status of your report."
                      )}
                    </Typography> */}
                  </Stack>
                  <CircleNumber count={2} ml={5} />
                </Stack>
                <Stack
                  direction="row"
                  className="form_600"
                  sx={{ mt: mMiddle }}
                >
                  <CircleNumber count={3} mr={5} />
                  <Stack direction="column">
                    <Typography variant="apph3Semibold">
                      {t(
                        "whistleblow.report.howItWorksStep3Title",
                        "Check the status"
                      )}
                    </Typography>
                    <div style={{ display: "inline-block", marginTop: "16px" }}>
                      <Typography variant="textBase" component="span">
                        {t(
                          "whistleblow.report.howItWorksStep3DescriptionPart1",
                          "You can"
                        )}{" "}
                      </Typography>
                      {/* <LinkDom
                        to="./status"
                        style={{
                          color: "black",
                          textDecoration: "underline",
                        }}
                      >
                        <Typography variant="textMedium">
                          {t(
                            "whistleblow.common.checkTheStatus",
                            "check the status"
                          )}{" "}
                        </Typography>
                      </LinkDom>
                      <Typography variant="textBase" component="span">
                        {t(
                          "whistleblow.report.howItWorksStep3DescriptionPart2",
                          "of the submitted report by using the access code as a unique access key. You can also choose to be notified by email when there is a follow-up on your report."
                        )}
                      </Typography> */}
                    </div>
                  </Stack>
                </Stack>
              </Stack>
            </section>
            {clientDetailState.value.officer && (
              <section id="contact">
                <Stack direction="column" sx={styles.section}>
                  <Typography variant="apph2Bold" sx={styles.title}>
                    {t("whistleblow.common.contact", "Contact")}
                  </Typography>
                  <Typography
                    variant="textBase"
                    className="form_500"
                    sx={{ mt: 4.5, textAlign: "center" }}
                  >
                    {t(
                      "whistleblow.report.contactDescription",
                      "In case of any inquiries or additional questions, please contact the Compliance Officer:"
                    )}
                  </Typography>
                  <Typography variant="textMedium" sx={{ mt: 1 }}>
                    {clientDetailState.value.officer.firstName &&
                    clientDetailState.value.officer.lastName
                      ? clientDetailState.value.officer.firstName +
                        " " +
                        clientDetailState.value.officer.lastName
                      : t("whistleblow.common.complianceOfficer")}
                  </Typography>
                  {clientDetailState.value.officerAddress && (
                    <Stack>
                      {/* <Typography variant="textMedium" sx={{ mt: 2 }}>
                        {t("whistleblow.settings.client.officirAddress")}
                      </Typography> */}
                      <Typography
                        variant="textBase"
                        sx={{ textAlign: "center", mt: 1 }}
                      >
                        {clientDetailState.value.officerAddress.street
                          ? clientDetailState.value.officerAddress.street
                          : ""}{" "}
                        {clientDetailState.value.officerAddress.streetNumber
                          ? clientDetailState.value.officerAddress.streetNumber
                          : ""}
                      </Typography>
                      <Typography
                        variant="textBase"
                        sx={{ textAlign: "center", mt: 1 }}
                      >
                        {clientDetailState.value.officerAddress.zip
                          ? clientDetailState.value.officerAddress.zip
                          : ""}{" "}
                        {clientDetailState.value.officerAddress.city
                          ? clientDetailState.value.officerAddress.city
                          : ""}
                      </Typography>
                      {/* <Typography
                        variant="textBase"
                        sx={{ textAlign: "center", mt: 1 }}
                      >
                        {clientDetailState.value.officerAddress.country
                          ? clientDetailState.value.officerAddress.country
                          : ""}
                      </Typography> */}
                    </Stack>
                  )}
                  <Link
                    href={"mailto: " + clientDetailState.value.officer.email}
                    sx={{ mt: 1, textDecoration: "none", color: "black" }}
                  >
                    <Typography variant="textBase">
                      {clientDetailState.value.officer.email}
                    </Typography>
                  </Link>
                  {clientDetailState.value.officer.phone && (
                    <Link
                      href={"phone:" + clientDetailState.value.officer.phone}
                      sx={{
                        mt: 1,
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <Typography variant="textBase">
                        {clientDetailState.value.officer.phone}
                      </Typography>
                    </Link>
                  )}
                  <Typography
                    variant="textBase"
                    className="form_600"
                    sx={{ mt: 2, textAlign: "center" }}
                  >
                    {t("whistleblow.report.contactSubDescription1")}
                  </Typography>
                  <Typography
                    variant="textBase"
                    className="form_600"
                    sx={{ mt: 2, textAlign: "center" }}
                  >
                    {t("whistleblow.report.contactSubDescription2")}
                  </Typography>
                </Stack>
              </section>
            )}
          </React.Fragment>
        )}
      </LoadingGuard>

      <FooterAnonymous />
    </React.Fragment>
  );
};

export default AnonymousPage;
export { CircleNumber };
