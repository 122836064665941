import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import Navigation from "../Navigation";
import CustomDialog from "../ui/CustomDialog";
import SmallFooter from "../ui/SmallFooter";
import AlertContext from "../contexts/AlertContext";

const UserDetail: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 3,
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });
  const api = useApiClient();
  const data = useParams();
  const userId = parseInt(data.userId);
  const clientId = data.companyId;
  const { refresh } = useContext(UiContext);
  const navigate = useNavigate();
  const path = "/app/admin/" + clientId;
  const { t } = useTranslation();
  const required = useRequiredValidation();
  const phoneValidation = usePhoneNumberValidation();
  const { showSuccess } = useContext(AlertContext);

  const [userClientCall, userClientState] = useSafeAsync(api.findClientById);
  const [userCall, userState] = useSafeAsync(api.findUserById);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [deleteUserCall, deleteUserState] = useSafeAsync(api.deleteUser);
  const [updateUserCall, updateUserState] = useSafeAsync(api.updateUser);
  const [restoreUserCall, restoreUserState] = useSafeAsync(api.restoreUser);

  const onRemoveFuncitonHandler = () => {
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  };

  const removeUserHandler = async () => {
    setShowDialog(false);
    // remove user, maybe move of request and events to another user in company
    const result = await deleteUserCall({ clientId: clientId, userId: userId });
    if (typeof result === "boolean" && result) {
      refresh();
      navigate(path);
    }
  };

  const restoreUserHandler = async () => {
    // obnovime uzika
    const result = await restoreUserCall({
      clientId: clientId,
      userId: userId,
    });
    if (typeof result === "boolean" && result) {
      showSuccess(t("whistleblow.user.restored"), "");
      setTimeout(() => {
        refresh();
      }, 250);
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const updateUserHandler = async (data: {
    userPhone: string;
    firstName: string;
    lastName: string;
  }) => {
    // updatneme usera
    const result = await updateUserCall({
      clientId: clientId,
      userId: userId,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.userPhone,
      role: "ADMIN",
    });
    if (typeof result === "object" && result) {
      refresh();
    }
  };

  useEffect(() => {
    userCall({ clientId: clientId, userId: userId });
    userClientCall({ id: clientId });
  }, [clientId, userCall, userClientCall, userId]);

  return (
    <Stack className="main">
      <Navigation />
      <Stack className="px_main" sx={{ mt: 14, mb: 8 }}>
        <Stack direction="row">
          <div className="space"></div>
          <LoadingGuard state={userState}>
            {userState.value && userState.value.dateDeleted == null && (
              <AsyncButton
                variant="contained"
                color="error"
                onClick={onRemoveFuncitonHandler}
                sx={{}}
              >
                {t("whistleblow.user.remove")}
              </AsyncButton>
            )}
            {userState.value && userState.value.dateDeleted != null && (
              <AsyncButton
                variant="contained"
                color="success"
                onClick={restoreUserHandler}
                sx={{}}
              >
                {t("whistleblow.user.restore")}
              </AsyncButton>
            )}
          </LoadingGuard>
        </Stack>
        <LoadingGuard state={userState}>
          <LoadingGuard state={userClientState}>
            <Box className="form_600" sx={{ mt: 3, alignSelf: "center" }}>
              <Typography variant="apph1Bold">
                {t("whistleblow.users.basicInfo", "User detail")}
              </Typography>
              {userState.value && userClientState.value && (
                <FormContainer
                  defaultValues={{
                    companyName: userClientState.value.clientId
                      ? userClientState.value.clientId
                      : "-",
                    userPhone: userState.value.phone
                      ? userState.value.phone
                      : "-",
                    userEmail: userState.value.email
                      ? userState.value.email
                      : "-",
                    firstName: userState.value.firstName
                      ? userState.value.firstName
                      : "-",
                    lastName: userState.value.lastName
                      ? userState.value.lastName
                      : "-",
                  }}
                  onSuccess={updateUserHandler}
                >
                  <TextFieldElement
                    id="companyName"
                    name="companyName"
                    label={t("whistleblow.client.companyName", "Company name")}
                    inputProps={{ readOnly: true }}
                    sx={styles.formItem}
                  />
                  <TextFieldElement
                    id="firstName"
                    name="firstName"
                    label={t("whistleblow.user.firstName")}
                    sx={styles.formItem}
                    validation={{ ...required }}
                  />
                  <TextFieldElement
                    id="lastName"
                    name="lastName"
                    label={t("whistleblow.user.lastName")}
                    sx={styles.formItem}
                    validation={{ ...required }}
                  />
                  <TextFieldElement
                    id="userPhone"
                    name="userPhone"
                    label={t("whistleblow.user.phone", "Phone")}
                    sx={styles.formItem}
                    validation={{ ...required, ...phoneValidation }}
                  />
                  <TextFieldElement
                    id="userEmail"
                    name="userEmail"
                    label={t("whistleblow.user.email", "Email")}
                    inputProps={{ readOnly: true }}
                    sx={styles.formItem}
                  />
                  <Stack direction="row" sx={{ mt: 2 }}>
                    <Button variant="text" onClick={goBackHandler}>
                      {t("whistleblow.common.back", "Back")}
                    </Button>
                    <div className="space"></div>
                    <AsyncButton variant="contained" sx={{}}>
                      {t("whistleblow.common.saveChanges", "Save changes")}
                    </AsyncButton>
                  </Stack>
                </FormContainer>
              )}
            </Box>
          </LoadingGuard>
        </LoadingGuard>
        {showDialog && (
          <CustomDialog
            openDialog={showDialog}
            closed={closeHandler}
            title={t("whistleblow.user.remove", "Remove user")}
            subtitle={t(
              "whistleblow.user.removeDescription",
              "Only user will be removed. Report and report history will remain."
            )}
            commentLabel=""
            commentPlaceholder=""
            buttonText={t("whistleblow.user.remove")}
            onButtonClick={removeUserHandler}
          />
        )}
      </Stack>
      <div className="space" />
      <SmallFooter />
    </Stack>
  );
};

export default UserDetail;
