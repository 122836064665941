import { Box, Stack, TextField } from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";
import { useEmailValidation } from "../form/validations/useEmailValidation";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../models/settings";
import Navigation from "../Navigation";
import NotAllowed from "../ui/NotAllowed";
import SmallFooter from "../ui/SmallFooter";

const NewUser: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      alignSelf: "center",
      alignItems: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const required = useRequiredValidation();
  const emailValidation = useEmailValidation();
  const phoneValidation = usePhoneNumberValidation();
  const { t } = useTranslation();
  const ctx = useContext(AuthContext);

  const navigate = useNavigate();
  const urlData = useParams();
  const clientId = urlData.companyId;
  const api = useApiClient();
  const { refresh } = useContext(UiContext);
  const [createUserCall, createUserState] = useSafeAsync(api.createUser);
  const [getClientInfoPublicByIdCall, getClientInfoPublicByIdState] =
    useSafeAsync(api.getClientInfoPublicById);

  const addNewUserHandler = useCallback(
    async (data: {
      companyId: string;
      userEmail: string;
      firstName: string;
      lastName: string;
      phone: string;
    }) => {
      //add new user
      const result = await createUserCall({
        email: data.userEmail,
        clientId: data.companyId,
        role: "ADMIN",
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
      });
      //const path = "/app/admin/" + data.companyId;
      if (typeof result === "object" && result) {
        refresh();
        navigate(-1);
      }
    },
    [createUserCall, refresh, navigate]
  );

  useEffect(() => {
    getClientInfoPublicByIdCall({ id: clientId });
  }, [clientId, getClientInfoPublicByIdCall]);

  return (
    <Stack className="main">
      <Navigation />
      {!ctx.isLoggedIn && <NotAllowed />}
      {ctx.isLoggedIn && (
        <Stack className="px_main" sx={{ mt: 14, mb: 8, alignItems: "center" }}>
          <Stack className="form_600" sx={{ mt: 5 }}>
            <FormContainer
              defaultValues={{ companyId: clientId }}
              onSuccess={addNewUserHandler}
            >
              <LoadingGuard state={getClientInfoPublicByIdState}>
                {getClientInfoPublicByIdState.value && (
                  <TextField
                    id="company"
                    name="companyId"
                    label={t("whistleblow.client.client", "Client")}
                    disabled
                    value={getClientInfoPublicByIdState.value.name}
                    sx={styles.formItem}
                    SelectProps={{ readOnly: true }}
                  />
                )}
                <TextFieldElement
                  id="userEmail"
                  name="userEmail"
                  label={t("whistleblow.common.email", "Email")}
                  validation={{ ...required, ...emailValidation }}
                  sx={styles.formItem}
                />
                <TextFieldElement
                  id="firstName"
                  name="firstName"
                  label={t("whistleblow.common.firstName", "First name")}
                  validation={{ ...required }}
                  sx={styles.formItem}
                />
                <TextFieldElement
                  id="lastName"
                  name="lastName"
                  label={t("whistleblow.common.lastName", "Last Name")}
                  validation={{ ...required }}
                  sx={styles.formItem}
                />
                <TextFieldElement
                  id="phone"
                  name="phone"
                  label={t("whistleblow.common.phone", "Phone number")}
                  validation={{ ...phoneValidation }}
                  sx={styles.formItem}
                />
                <Stack direction="row" sx={styles.formItem}>
                  <div className="space"></div>
                  <AsyncButton state={createUserState}>
                    {t("whistleblow.user.add", "Add new user")}
                  </AsyncButton>
                </Stack>
              </LoadingGuard>
            </FormContainer>
          </Stack>
        </Stack>
      )}
      <div className="space" />
      <SmallFooter />
    </Stack>
  );
};

export default NewUser;
