import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import i18n from "../../i18n";

const SmallFooter: React.FC = () => {
  const { t } = useTranslation();

  const languages = [
    { id: "cs", label: t("whistleblow.language.czech") },
    { id: "en", label: t("whistleblow.language.english") },
  ];

  const selectLanguageHandler = (data: any) => {
    // vyber jazyka
    i18n.changeLanguage(data);
    localStorage.setItem("savedLanguage", data);
  };

  return (
    <Stack
      direction="row"
      className="px_main printHide"
      sx={{
        maxHeight: 32,
        py: 3,
        borderTop: "1px solid rgba(0,0,0,0.1)",
        /* position: "fixed",
        bottom: 0,
        left: 0, */
        zIndex: 0,
        background: "white",
      }}
    >
      <Typography
        variant="textSmall"
        sx={{ alignSelf: "center", textAlign: "left" }}
      >
        {dayjs().get("year") + " " + t("whistleblow.common.mka", "MKA Nosko")}
      </Typography>
      <div className="space"></div>
      <Stack sx={{ alignSelf: "center" }}>
        <FormContainer
          defaultValues={{ lng: i18n.language }}
          onSuccess={selectLanguageHandler}
        >
          <SelectElement
            name="lng"
            options={languages}
            onChange={selectLanguageHandler}
            size="small"
            SelectProps={{
              MenuProps: {
                sx: {
                  ".MuiInputBase-root": {
                    color: "white",
                  },
                  "MuiSvgIcon-root": {
                    color: "white",
                  },
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                },
              },
            }}
          />
        </FormContainer>
      </Stack>
    </Stack>
  );
};

export default SmallFooter;
