import {Typography} from "@mui/material";
import {bgcolor} from "@mui/system";
import {
    DataGrid,
    GridColDef,
    GridEventListener,
    GridPrintExportOptions,
    GridRowParams,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";

import {useApiClient} from "../../api/useApiClient";
import {useSafeAsync} from "../../hooks/useSafeAsync";
import {LoadingGuard} from "../form/LoadingGuard";
import Chips from "../ui/Chips";

export function renderStateChip(status: string, isForAnonymous?: boolean) {
    if (status === "NEW") {
        if (isForAnonymous) {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.newWhistleblower",
                        bgColor: "white",
                        isWhiteColor: false,
                    }}
                />
            );
        } else {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.new",
                        bgColor: "green",
                        isWhiteColor: true,
                    }}
                />
            );
        }
    } else if (status === "UNDER_REVIEW") {
        if (isForAnonymous) {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.underReviewWhistleblower",
                        bgColor: "white",
                        isWhiteColor: false,
                    }}
                />
            );
        } else {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.underReview",
                        bgColor: "#F6C25C",
                        isWhiteColor: false,
                    }}
                />
            );
        }
    } else if (status === "DISMISSED") {
        if (isForAnonymous) {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.dismissedWhistleblower",
                        bgColor: "white",
                        isWhiteColor: false,
                    }}
                />
            );
        } else {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.dismissed",
                        bgColor: "black",
                        isWhiteColor: true,
                    }}
                />
            );
        }
    } else if (status === "CLOSED") {
        if (isForAnonymous) {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.closedWhistleblower",
                        bgColor: "white",
                        isWhiteColor: false,
                    }}
                />
            );
        } else {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.closed",
                        bgColor: "blue",
                        isWhiteColor: true,
                    }}
                />
            );
        }
    } else if (status === "CLOSED_WITH_ACTION") {
        if (isForAnonymous) {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.closedWithActionWhistleblower",
                        bgColor: "white",
                        isWhiteColor: false,
                    }}
                />
            );
        } else {
            return (
                <Chips
                    chip={{
                        label: "whistleblow.status.closedWithAction",
                        bgColor: "purple",
                        isWhiteColor: true,
                    }}
                />
            );
        }
    }
    return <React.Fragment>-</React.Fragment>;
}

const ReportTable: React.FC = () => {
    const data = useParams();
    const clientId = data.companyId;
    const navigate = useNavigate();

    const api = useApiClient();

    const [pageSize, setPageSize] = useState(10);

    const [allReportsCall, allReportsState] = useSafeAsync(
        api.findAllReportsByClientId
    );

    const {t} = useTranslation();

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{alignContent: "end"}}>
                <GridToolbarExport
                    sx={{color: "black"}}
                    printOptions={{
                        hideFooter: true,
                        hideToolbar: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }

    const customLocale = {
        toolbarExport: t("whistleblow.common.export"),
        toolbarExportCSV: t("whistleblow.common.exportCSV"),
        toolbarExportPrint: t("whistleblow.common.exportPrint"),
        columnMenuLabel: t("whistleblow.tableSettings.columnMenuLabel"),
        columnMenuShowColumns: t("whistleblow.tableSettings.columnMenuShowColumns"),
        columnMenuFilter: t("whistleblow.tableSettings.columnMenuFilter"),
        columnMenuHideColumn: t("whistleblow.tableSettings.columnMenuHideColumn"),
        columnMenuUnsort: t("whistleblow.tableSettings.columnMenuUnsort"),
        columnMenuSortAsc: t("whistleblow.tableSettings.columnMenuSortAsc"),
        columnMenuSortDesc: t("whistleblow.tableSettings.columnMenuSortDesc"),
        filterPanelAddFilter: t("whistleblow.tableSettings.filterPanelAddFilter"),
        filterPanelDeleteIconLabel: t(
            "whistleblow.tableSettings.filterPanelDeleteIconLabel"
        ),
        filterPanelLinkOperator: t(
            "whistleblow.tableSettings.filterPanelLinkOperator"
        ),
        filterPanelOperators: t("whistleblow.tableSettings.filterPanelOperators"),
        filterPanelOperatorAnd: t(
            "whistleblow.tableSettings.filterPanelOperatorAnd"
        ),
        filterPanelOperatorOr: t("whistleblow.tableSettings.filterPanelOperatorOr"),
        filterPanelColumns: t("whistleblow.tableSettings.filterPanelColumns"),
        filterPanelInputLabel: t("whistleblow.tableSettings.filterPanelInputLabel"),
        filterPanelInputPlaceholder: t(
            "whistleblow.tableSettings.filterPanelInputPlaceholder"
        ),
        columnsPanelTextFieldLabel: t(
            "whistleblow.tableSettings.columnsPanelTextFieldLabel"
        ),
        columnsPanelTextFieldPlaceholder: t(
            "whistleblow.tableSettings.columnsPanelTextFieldPlaceholder"
        ),
        columnsPanelDragIconLabel: t(
            "whistleblow.tableSettings.columnsPanelDragIconLabel"
        ),
        columnsPanelShowAllButton: t(
            "whistleblow.tableSettings.columnsPanelShowAllButton"
        ),
        columnsPanelHideAllButton: t(
            "whistleblow.tableSettings.columnsPanelHideAllButton"
        ),
        filterOperatorContains: t(
            "whistleblow.tableSettings.filterOperatorContains"
        ),
        filterOperatorEquals: t("whistleblow.tableSettings.filterOperatorEquals"),
        filterOperatorStartsWith: t(
            "whistleblow.tableSettings.filterOperatorStartsWith"
        ),
        filterOperatorEndsWith: t(
            "whistleblow.tableSettings.filterOperatorEndsWith"
        ),
        filterOperatorIs: t("whistleblow.tableSettings.filterOperatorIs"),
        filterOperatorNot: t("whistleblow.tableSettings.filterOperatorNot"),
        filterOperatorAfter: t("whistleblow.tableSettings.filterOperatorAfter"),
        filterOperatorOnOrAfter: t(
            "whistleblow.tableSettings.filterOperatorOnOrAfter"
        ),
        filterOperatorBefore: t("whistleblow.tableSettings.filterOperatorBefore"),
        filterOperatorOnOrBefore: t(
            "whistleblow.tableSettings.filterOperatorOnOrBefore"
        ),
        filterOperatorIsEmpty: t("whistleblow.tableSettings.filterOperatorIsEmpty"),
        filterOperatorIsNotEmpty: t(
            "whistleblow.tableSettings.filterOperatorIsNotEmpty"
        ),
        filterOperatorIsAnyOf: t("whistleblow.tableSettings.filterOperatorIsAnyOf"),
    };

    const reportsColumns: GridColDef[] = [
        {field: "id", headerName: t("whistleblow.report.table.id"), width: 200},
        {
            field: "recieved",
            headerName: t("whistleblow.report.table.recieved"),
            width: 150,
        },
        {
            field: "timeToSolve",
            headerName: t("whistleblow.report.table.timeToSolve"),
            width: 150,
        },
        {
            field: "status",
            headerName: t("whistleblow.report.table.status"),
            width: 250,
            renderCell: ({row}: Partial<GridRowParams>) =>
                renderStateChip(row.status),
        },
        {
            field: "priority",
            headerName: t("whistleblow.report.table.priority"),
        },
        {
            field: "channel",
            headerName: t("whistleblow.report.table.channel"),
        },
        {
            field: "category",
            headerName: t("whistleblow.report.table.category"),
            width: 200,
        },
        {
            field: "comment",
            headerName: t("whistleblow.report.table.comment"),
            width: 300,
        },
    ];

    const getChannelText = (channelId: string) => {
        switch (channelId) {
            case "WEB":
                return t("whistleblow.channel.web");
            case "EMAIL":
                return t("whistleblow.channel.email");
            case "CALL":
                return t("whistleblow.channel.call");
            case "PERSONAL":
                return t("whistleblow.channel.personal");
            case "POSTAL":
                return t("whistleblow.channel.postal");
        }
        return "-";
    };

    const getPriorityText = (priorityId: string) => {
        switch (priorityId) {
            case "LOW":
                return t("whistleblow.priority.low");
            case "MEDIUM":
                return t("whistleblow.priority.medium");
            case "HIGH":
                return t("whistleblow.priority.high");
        }
        return "-";
    }
    //    ReportCategory: "AML" | "DATA_PROTECTION" | "SAFETY" | "FINANCIAL_MISCONDUCT" | "WORKPLACE_MISCONDUCT" | "CORRUPTION" |
    //    "REGULATORY_AND_LEGAL_VIOLATION" | "CYBERSECURITY" | "ENVIRONMENT_AND_SUSTAINABILITY" | "DISCRIMINATION_AND_HARASSMENT" |
    //    "PROCUREMENT_AND_CONTRACTING" | "INTELLECTUAL_PROPERTY" | "CONFLICT_OF_INTEREST" | "HEALTH_AND_SAFETY" | "OTHER";
    const getCategoryText = (categoryId: string) => {
        switch (categoryId) {
            case "AML":
                return t("whistleblow.category.aml");
            case "DATA_PROTECTION":
                return t("whistleblow.category.dataProtection");
            case "SAFETY":
                return t("whistleblow.category.safety");
            case "FINANCIAL_MISCONDUCT":
                return t("whistleblow.category.financialMisconduct");
            case "WORKPLACE_MISCONDUCT":
                return t("whistleblow.category.workplaceMisconduct");
            case "CORRUPTION":
                return t("whistleblow.category.corruption");
            case "REGULATORY_AND_LEGAL_VIOLATION":
                return t("whistleblow.category.regulatoryAndLegalViolation");
            case "CYBERSECURITY":
                return t("whistleblow.category.cyberSecurity");
            case "ENVIRONMENT_AND_SUSTAINABILITY":
                return t("whistleblow.category.environment");
            case "DISCRIMINATION_AND_HARASSMENT":
                return t("whistleblow.category.discrimination");
            case "PROCUREMENT_AND_CONTRACTING":
                return t("whistleblow.category.procurement");
            case "INTELLECTUAL_PROPERTY":
                return t("whistleblow.category.intellectualProperty");
            case "CONFLICT_OF_INTEREST":
                return t("whistleblow.category.conflictOfInterest");
            case "HEALTH_AND_SAFETY":
                return t("whistleblow.category.healthAndSafety");
            case "OTHER":
                return t("whistleblow.category.other");
        }
        return "-";
    };

    const reportsRow = useMemo(() => {
        if (allReportsState.value == null) {
            return [];
        }
        return allReportsState.value.data.map((report) => {
            return {
                id: report.id,
                recieved: dayjs(report.createDate).format("DD.MM.YYYY HH:mm"),
                timeToSolve: report.remainingDaysToSolve,
                priority: getPriorityText(report.priority),
                channel: getChannelText(report.channel),
                category: getCategoryText(report.category),
                comment: report.publicComment ? report.publicComment : "-",
                status: report.status,
            };
        });
    }, [allReportsState.value]);

    const reportDetailHandler: GridEventListener<"rowClick"> = (params) => {
        const path = "/app/" + clientId + "/admin/reports/" + params.id + "/0";
        navigate(path);
    };

    useEffect(() => {
        allReportsCall({clientId: clientId});
    }, [allReportsCall, clientId]);

    return (
        <LoadingGuard state={allReportsState}>
            {reportsRow.length === 0 && (
                <Typography sx={{mt: 2}}>
                    {t("whistleblow.common.notFounded")}
                </Typography>
            )}
            {reportsRow.length > 0 && (
                <DataGrid
                    rows={reportsRow}
                    columns={reportsColumns}
                    components={{Toolbar: CustomToolbar}}
                    localeText={customLocale}
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: t("whistleblow.common.rowPerPage"),
                        },
                    }}
                    sx={{
                        width: "100%",
                        minHeight: "600px",
                        overflowX: "scroll",
                        mt: 5,
                        "& .MuiDataGrid-row:hover": {
                            cursor: "pointer",
                        },
                        "@media print": {
                            width: "170%!important",
                            overflow: "visible!important",
                            m: "0!important",
                            p: "0!important",
                            "@page": {size: "A4 landscape"},
                            /* "*::after": {
                              "@page": {
                                size: "portrait",
                              },
                            }, */
                        },
                    }}
                    onRowClick={reportDetailHandler}
                    rowsPerPageOptions={[10, 25, 50]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPage) => setPageSize(newPage)}
                />
            )}
        </LoadingGuard>
    );
};

export default ReportTable;
