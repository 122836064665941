import { Sensors } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { defineStyles } from "../../styles/defineStyles";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { LoadingGuard } from "../form/LoadingGuard";
import i18n from "../../i18n";
import UiContext from "../contexts/UiContext";

const FooterAnonymous: React.FC = () => {
  const styles = defineStyles({
    main: {
      background: "#37474f",
      color: "white",
      py: 3,
    },
    loginName: {
      pl: 1,
      fontSize: "20px",
      fontWeight: "500",
    },
    icon: {
      width: "32px",
      height: "32px",
      ml: 2,
    },
    selectElementStyle: {
      ".MuiInputBase-root": {
        color: "white",
      },
      "&& MuiSvgIcon-root": {
        color: "white",
      },
    },
  });

  const { t } = useTranslation();
  const { companyId } = useParams();
  const { refresh } = useContext(UiContext);
  let showedName = "";
  let path = "";
  const api = useApiClient();
  const [clientDetailCall, clientDetailState] = useSafeAsync(
    api.getClientInfoPublicById
  );

  if (companyId == undefined || companyId == null) {
    showedName = "SuperAdmin";
    path = "/app/admin/settings";
  } else {
    showedName = companyId;
    path = "/app/" + companyId + "/admin/settings";
  }

  const languages = [
    { id: "cs", label: t("whistleblow.language.czech") },
    { id: "en", label: t("whistleblow.language.english") },
  ];

  const selectLanguageHandler = (data: any) => {
    i18n.changeLanguage(data);
    localStorage.setItem("savedLanguage", data);
  };

  useEffect(() => {
    const result = clientDetailCall({ id: companyId });
  }, [companyId, clientDetailCall]);

  return (
    <LoadingGuard state={clientDetailState}>
      {clientDetailState.value && (
        <Stack direction="column" className="px_main" sx={styles.main}>
          <Stack direction="row" sx={{ mb: 3 }}>
            <Sensors
              sx={{
                mr: 1,
                width: { xs: "22px", sm: "22px", md: "22px", lg: "24px" },
                height: { xs: "22px", sm: "22px", md: "22px", lg: "24px" },
              }}
            />
            <Typography
              variant="navLogo"
              sx={{
                fontSize: { xs: "90%", sm: "90%", md: "90%", lg: "100%" },
              }}
            >
              {t("whistleblow.appName", "Compliance Report")} |{" "}
              {companyId == null || companyId == undefined
                ? t("whistleblow.common.superAdmin")
                : clientDetailState.value.name
                ? clientDetailState.value.name
                : t("whistleblow.common.notAvailable")}
            </Typography>
            <div className="space"></div>
            <FormContainer
              defaultValues={{ lng: i18n.language }}
              onSuccess={selectLanguageHandler}
            >
              <SelectElement
                name="lng"
                options={languages}
                onChange={selectLanguageHandler}
                size="small"
                variant="standard"
                color="info"
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
                SelectProps={{
                  SelectDisplayProps: {
                    style: { color: "white", background: "#37474f" },
                  },
                  MenuProps: {
                    sx: {
                      "&& .MuiList-root": {
                        backgroundColor: "#37474f",
                        color: "white",
                      },
                      "&& .MuiMenuItem-root": {
                        backgroundColor: "#37474f",
                        color: "white",
                      },
                    },
                  },
                }}
              />
            </FormContainer>
          </Stack>

          <Stack direction="column" sx={{}}>
            <Typography variant="textSmall">
              {clientDetailState.value.name
                ? clientDetailState.value.name
                : companyId}
            </Typography>
            {clientDetailState.value.address && (
              <Stack>
                <Typography variant="textSmall" sx={{}}>
                  {clientDetailState.value
                    ? clientDetailState.value.address.street +
                      " " +
                      clientDetailState.value.address.streetNumber
                    : "Street p.n. 25"}
                </Typography>
                <Typography variant="textSmall" sx={{}}>
                  {clientDetailState.value
                    ? clientDetailState.value.address.city
                    : "City N.Y."}
                </Typography>
                <Typography variant="textSmall" sx={{}}>
                  {clientDetailState.value
                    ? clientDetailState.value.address.country
                    : "Country"}
                </Typography>
              </Stack>
            )}
            <Typography variant="textSmall" sx={{}}>
              {clientDetailState.value
                ? "ID " + clientDetailState.value.ico
                : "Company ID"}
            </Typography>
          </Stack>
        </Stack>
      )}
    </LoadingGuard>
  );
};

export default FooterAnonymous;
