import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  Link,
  Button,
  ListItemButton,
  AppBar,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Toolbar,
  ListItemIcon,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Link as LinkDom,
  useHref,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Send, Sensors } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";

import { useSafeAsync } from "../hooks/useSafeAsync";
import { useApiClient } from "../api/useApiClient";
import { defineStyles } from "../styles/defineStyles";

import AuthContext from "./contexts/AuthContext";

import "../index.css";

export let companyName: string = null;

const Navigation: React.FC<{
  isLandingPage?: boolean;
}> = (props) => {
  const ctx = useContext(AuthContext);
  const style = defineStyles({
    menu: {
      height: { xs: "64px", sm: "64px" },
      display: "flex",
      boxShadow:
        "0px 2px 1px -1px rgba(255, 255, 255, 0.2), 0px 1px 1px rgba(255, 255, 255, 0.14), 0px 1px 3px rgba(255, 255, 255, 0.12)",
      position: "fixed",
      left: "0",
      top: "0",
      zIndex: "90",
      background: "#37474f",
      color: "white",
    },
    icon: {
      width: "32px",
      height: "32px",
      ml: 2,
    },
    loginName: {
      pl: 1,
      fontSize: "20px",
      fontWeight: "500",
    },
    avatar: {
      width: "40px",
      height: "40px",
      mr: 7,
    },
    menuItemText: {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    menuItemSubtext: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    iconNotification: {
      width: "24px",
      height: "24px",
      mr: 4,
    },
    bold: {
      fontWeigth: "bold",
    },
    notificationRow: {
      alignItems: "center",
    },
  });

  const drawerWidth = 400;
  const extraButtonHeight = 40;
  const data = useParams();
  const clientId = data.companyId;
  const userId = data.userId;
  const { t } = useTranslation();
  const api = useApiClient();

  const companyId = data.companyId;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openUser = Boolean(anchorEl);
  const [selectedMenu, setSelectedMenu] = useState<number | null>(
    window.location.pathname === "/app/" + clientId + "/settings/" + "0" ||
      window.location.pathname === "/app/" + clientId + "/settings/" + "1" ||
      window.location.pathname === "/app/" + clientId + "/settings/" + "2" ||
      window.location.pathname === "/app/" + clientId + "/settings/" + "3" ||
      //window.location.pathname === "/app/" + clientId + "/admin/settings" ||
      window.location.pathname === "/app/admin/" + clientId + "/" + userId
      ? 1
      : window.location.pathname === "/app/" + clientId + "/admin/settings"
      ? 2
      : 0
  );
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const container = document.body;

  const [clientDetailCall, clientDetailState] = useSafeAsync(
    api.getClientInfoPublicById
  );

  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleClickUser = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseUser = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const loggedUserRole = useMemo(() => {
    if (!ctx.isLoggedIn) {
      return "NotLoggedIn";
    } else {
      if (ctx.loggedUser.userDTO.role === "SUPER_ADMIN")
        return t("whistleblow.common.superAdmin");
      if (ctx.loggedUser.userDTO.role === "ADMIN")
        return t("whistleblow.common.admin");
    }
    return "Unknown";
  }, [ctx.loggedUser, ctx.isLoggedIn, t]);

  let path = "";
  if (companyId == undefined || null) {
    path = "/app/admin/settings";
  } else {
    path = "/app/" + companyId + "/admin/settings";
  }

  let homePath = "";
  if (!ctx.isLoggedIn && companyId != undefined) {
    homePath = "/app/" + companyId;
  } else if (ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "SUPER_ADMIN") {
    homePath = "/app/admin";
  } else if (ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN") {
    homePath = "/app/" + companyId + "/admin";
  }

  const checkStatusHandler = () => {
    navigate("/app/" + companyId + "/status");
  };

  const newReportHandler = () => {
    navigate("/app/" + companyId + "/newReport");
  };

  const goToReportsHandler = () => {
    setSelectedMenu(0);
    navigate("/app/" + companyId + "/admin/reports/");
  };
  const goToSettingsHandler = () => {
    setSelectedMenu(1);
    navigate("/app/" + companyId + "/settings/0");
  };
  const goToClientsHandler = () => {
    navigate("/app/admin");
  };

  const manageAccountHandler = () => {
    setSelectedMenu(3);
    navigate(path);
  };

  /* const companyName = useCallback(() => {
    if (clientId == null || clientId == undefined) {
      return "SuperAdmin";
    } else if (
      clientDetailState.value != null &&
      clientDetailState.value.name
    ) {
      return clientDetailState.value.name;
    } else {
      return t("whistleblow.common.notAvailable");
    }
  }, [clientId, clientDetailState]); */

  useEffect(() => {
    if (clientId != null || clientId != undefined) {
      clientDetailCall({ id: clientId });
    }
  }, [clientDetailCall, clientId]);

  useEffect(() => {
    if (clientDetailState.value != null) {
      if (companyName == null) {
        if (clientId != null) {
          if (clientDetailState.value != null && clientDetailState.value.name) {
            companyName = clientDetailState.value.name;
          } else {
            companyName = t("whistleblow.common.notAvailable");
          }
        }
      }
    } else if (companyName == null) {
      if (clientId == null || clientId == undefined) {
        companyName = "SuperAdmin";
      }
    }
  }, [clientDetailState.value, clientId, t]);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "left" }}>
      <List>
        {/* <ListItemButton onClick={() => navigate(homePath)}>
          <ListItemIcon>
            <Sensors />
          </ListItemIcon>
          <ListItemText
            primary={t("whistleblow.appName") + " | " + showedName}
            sx={{ fontWeight: 700 }}
          />
        </ListItemButton>
        <Divider sx={{ borderBottomWidth: "3px" }} /> */}
        {!ctx.isLoggedIn && (
          <React.Fragment>
            {props.isLandingPage && (
              <React.Fragment>
                <ListItemButton
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "#about";
                  }}
                >
                  <ListItemText
                    sx={{ textDecoration: "none" }}
                    primary={t("whistleblow.common.about")}
                  />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "#howItWorks";
                  }}
                >
                  <ListItemText primary={t("whistleblow.common.howItWorks")} />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "#contact";
                  }}
                >
                  <ListItemText primary={t("whistleblow.common.contact")} />
                </ListItemButton>
                <Divider />
              </React.Fragment>
            )}
            <ListItemButton onClick={newReportHandler}>
              <ListItemText primary={t("whistleblow.common.SubmitAReport")} />
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={checkStatusHandler}>
              <ListItemText primary={t("whistleblow.common.checkStatus")} />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        )}
        {ctx.isLoggedIn && (
          <React.Fragment>
            {ctx.loggedUser.userDTO.role === "ADMIN" && (
              <React.Fragment>
                <ListItemButton
                  id="reports"
                  onClick={goToReportsHandler}
                  selected={selectedMenu === 0}
                >
                  <ListItemText primary={t("whistleblow.report.report")} />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  id="settings"
                  onClick={goToSettingsHandler}
                  selected={selectedMenu === 1}
                >
                  <ListItemText primary={t("whistleblow.settings.settings")} />
                </ListItemButton>
                <Divider />
                <ListItemButton id="addAdminReport" onClick={newReportHandler}>
                  <ListItemText primary={t("whistleblow.common.addAReport")} />
                </ListItemButton>
              </React.Fragment>
            )}
            {ctx.loggedUser.userDTO.role === "SUPER_ADMIN" && (
              <ListItemButton id="clients" onClick={goToClientsHandler}>
                <ListItemText primary={t("whistleblow.common.clients")} />
              </ListItemButton>
            )}
            <Divider sx={{ borderBottomWidth: "3px" }} />
            <ListItem>
              <ListItemText
                primary={
                  ctx.loggedUser.userDTO.firstName &&
                  ctx.loggedUser.userDTO.lastName
                    ? ctx.loggedUser.userDTO.firstName +
                      " " +
                      ctx.loggedUser.userDTO.lastName
                    : ctx.loggedUser.userDTO.email
                }
                secondary={loggedUserRole}
              />
            </ListItem>
            <Divider />
            <ListItemButton id="manageAccount" onClick={manageAccountHandler}>
              <ListItemText primary={t("whistleblow.navigation.manage")} />
            </ListItemButton>
            <Divider />
            {/* {ctx.loggedUser.userDTO.role === "ADMIN" && (
              <ListItemButton id="support">
                <ListItemText primary={t("whistleblow.navigation.support")} />
              </ListItemButton>
            )}
            <Divider /> */}
            <ListItemButton id="logout">
              <ListItemText
                primary={t("whistleblow.navigation.signOut")}
                onClick={ctx.doLogout}
              />
            </ListItemButton>
          </React.Fragment>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar className="main px_main" sx={style.menu}>
      <Stack
        direction="row"
        sx={{
          flexGrow: 1,
        }}
      >
        <Link
          href={homePath}
          sx={{
            textDecoration: "none",
            color: "white",
            alignSelf: "center",
            mr: { xs: 5, sm: 0 },
          }}
        >
          <Stack direction="row" sx={{ flexGrow: 1 }}>
            <Sensors
              sx={{
                mr: 1,
                width: { xs: "22px", sm: "22px", md: "22px", lg: "24px" },
                height: { xs: "22px", sm: "22px", md: "22px", lg: "24px" },
              }}
            />
            <Typography
              variant="navLogo"
              sx={{
                fontSize: { xs: "90%", sm: "90%", md: "90%", lg: "100%" },
              }}
            >
              {t("whistleblow.appName", "Compliance Report")} | {companyName}
            </Typography>
          </Stack>
        </Link>
        {!ctx.isLoggedIn &&
          (clientId != null || clientId != undefined) &&
          props.isLandingPage && (
            <Stack
              direction="row"
              sx={{
                ml: { lg: 3, xl: 9 },
                display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                flexGrow: 1,
              }}
            >
              <Link
                href="#about"
                sx={{
                  color: "white",
                  alignSelf: "center",
                  mr: { lg: 2, xl: 3.5 },
                  textDecoration: "none",
                }}
              >
                <Typography variant="textMedium">
                  {t("whistleblow.common.about", "About")}
                </Typography>
              </Link>
              <Link
                href="#howItWorks"
                sx={{
                  color: "white",
                  alignSelf: "center",
                  mr: { lg: 2, xl: 3.5 },
                  textDecoration: "none",
                }}
              >
                <Typography variant="textMedium">
                  {t("whistleblow.common.howItWorks", "How it works")}
                </Typography>
              </Link>
              <Link
                href="#contact"
                sx={{
                  color: "white",
                  alignSelf: "center",
                  mr: { lg: 2, xl: 3.5 },
                  textDecoration: "none",
                }}
              >
                <Typography variant="textMedium">
                  {t("whistleblow.common.contact", "Contact")}
                </Typography>
              </Link>
              <div className="space" />
              <Button
                variant="outlined"
                color="info"
                onClick={checkStatusHandler}
                sx={{
                  mr: 2,
                  alignSelf: "center",
                }}
              >
                <Typography variant="textMedium">
                  {t("whistleblow.common.checkStatus", "Check status")}
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={newReportHandler}
                sx={{ mr: 0, alignSelf: "center", height: extraButtonHeight }}
              >
                <Stack direction="row">
                  <Typography variant="textMedium">
                    {t("whistleblow.common.submit", "Submit")}
                  </Typography>
                  <Send sx={{ ml: 1 }} />
                </Stack>
              </Button>
            </Stack>
          )}

        {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "SUPER_ADMIN" && (
          <Stack
            direction="row"
            sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}
          >
            <Button
              variant="outlined"
              onClick={goToClientsHandler}
              color="info"
              sx={{
                ml: 4.5,
                width: "150px",
                alignSelf: "center",
              }}
            >
              <Typography variant="textMedium">
                {t("whistleblow.common.clients")}
              </Typography>
            </Button>
          </Stack>
        )}
        {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN" && (
          <React.Fragment>
            <div className="space" />
            <Stack
              direction="row"
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              }}
            >
              <Stack
                direction="row"
                spacing={5}
                sx={{
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: "black",
                    "&, & .MuiListItemIcon-root": {
                      color: "white",
                    },
                  },
                }}
              >
                <ListItemButton
                  id="reports"
                  onClick={goToReportsHandler}
                  sx={{ alignSelf: "center" }}
                  selected={selectedMenu === 0}
                >
                  <Typography variant="textMedium">
                    {t("whistleblow.report.report")}
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  id="settings"
                  onClick={goToSettingsHandler}
                  sx={{ alignSelf: "center" }}
                  selected={selectedMenu === 1}
                >
                  <Typography variant="textMedium">
                    {t("whistleblow.settings.settings")}
                  </Typography>
                </ListItemButton>
                <div className="space"></div>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                onClick={newReportHandler}
                sx={{ mr: 10, width: "220px", alignSelf: "center" }}
              >
                <Stack direction="row">
                  <Typography variant="textBase">
                    {t("whistleblow.common.addAReport")}
                  </Typography>
                  <Send sx={{ ml: 1 }} />
                </Stack>
              </Button>
            </Stack>
          </React.Fragment>
        )}
      </Stack>
      {ctx.isLoggedIn && (
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            position: "absolute",
            right: { lg: 64, xl: 128 },
            top: "7px",
          }}
        >
          <Tooltip
            title={t("whistleblow.common.accountSettings", "Account settings")}
            sx={style.avatar}
          >
            <IconButton
              onClick={handleClickUser}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{ alignSelf: "center" }}
            >
              <Avatar>
                {ctx.loggedUser.userDTO.lastName
                  ? ctx.loggedUser.userDTO.lastName.charAt(0).toUpperCase()
                  : ctx.loggedUser.userDTO.email.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openUser}
            onClose={handleCloseUser}
            onClick={handleCloseUser}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 60,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Box>
                <Typography sx={style.menuItemText}>
                  {ctx.loggedUser.userDTO.role === "ADMIN" &&
                  ctx.loggedUser.userDTO.firstName
                    ? ctx.loggedUser.userDTO.firstName
                    : ctx.loggedUser.userDTO.email}{" "}
                  {ctx.loggedUser.userDTO.role === "ADMIN"
                    ? ctx.loggedUser.userDTO.lastName
                    : ""}
                </Typography>
                <Typography sx={style.menuItemSubtext}>
                  {loggedUserRole}
                </Typography>
              </Box>
            </MenuItem>
            <Divider />
            {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN" && (
              <Stack>
                <MenuItem>
                  <LinkDom to={path} className="navigationMenuItem">
                    <Typography>
                      {t("whistleblow.navigation.manage", "Manage account")}
                    </Typography>
                  </LinkDom>
                </MenuItem>
                {/* <MenuItem>
                  <Typography>
                    {t("whistleblow.navigation.support", "Support")}
                  </Typography>
                </MenuItem> */}
                <Divider />
              </Stack>
            )}
            <MenuItem onClick={ctx.doLogout}>
              <Typography sx={style.menuItemText}>
                {t("whistleblow.navigation.signOut")}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
      <Toolbar
        sx={{
          display: { md: "flex", lg: "none" },
          position: "absolute",
          right: { xs: 0, sm: 0, md: 32 },
          top: { xs: "4px", sm: 0 },
          ml: 2,
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        container={container}
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: {
            md: "block",
            lg: "none",
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Navigation;
