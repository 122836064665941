import { Stack } from "@mui/material";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import { LoadingGuard } from "../form/LoadingGuard";

const ClientsTable: React.FC = () => {
  const styles = defineStyles({
    table: {
      mt: 3,
      minHeight: "640px",
      minWidth: "300px",
      width: "100%",
      alignSelf: "center",
      overflowX: "scroll",
    },
    head: {
      fontWeight: "bold",
      width: "100%",
    },
    headName: {
      fontWeight: "bold",
      width: "40%",
    },
    headDescription: {
      fontWeight: "bold",
      width: "40%",
    },
    headItem: {
      fontWeight: "bold",
      width: "10%",
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);

  const api = useApiClient();
  const [allClientsCall, allClientsState] = useSafeAsync(api.findAllClients);

  const clientsColumns: GridColDef[] = [
    {
      field: "clientId",
      headerName: t("whistleblow.client.id", "Url id of a client"),
      width: 150,
    },
    {
      field: "clientName",
      headerName: t("whistleblow.client.name", "Name"),
      width: 200,
    },
    {
      field: "clientIco",
      headerName: t("whistleblow.client.ico", "Clients ICO"),
      width: 150,
    },
    {
      field: "deleteDate",
      headerName: t("whistleblow.common.deleted", "Deleted"),
      width: 100,
    },
  ];

  const clientsRows = useMemo(() => {
    if (allClientsState.value == null) {
      return [];
    }
    return allClientsState.value.data.map((client) => {
      return {
        id: client.clientId,
        clientId: client.clientId,
        clientName: client.name,
        clientIco: client.ico,
        deleteDate: client.dateDeleted,
      };
    });
  }, [allClientsState.value]);

  const clientDetailHandler: GridEventListener<"rowClick"> = (params) => {
    const path = "/app/admin/" + params.id.toString();
    navigate(path);
  };

  useEffect(() => {
    allClientsCall({});
  }, [allClientsCall]);

  return (
    <LoadingGuard state={allClientsState}>
      <DataGrid
        rows={clientsRows}
        columns={clientsColumns}
        sx={{
          ...styles.table,
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        onRowClick={clientDetailHandler}
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
      />
    </LoadingGuard>
  );
};

export default ClientsTable;
