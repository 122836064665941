import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const NotAllowed: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack className="main px_main" sx={{ mt: 14, mb: 10 }}>
      <Typography variant="textBold">
        {t("common.notAllowed", "You are not allowed be here. Try to login.")}
      </Typography>
      <div className="space" />
    </Stack>
  );
};

export default NotAllowed;
