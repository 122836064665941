import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

export const useIsDateInPast = (maxYear: number) => {
  const { t } = useTranslation();

  return {
    validate: (date?: Dayjs) => {
      if (
        (dayjs(date) < dayjs() && dayjs(date).year() > maxYear) ||
        date == null
      ) {
        return undefined;
      } else {
        return t("whistleblow.error.dateInPast", { maxYear: maxYear });
      }
    },
  };
};
