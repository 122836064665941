import { AddRounded } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AlertContext from "../contexts/AlertContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";

const UserTable: React.FC = () => {
  const styles = defineStyles({
    table: {
      mt: 3,
      width: "100%",
      minHeight: "320px",
      /* overflowX: { sm: "scroll", md: "hidden" }, */
    },
    head: {
      fontWeight: "bold",
      width: "100%",
    },
    headName: {
      fontWeight: "bold",
      width: "20%",
    },
    headDescription: {
      fontWeight: "bold",
      width: "20%",
    },
    headItem: {
      fontWeight: "bold",
      width: "10%",
    },
    iconButton: {
      mr: 1,
    },
  });

  const data = useParams();
  const clientId = data.companyId;
  const navigate = useNavigate();
  const { showSuccess } = useContext(AlertContext);
  const { refresh } = useContext(UiContext);

  const api = useApiClient();
  const { t } = useTranslation();

  const [pageSize, setPageSize] = useState(10);

  const [allUsersForClientCall, allUsersForClientState] = useSafeAsync(
    api.findAllUsersByClientId
  );
  const [restoreUserCall, restoreUserState] = useSafeAsync(api.restoreUser);

  const usersColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t("whistleblow.common.name", "Name"),
      width: 200,
    },
    {
      field: "userEmail",
      headerName: t("whistleblow.common.email", "Email"),
      width: 200,
    },
    {
      field: "isDeleted",
      headerName: t("whistleblow.common.deleted", "Deleted"),
      width: 200,
    },
    /* {
      field: "actions",
      headerName: t("whistleblow.user.actions", "Actions"),
      width: 200,
      renderCell: ({ row }: Partial<GridRowParams>) => renderButton(row.id),
    }, */
  ];

  const isDeleted = (id: number) => {
    if (allUsersForClientState.value == null) return false;
    for (let i = 0; i < allUsersForClientState.value.data.length; i++) {
      if (id === allUsersForClientState.value.data[i].id) {
        if (allUsersForClientState.value.data[i].dateDeleted) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  const restoreUserHandler = async (id: number) => {
    const result = await restoreUserCall({ clientId: clientId, userId: id });
    if (typeof result === "boolean" && result) {
      showSuccess(t("whistleblow.user.restored"), "");
      setTimeout(() => {
        refresh();
      }, 250);
    }
  };

  const renderButton = (id: number) => {
    if (allUsersForClientState.value == null) return <></>;
    return (
      <React.Fragment>
        {isDeleted(id) && (
          <AsyncButton
            color="success"
            variant="contained"
            state={restoreUserState}
            onClick={() => restoreUserHandler(id)}
          >
            <AddRounded sx={styles.iconButton} />
            {t("whistleblow.common.restore", "Restore")}
          </AsyncButton>
        )}
      </React.Fragment>
    );
  };

  const usersRows = useMemo(() => {
    if (allUsersForClientState.value == null) {
      return [];
    }
    return allUsersForClientState.value.data.map((user) => {
      return {
        id: user.id,
        name: user.lastName ? user.lastName + " " + user.firstName : "-",
        userEmail: user.email,
        isDeleted: user.dateDeleted
          ? dayjs(user.dateDeleted).format("DD.MM.YYYY HH:mm")
          : "-",
      };
    });
  }, [allUsersForClientState.value]);

  const userDetailHandler: GridEventListener<"rowClick"> = (params) => {
    const path = "/app/admin/" + clientId + "/" + params.id;
    navigate(path);
  };

  useEffect(() => {
    allUsersForClientCall({ clientId: clientId, activeOnly: false });
  }, [allUsersForClientCall, clientId]);

  return (
    <Stack direction="column" sx={{ alignItems: "center" }}>
      <LoadingGuard state={allUsersForClientState}>
        {usersRows.length === 0 && (
          <Typography sx={{ mt: 2 }}>
            {t("whistleblow.user.notFound", "No user was found.")}
          </Typography>
        )}
        {usersRows.length > 0 && (
          <DataGrid
            rows={usersRows}
            columns={usersColumns}
            sx={{
              ...styles.table,
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer",
              },
            }}
            onRowClick={userDetailHandler}
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
          />
        )}
      </LoadingGuard>
    </Stack>
  );
};

export default UserTable;
