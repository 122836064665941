import { Fetcher } from "openapi-typescript-fetch";

import { paths } from "./generated/ea-whistleblowing-api";
import { safe404call } from "./safe404call";

export const buildApiClient = (baseUrl: string, authKey?: string) => {
  const f = Fetcher.for<paths>();
  f.configure({
    baseUrl: baseUrl,
    init: {
      headers: authKey != null ? { Authorization: authKey } : {},
    },
  });
  return {
    createReport: safe404call(
      f.path("/api/v1/clients/{clientId}/reports").method("post").create()
    ),
    createReportByAdmin: safe404call(
      f.path("/api/v1/clients/{clientId}/reports/admin").method("post").create()
    ),
    getReportByAccessCode: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/status/{code}")
        .method("get")
        .create()
    ),
    sendCodeToEmailByAccessCode: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/code/{code}/sendByEmail")
        .method("put")
        .create()
    ),
    sendEmailForStatusChangeAnonymous: safe404call(
      f
        .path(
          "/api/v1/clients/{clientId}/reports/code/{code}/statusNotification"
        )
        .method("put")
        .create()
    ),
    getReportById: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}")
        .method("get")
        .create()
    ),
    getReportHistory: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/history")
        .method("get")
        .create()
    ),
    updateReportStatus: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/status")
        .method("put")
        .create()
    ),
    updateReportCategory: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/category")
        .method("put")
        .create()
    ),
    updateReportPriority: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/priority")
        .method("put")
        .create()
    ),
    updateReportPublicComment: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/publicComment")
        .method("put")
        .create()
    ),
    updateReportPublicAssessment: safe404call(
      f
        .path("/api/v1/clients/{clientId}/reports/{reportId}/publicAssessment")
        .method("put")
        .create()
    ),
    updateReportInternalAssessment: safe404call(
      f
        .path(
          "/api/v1/clients/{clientId}/reports/{reportId}/internalAssessment"
        )
        .method("put")
        .create()
    ),
    findAllReportsByClientId: safe404call(
      f.path("/api/v1/clients/{clientId}/reports").method("get").create()
    ),
    findAllClients: safe404call(
      f.path("/api/v1/clients").method("get").create()
    ),
    findClientById: safe404call(
      f.path("/api/v1/clients/{id}").method("get").create()
    ),
    findAllUsersByClientId: safe404call(
      f.path("/api/v1/clients/{clientId}/users").method("get").create()
    ),
    createClient: safe404call(
      f.path("/api/v1/clients").method("post").create()
    ),
    updateClient: safe404call(
      f.path("/api/v1/clients/{clientId}").method("put").create()
    ),
    getClientInfoById: safe404call(
      f.path("/api/v1/clients/{id}").method("get").create()
    ),
    getClientInfoPublicById: safe404call(
      f.path("/api/v1/clients/{id}/public").method("get").create()
    ),
    updateClientInfo: safe404call(
      f.path("/api/v1/clients/{clientId}").method("put").create()
    ),
    createUser: safe404call(
      f.path("/api/v1/clients/{clientId}/users").method("post").create()
    ),
    findUserById: safe404call(
      f.path("/api/v1/clients/{clientId}/users/{userId}").method("get").create()
    ),
    updateUser: safe404call(
      f.path("/api/v1/clients/{clientId}/users/{userId}").method("put").create()
    ),
    deleteUser: safe404call(
      f
        .path("/api/v1/clients/{clientId}/users/{userId}")
        .method("delete")
        .create()
    ),
    restoreUser: safe404call(
      f
        .path("/api/v1/clients/{clientId}/users/{userId}/enable")
        .method("put")
        .create()
    ),
    loginUser: safe404call(f.path("/api/v1/sessions").method("post").create()),
    setUserPassword: safe404call(
      f.path("/api/v1/sessions/first-password").method("post").create()
    ),
    getLogged: safe404call(f.path("/api/v1/sessions").method("get").create()),
    changePasswordRequest: safe404call(
      f.path("/api/v1/sessions/change-password/request").method("post").create()
    ),
    changePassword: safe404call(
      f.path("/api/v1/sessions/change-password").method("post").create()
    ),
    userChangePassword: safe404call(
      f
        .path("/api/v1/clients/{clientId}/users/{id}/password")
        .method("put")
        .create()
    ),
    echo: safe404call(f.path("/api/v1/echo/hello").method("post").create()),
    googleLoginViaToken: safe404call(
      f.path("/api/v1/social/google").method("post").create()
    ),
  };
};
