import {
  ArrowForward,
  CheckCircleOutline,
  ContentPaste,
  ExpandLess,
  ExpandMore,
  Send,
} from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CheckboxElement,
  FormContainer,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "i18next";

import {
  CreateReportDTO,
  ReportCategory,
  ReportChannel,
} from "../../api/types";
import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import AuthContext from "../contexts/AuthContext";
import { AsyncButton } from "../form/AsyncButton";
import { useEmailValidation } from "../form/validations/useEmailValidation";
import { useIsBirthdayValidation } from "../form/validations/useIsBirthdayValidation";
import { useIsDateInPast } from "../form/validations/useIsDateInPast";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import Navigation from "../Navigation";
import InfoItem from "../ui/InfoItem";
import SmallFooter from "../ui/SmallFooter";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import CustomDialog from "../ui/CustomDialog";
import AlertContext from "../contexts/AlertContext";
import { WhistleDateTimePickerElement } from "../ui/WhistleDateTimePickerElement";
import { WhistleDatePickerElement } from "../ui/WhistleDatePickerElement";

export type ReportData = {
  incidentDescription: string;
  dateRecieved: Dayjs;
  category: ReportCategory;
  channel: ReportChannel;
  reporterName: string;
  reporterBirthDate: Dayjs;
  reporterEmail: string;
  reporterPhone: string;
  reporterAddress: string;
  involvedNames: string;
  withnessesNames: string;
  incidentDateTime: Dayjs;
  incidentLocation: string;
  incidentValue: string;
  evidence: string;
  reportCopy: boolean;
  reportStatusChange: boolean;
  notificationEmail: string;
};

const NewReport: React.FC = () => {
  const extraButtonHeight = 40;
  const marginTop = 14;
  const buttonWidth = "75%";
  const messageTime = 1500;
  const maxYear = 2000;
  const ctx = useContext(AuthContext);
  const [step, setStep] = useState<number>(
    ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN" ? 1 : 0
  );
  const [reporterEmail, setReporterEmail] = useState<string>("");
  const [showDetail, setShowDetail] = useState<boolean>(true);
  const [showNotifications, setShowNotifications] = useState<boolean>(true);
  const [reportingDateTime, setReportingDateTime] = useState<Dayjs | null>(
    null
  );
  const [showDialogForEmail, setShowDialogForEmail] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string | null>(null);
  const api = useApiClient();
  const [createReportCall, createReportState] = useSafeAsync(api.createReport);
  const [createReportByAdminCall, createReportByAdminState] = useSafeAsync(
    api.createReportByAdmin
  );
  const [sendAccessCodeByEmailCall, sendAccessCodeByEmailState] = useSafeAsync(
    api.sendCodeToEmailByAccessCode
  );

  const requiredField = useRequiredValidation();
  const emailValidation = useEmailValidation();
  const isDateInPast = useIsDateInPast(maxYear);
  const phoneValidation = usePhoneNumberValidation();
  const birthdayValidation = useIsBirthdayValidation(18);
  const minReportLength = useMinLengthValidation(30);
  const data = useParams();
  const companyId = data.companyId;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm();
  const { showSuccess } = useContext(AlertContext);

  const isCheckedConfirmation = form.watch("confirmationOfReceipt");
  const isCheckedReportAssesment = form.watch("reportAssesment");
  const isCheckedRemedyMeasuresAdopted = form.watch("remedyMeasuresAdopted");

  const isReporterEmailFilled = form.watch("reporterEmail");

  const emailRequired =
    isCheckedConfirmation ||
    isCheckedReportAssesment ||
    isCheckedRemedyMeasuresAdopted;

  useEffect(() => {
    if (isReporterEmailFilled) {
      form.setValue("confirmationOfReceipt", true);
      form.setValue("reportAssesment", true);
      form.setValue("remedyMeasuresAdopted", true);
      form.setValue("notificationEmail", reporterEmail);
    }
  }, [isReporterEmailFilled, form, reporterEmail]);

  useEffect(() => {
    if (!emailRequired) {
      form.resetField("notificationEmail");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailRequired, form.resetField]);

  const lang = useMemo(() => (i18next.language == "en" ? "en" : "cs-cz"), []);

  const nextStepHandler = () => {
    setStep(step + 1);
  };

  const showDetailHandler = () => {
    setShowDetail(!showDetail);
  };

  const showNotificationsHandler = () => {
    setShowNotifications(!showNotifications);
  };

  const sendIncidentHandler = async (data: any) => {
    // vsechno hezky posleme
    let newReport: CreateReportDTO = null;
    newReport = {
      incidentDescription: data.incidentDescription,
    };
    if (
      ctx.isLoggedIn &&
      ctx.loggedUser.userDTO.role === "ADMIN" &&
      ctx.loggedUser.userDTO.clientId === companyId
    ) {
      newReport = {
        ...newReport,
        receivedDate: data.receivedDate
          ? dayjs(data.receivedDate).toISOString()
          : null,
        category: data.category,
        channel: data.channel,
      };
    }
    if (showDetail) {
      newReport = {
        ...newReport,
        reporterName: data.reporterName,
        reporterBirthDate: dayjs(data.birthDay).toISOString(),
        reporterBirthPlainDate: data.birthDay
          ? {
              year: dayjs(data.birthDay).get("year"),
              month: dayjs(data.birthDay).get("month") + 1,
              day: dayjs(data.birthDay).get("date"),
            }
          : null,
        reporterEmail: data.reporterEmail,
        reporterPhone: data.reporterPhone,
        reporterContactAddress: data.reporterAddress,
        personsInvolved: data.involvedNames,
        witnesses: data.withnessesNames,
        incidentDate: data.incidentDateTime
          ? dayjs(data.incidentDateTime).toISOString()
          : null,
        incidentLocation: data.incidentLocation,
        incidentValue: data.incidentValue,
        evidence: data.evidence,
      };
    }
    if (showNotifications) {
      newReport = {
        ...newReport,
        notificationSetup: {
          confirmationEnabled: data.confirmationOfReceipt,
          statusEnabled: data.reportAssesment,
          remedyMeasurement: data.remedyMeasuresAdopted,
          email: data.notificationEmail,
        },
      };
    }
    if (ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "ADMIN") {
      const result = await createReportByAdminCall({
        clientId: companyId,
        ...newReport,
      });
      console.log(
        "report " +
          JSON.stringify(newReport) +
          "date received " +
          dayjs(newReport.receivedDate).format("DD.MM.YYYY HH:mm")
      );
      if (typeof result === "object" && result) {
        navigate("/app/" + companyId + "/admin/reports/" + result.value + "/0");
      }
    } else {
      const result = await createReportCall({
        clientId: companyId,
        ...newReport,
      });
      if (typeof result === "object" && result) {
        setAccessCode(result.value);
        setReportingDateTime(dayjs());
        nextStepHandler();
      }
    }
  };

  const checkStatusHandler = () => {
    navigate("/app/" + companyId + "/status/");
  };

  const sendCodeToEmailDialogHandler = () => {
    setShowDialogForEmail(true);
  };

  const closedDialogForEmailHandler = () => {
    setShowDialogForEmail(false);
  };

  const sendCodeToEmailHandler = async (email: string) => {
    // posleme email na ktery se posle kod
    const result = await sendAccessCodeByEmailCall({
      clientId: companyId,
      code: accessCode,
      email: email,
    });
    if (typeof result === "boolean" && result) {
      showSuccess(t("whistleblow.message.codeSend"), "", messageTime);
      setShowDialogForEmail(false);
    }
  };

  const setReporterEmailHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReporterEmail(event.currentTarget.value);
  };

  //"AML" | "DATA_PROTECTION" | "SAFETY" | "FINANCIAL_MISCONDUCT" | "WORKPLACE_MISCONDUCT" |
  // "CORRUPTION" | "REGULATORY_AND_LEGAL_VIOLATION" | "CYBERSECURITY" | "ENVIRONMENT_AND_SUSTAINABILITY" | "DISCRIMINATION_AND_HARASSMENT" |
  // "PROCUREMENT_AND_CONTRACTING" | "INTELLECTUAL_PROPERTY" | "CONFLICT_OF_INTEREST" | "HEALTH_AND_SAFETY" | "OTHER"
  const allCategories = [
    /* { id: "AML", label: t("whistleblow.category.aml") },
    { id: "DATA_PROTECTION", label: t("whistleblow.category.dataProtection") },
    { id: "SAFETY", label: t("whistleblow.category.safety") }, */
    {
      id: "FINANCIAL_MISCONDUCT",
      label: t("whistleblow.category.financialMisconduct"),
    },
    {
      id: "WORKPLACE_MISCONDUCT",
      label: t("whistleblow.category.workplaceMisconduct"),
    },
    { id: "CORRUPTION", label: t("whistleblow.category.corruption") },
    {
      id: "REGULATORY_AND_LEGAL_VIOLATION",
      label: t("whistleblow.category.reqularAndLegalViolation"),
    },
    { id: "CYBERSECURITY", label: t("whistleblow.category.cyberSecurity") },
    {
      id: "ENVIRONMENT_AND_SUSTAINABILITY",
      label: t("whistleblow.category.environment"),
    },
    {
      id: "DISCRIMINATION_AND_HARASSMENT",
      label: t("whistleblow.category.discrimination"),
    },
    {
      id: "PROCUREMENT_AND_CONTRACTING",
      label: t("whistleblow.category.procurement"),
    },
    {
      id: "INTELLECTUAL_PROPERTY",
      label: t("whistleblow.category.intelectualProperty"),
    },
    {
      id: "CONFLICT_OF_INTEREST",
      label: t("whistleblow.category.conflictOfInterest"),
    },
    {
      id: "HEALTH_AND_SAFETY",
      label: t("whistleblow.category.healthAndSafety"),
    },
    { id: "OTHER", label: t("whistleblow.category.other") },
  ];

  //"WEB" | "EMAIL" | "CALL" | "PERSONAL" | "POSTAL"
  const allChannels = [
    { id: "WEB", label: t("whistleblow.channel.web") },
    { id: "EMAIL", label: t("whistleblow.channel.email") },
    { id: "CALL", label: t("whistleblow.channel.call") },
    { id: "PERSONAL", label: t("whistleblow.channel.personal") },
    { id: "POSTAL", label: t("whistleblow.channel.written") },
  ];

  /* */

  return (
    <Stack direction="column" className="main">
      <Navigation />
      {step === 0 && (
        <Stack direction="column" className="px_main" sx={{}}>
          <Typography
            variant="apph1Bold"
            sx={{ mt: marginTop, alignSelf: "center" }}
          >
            {t("whistleblow.newReport.title", "New Report")}
          </Typography>
          <Stack
            direction="column"
            className="form_600"
            sx={{
              mt: 5,
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <InfoItem
              title={t(
                "whistleblow.newReport.privacyTitle",
                "Privacy protection"
              )}
              description={t("whistleblow.newReport.privacyDescription")}
              icon="shield"
            />
            <InfoItem
              title={t("whistleblow.newReport.securityTitle")}
              description={t("whistleblow.newReport.securityDescription")}
              icon="lock"
            />
            <InfoItem
              title={t("whistleblow.newReport.independentTitle")}
              description={t("whistleblow.newReport.independentDescription")}
              icon="balance"
            />
            <Button
              variant="contained"
              onClick={nextStepHandler}
              sx={{ mt: 5, mb: 10, width: buttonWidth }}
            >
              <Stack direction="row" sx={{ alignItems: "center" }}>
                <Typography variant="textBase">
                  {t("whistleblow.common.continue")}
                </Typography>
                <ArrowForward sx={{ ml: 1 }} />
              </Stack>
            </Button>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <React.Fragment>
          <Stack direction="column" className="px_main" sx={{}}>
            <Typography
              variant="apph1Bold"
              sx={{ mt: marginTop, alignSelf: "center" }}
            >
              {t("whistleblow.newReport.title")}
            </Typography>
            <Stack
              direction="column"
              className="form_600"
              sx={{ mt: 4.5, alignSelf: "center", width: "100%" }}
            >
              <Typography variant="textBase" sx={{ mb: 2 }}>
                {t("whistleblow.newReport.form.description")}
              </Typography>
              <FormContainer formContext={form} onSuccess={sendIncidentHandler}>
                <Stack direction="column">
                  <TextFieldElement
                    name="incidentDescription"
                    multiline
                    rows={5}
                    validation={{ ...requiredField, ...minReportLength }}
                    placeholder={t("whistleblow.newReport.form.describe")}
                  />
                  {ctx.isLoggedIn &&
                    ctx.loggedUser.userDTO.role === "ADMIN" &&
                    ctx.loggedUser.userDTO.clientId === companyId && (
                      <Stack spacing={3} sx={{ mt: 3, mb: 3 }}>
                        <WhistleDateTimePickerElement
                          name="receivedDate"
                          dateLabel={t(
                            "whistleblow.newReport.form.detail.dateRecieved"
                          )}
                          timeLabel={t(
                            "whistleblow.newReport.form.detail.timeReceived"
                          )}
                          validation={{ ...isDateInPast, ...requiredField }}
                          helperTextDate={t(
                            "whistleblow.message.receivedDateFormat"
                          )}
                        />
                        {/* <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={lang}
                        >
                          <Controller
                            name="dateReceived"
                            rules={{ ...isDateInPast, ...required }}
                            render={({
                              field: { onChange },
                              fieldState: { error },
                            }) => (
                              <DateTimePicker
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    error={error}
                                    helperText={error ? error.message : ""}
                                  />
                                )}
                                value={receivedDayValue}
                                onChange={(newValue) => {
                                  onChange(setReceivedDayValue(newValue));
                                }}
                                label={t(
                                  "whistleblow.newReport.form.detail.dateRecieved"
                                )}
                                inputFormat="DD.MM.YYYY HH:mm"
                                ampm={false}
                              />
                            )}
                          />
                        </LocalizationProvider> */}
                        <SelectElement
                          id="category"
                          name="category"
                          label={t("whistleblow.category.category")}
                          validation={requiredField}
                          options={allCategories}
                        />
                        <SelectElement
                          id="channel"
                          name="channel"
                          label={t("whistleblow.channel.channel")}
                          validation={requiredField}
                          options={allChannels}
                        />
                      </Stack>
                    )}
                  {!showDetail && (
                    <Stack
                      direction="column"
                      sx={{ mt: 2, border: "1px solid rgba(0,0,0,0.3)" }}
                    >
                      <Button
                        variant="text"
                        onClick={showDetailHandler}
                        sx={{ color: "black", height: extraButtonHeight }}
                      >
                        <Typography variant="textBase" sx={{ pl: 1 }}>
                          {t("whistleblow.newReport.form.detail.add")}
                        </Typography>
                        <div className="space"></div>
                        <ExpandMore sx={{}} />
                      </Button>
                    </Stack>
                  )}
                  {showDetail && (
                    <Stack
                      direction="column"
                      sx={{ mt: 2, border: "1px solid rgba(0,0,0,0.3)" }}
                    >
                      <Button
                        variant="text"
                        onClick={showDetailHandler}
                        sx={{
                          color: "black",
                          pl: 2,
                          height: extraButtonHeight,
                        }}
                      >
                        <Typography variant="textBase">
                          {t("whistleblow.newReport.form.detail.detail")}
                        </Typography>
                        <div className="space"></div>
                        <ExpandLess sx={{}} />
                      </Button>
                      <Stack spacing={3} sx={{ mt: 3, mb: 3, pl: 2, pr: 2 }}>
                        <Typography variant="textBase">
                          {t("whistleblow.newReport.form.detail.whyRequired")}
                        </Typography>
                        <TextFieldElement
                          name="reporterName"
                          label={
                            ctx.isLoggedIn
                              ? t("whistleblow.report.detail.person")
                              : t("whistleblow.newReport.form.detail.name")
                          }
                          validation={requiredField}
                          helperText={t("whistleblow.error.required")}
                        />
                        <WhistleDatePickerElement
                          name="birthDay"
                          label={
                            ctx.isLoggedIn
                              ? t("whistleblow.report.detail.birthDay")
                              : t("whistleblow.newReport.form.detail.birthday")
                          }
                          validation={{
                            ...birthdayValidation,
                            ...requiredField,
                          }}
                          helperText={t("whistleblow.error.required")}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePickerElement
                            name="birthDay"
                            label={
                              ctx.isLoggedIn
                                ? t("whistleblow.report.detail.birthDay")
                                : t(
                                    "whistleblow.newReport.form.detail.birthday"
                                  )
                            }
                            inputFormat="DD.MM.YYYY"
                            maxDate={dayjs().add(-5, "year")}
                            minDate={dayjs().add(-90, "year")}
                            validation={birthdayValidation}
                          />
                        </LocalizationProvider> */}
                        <TextFieldElement
                          name="reporterEmail"
                          label={
                            ctx.isLoggedIn
                              ? t("whistleblow.report.detail.email")
                              : t(
                                  "whistleblow.newReport.form.detail.contactEmail"
                                )
                          }
                          onChange={setReporterEmailHandler}
                          validation={emailValidation}
                        />
                        <TextFieldElement
                          name="reporterPhone"
                          label={
                            ctx.isLoggedIn
                              ? t("whistleblow.report.detail.phone")
                              : t(
                                  "whistleblow.newReport.form.detail.contactPhone"
                                )
                          }
                          validation={phoneValidation}
                        />
                        <TextFieldElement
                          name="reporterAddress"
                          label={
                            ctx.isLoggedIn
                              ? t("whistleblow.report.detail.address")
                              : t(
                                  "whistleblow.newReport.form.detail.contactAddress"
                                )
                          }
                        />
                        <TextFieldElement
                          name="involvedNames"
                          label={t(
                            "whistleblow.newReport.form.detail.involvedNames"
                          )}
                        />
                        <TextFieldElement
                          name="withnessesNames"
                          label={t(
                            "whistleblow.newReport.form.detail.witnesses"
                          )}
                        />
                        <WhistleDateTimePickerElement
                          name="incidentDateTime"
                          dateLabel={t(
                            "whistleblow.newReport.form.detail.incidentDate"
                          )}
                          timeLabel={t(
                            "whistleblow.newReport.form.detail.incidentTime"
                          )}
                          validation={isDateInPast}
                        />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePickerElement
                            name="incidentDateTime"
                            label={t(
                              "whistleblow.newReport.form.detail.incidentDate"
                            )}
                            inputFormat="DD.MM.YYYY HH:mm"
                            ampm={false}
                            validation={isDateInPast}
                          />
                        </LocalizationProvider> */}
                        <TextFieldElement
                          name="incidentLocation"
                          label={t(
                            "whistleblow.newReport.form.detail.incidentLocation"
                          )}
                        />
                        <TextFieldElement
                          name="incidentValue"
                          label={t(
                            "whistleblow.newReport.form.detail.incidentValue"
                          )}
                        />
                        <TextFieldElement
                          name="evidence"
                          label={t(
                            "whistleblow.newReport.form.detail.evidence"
                          )}
                        />
                      </Stack>
                    </Stack>
                  )}
                  {!showNotifications && !ctx.isLoggedIn && (
                    <Stack
                      direction="column"
                      sx={{ mt: 2, border: "1px solid rgba(0,0,0,0.3)" }}
                    >
                      <Button
                        variant="text"
                        onClick={showNotificationsHandler}
                        sx={{ color: "black", height: extraButtonHeight }}
                      >
                        <Typography variant="textBase" sx={{ pl: 1 }}>
                          {t("whistleblow.newReport.form.notification.recieve")}
                        </Typography>
                        <div className="space"></div>
                        <ExpandMore sx={{}} />
                      </Button>
                    </Stack>
                  )}
                  {showNotifications && !ctx.isLoggedIn && (
                    <Stack
                      direction="column"
                      sx={{ mt: 2, border: "1px solid rgba(0,0,0,0.3)" }}
                    >
                      <Button
                        variant="text"
                        onClick={showNotificationsHandler}
                        sx={{
                          color: "black",
                          pl: 2,
                          height: extraButtonHeight,
                        }}
                      >
                        <Typography variant="textBase">
                          {t(
                            "whistleblow.newReport.form.notification.notification"
                          )}
                        </Typography>
                        <div className="space"></div>
                        <ExpandLess sx={{}} />
                      </Button>
                      <Stack spacing={3} sx={{ mt: 3, mb: 3, pl: 2, pr: 2 }}>
                        <CheckboxElement
                          name="confirmationOfReceipt"
                          label={t(
                            "whistleblow.newReport.form.notification.confirmationOfReceipt"
                          )}
                        />
                        <CheckboxElement
                          name="reportAssesment"
                          label={t(
                            "whistleblow.newReport.form.notification.reportAssesment"
                          )}
                        />
                        <CheckboxElement
                          name="remedyMeasuresAdopted"
                          label={t(
                            "whistleblow.newReport.form.notification.remedyMeasuresAdopted"
                          )}
                        />
                        <TextFieldElement
                          name="notificationEmail"
                          label={t("whistleblow.common.email")}
                          validation={
                            emailRequired
                              ? { ...requiredField, ...emailValidation }
                              : { required: undefined, ...emailValidation }
                          }
                          /* required={emailRequired} */
                        />
                        {/* <Typography variant="textSmallBold">
                          {t(
                            "whistleblow.newReport.form.notification.emailDescription"
                          )}
                        </Typography> */}
                      </Stack>
                    </Stack>
                  )}
                  <AsyncButton
                    variant="contained"
                    state={createReportState}
                    sx={{
                      mt: 5,
                      mb: 10,
                      width: buttonWidth,
                      alignSelf: "center",
                    }}
                  >
                    <Typography variant="textBase">
                      {t("whistleblow.common.send")}
                    </Typography>
                    <Send
                      sx={{
                        ml: 2,
                        height: "24px",
                        width: "24px",
                      }}
                    />
                  </AsyncButton>
                </Stack>
              </FormContainer>
            </Stack>
          </Stack>
        </React.Fragment>
      )}
      {step === 2 && (
        <Stack
          direction="column"
          className="px_main"
          sx={{
            pb: 10,
          }}
        >
          <Stack direction="row" sx={{ mt: marginTop, alignSelf: "center" }}>
            <Typography
              variant="apph1Bold"
              sx={{ alignSelf: "center", justifySelf: "center" }}
            >
              {t("whistleblow.newReport.final.title")}
            </Typography>
            <CheckCircleOutline
              sx={{
                ml: 2,
                width: "40px",
                height: "40px",
                justifySelf: "center",
              }}
            />
          </Stack>
          <Stack
            direction="column"
            className="form_600"
            sx={{
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="apph3Semibold"
              sx={{ textAlign: "center", mt: 5 }}
            >
              {t("whistleblow.newReport.final.subtitle1")}
              {t("whistleblow.newReport.final.subtitle2")}
            </Typography>
            <Typography variant="textBase" sx={{ mt: 5 }}>
              {t("whistleblow.newReport.final.reportOn")}
            </Typography>
            <Typography variant="textMedium">
              {reportingDateTime
                ? dayjs(reportingDateTime).format("DD.MM.YYYY HH:mm")
                : "Not available"}
            </Typography>
            <Typography variant="apph3Semibold" sx={{ mt: 5 }}>
              {t("whistleblow.newReport.final.accessCode")}
            </Typography>
            <Typography
              variant="apph24"
              sx={{
                mt: 2,
                pt: 1,
                pb: 1,
                pl: 2,
                pr: 2,
                border: "1px solid rgba(0,0,0,0.2)",
                boxShadow: "1px 1px rgba(0,0,0,0.1)",
              }}
            >
              {accessCode ? accessCode : "-"}
            </Typography>
            <Stack direction="row" sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={async () => {
                  if ("clipboard" in navigator) {
                    await navigator.clipboard.writeText(accessCode);
                    showSuccess(
                      t("whistleblow.message.copySucessfull"),
                      "",
                      1500
                    );
                  } else {
                    document.execCommand("copy", true, accessCode);
                  }
                }}
              >
                <ContentPaste />
                <Typography variant="textBase" sx={{ ml: 1 }}>
                  {t("whistleblow.common.copy")}
                </Typography>
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={sendCodeToEmailDialogHandler}
                sx={{ ml: 2 }}
              >
                <Send />
                <Typography variant="textBase" sx={{ ml: 1 }}>
                  {t("whistleblow.common.sendToEmail")}
                </Typography>
              </Button>
            </Stack>
            <Typography
              variant="textMedium"
              sx={{
                mt: 5,
                textAlign: "center",
              }}
            >
              {t("whistleblow.newReport.final.accessCodeWarning")}
            </Typography>
            <Button
              variant="contained"
              onClick={checkStatusHandler}
              sx={{ mt: 5, width: buttonWidth, alignItems: "center" }}
            >
              <Typography variant="textBase">
                {t("whistleblow.common.checkStatus")}
              </Typography>
            </Button>
          </Stack>
          {showDialogForEmail && (
            <CustomDialog
              openDialog={showDialogForEmail}
              closed={closedDialogForEmailHandler}
              title={t("whistleblow.dialog.enterEmail")}
              subtitle={t("whistleblow.dialog.enterEmailDescriptionCode")}
              commentLabel={t("whistleblow.dialog.emailLabel")}
              buttonText={t("whistleblow.dialog.send")}
              onButtonClick={sendCodeToEmailHandler}
              isEmail={true}
              rowNumber={1}
            />
          )}
        </Stack>
      )}
      <div className="space"></div>
      <SmallFooter />
    </Stack>
  );
};

export default NewReport;
