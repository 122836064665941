// https://stackoverflow.com/questions/43898155/react-i18next-not-loading-json-translation-files-in-react-app-created-with-creat

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "cs",
    supportedLngs: ["en", "cs"],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/translations/{{lng}}.json",
    },
  });

export default i18n;
