import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

class Room {
  id: number;
  title: string;
  description: string;
  freeCapacity: number;
  status: boolean;
  imgUrl: string;
  chips: string[];
  calendarUrl: string;
  constructor(
    id: number,
    title: string,
    description: string,
    freeCapacity: number,
    status: boolean,
    imgUrl: string,
    chips: string[],
    calendarUrl: string
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.freeCapacity = freeCapacity;
    this.status = status;
    this.imgUrl = imgUrl;
    this.chips = chips;
    this.calendarUrl = calendarUrl;
  }
}

let requestId = new Date().getTime();
class RequestItem {
  id: number;
  name: string;
  description: string;
  roomId: number;
  companyId: number;
  peopleCount: number;
  isDecided: boolean;
  isAccepted: boolean;
  refreshment: boolean;
  dateTimeFrom: string;
  dateTimeTo: string;
  userId: number;
  deniedComment: string;

  constructor(
    name: string,
    description: string,
    roomId: number,
    companyId: number,
    peopleCount: number,
    isDecided: boolean,
    isAccepted: boolean,
    refreshment: boolean,
    dateTimeFrom: string,
    dateTimeTo: string,
    userId: number,
    deniedComment: string
  ) {
    this.id = requestId++;
    this.name = name;
    this.description = description;
    this.roomId = roomId;
    this.companyId = companyId;
    this.peopleCount = peopleCount;
    this.isDecided = isDecided;
    this.isAccepted = isAccepted;
    this.refreshment = refreshment;
    this.dateTimeFrom = dateTimeFrom;
    this.dateTimeTo = dateTimeTo;
    this.userId = userId;
    this.deniedComment = deniedComment;
  }
}

let companyId = new Date().getTime();
class Company {
  id: number;
  name: string;
  description: string;

  constructor(name: string, description: string) {
    this.id = companyId++;
    this.name = name;
    this.description = description;
  }
}

let userID = new Date().getTime();

class User {
  id: number;
  isAdmin: boolean;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  companyId: number;
  phone: string;

  constructor(
    isAdmin: boolean,
    firstName: string,
    lastName: string,
    email: string,
    avatar: string,
    companyId: number,
    phone: string
  ) {
    this.id = userID++;
    this.isAdmin = isAdmin;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.avatar = avatar;
    this.companyId = companyId;
    this.phone = phone;
  }
}

let notificationId = new Date().getTime();

class NotificationItem {
  id: number;
  requestId: number;
  createDateAndTime: Dayjs;
  isRead: boolean;

  constructor(requestId: number, createDateAndTime: Dayjs, isRead: boolean) {
    this.id = notificationId++;
    this.requestId = requestId;
    this.createDateAndTime = createDateAndTime;
    this.isRead = isRead;
  }
}

const findAcceptedRequests = (requests: RequestItem[]) => {
  const acceptedRequests: RequestItem[] = [];
  for (const requestItem of requests) {
    if (requestItem.isAccepted) {
      acceptedRequests.push(requestItem);
    }
  }
  return acceptedRequests;
};

const findRoomByID = (rooms: Room[], id: number) => {
  for (const room of rooms) {
    if (room.id === id) {
      return room;
    }
  }
  return null;
};

const findCompanyByName = (companies: Company[], name: string) => {
  for (const company of companies) {
    if (company.name === name) {
      return company;
    }
  }
  return null;
};

const useRequiredValidation = () => {
  const { t } = useTranslation();

  return {
    required: t("whistleblow.error.required", "This field is required"),
  };
};

export {
  Room,
  User,
  RequestItem,
  Company,
  NotificationItem,
  findRoomByID,
  findAcceptedRequests,
  findCompanyByName,
  useRequiredValidation,
};
