import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import ReportTable from "./ReportTable";

const ReportPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Stack className="px_main" sx={{ mt: 14 }}>
      <Typography variant="h3" sx={{}}>
        {t("whistleblow.report.report")}
      </Typography>
      <ReportTable />
    </Stack>
  );
};

export default ReportPage;
