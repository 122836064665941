import i18next from "i18next";
import React, { useContext, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import {
  PaletteColor,
  PaletteColorOptions,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { AlertsBar } from "./components/AlertsBar";
import { Content } from "./components/Content";
import { AlertProvider } from "./components/contexts/AlertContext";
import AuthContext from "./components/contexts/AuthContext";
import { UiContextProvider } from "./components/contexts/UiContext";
import i18n from "./i18n";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    apph1Bold: true;
    apph2: true;
    apph2Bold: true;
    apph3Semibold: true;
    textBase: true;
    textSmall: true;
    textSmallBold: true;
    textMedium: true;
    textBold: true;
    navLogo: true;
    apph24: true;
    brownfox: true;
    graymouse: true;
    bluesea: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    bluesea: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    graymouse: PaletteColorOptions;
    brownfox: PaletteColorOptions;
    bluesea: PaletteColorOptions;
  }

  interface PaletteOptions {
    graymouse: PaletteColorOptions;
    brownfox: PaletteColorOptions;
    bluesea: PaletteColorOptions;
  }
}

function App() {
  const { t } = useTranslation();
  const notLoggedMessage = t(
    "whistleblower.common.notLogged",
    "Pro práci v této části se musíte přihlásit"
  );
  const notAllowedMessage = t(
    "whistleblower.common.notAllowed",
    "Váš profil neumožňuje práci v této části."
  );

  const ctx = useContext(AuthContext);
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: string) =>
    augmentColor({ color: { main: mainColor } });

  const whistleblowTheme = createTheme({
    palette: {
      primary: {
        main: "#F6C25C",
      },
      secondary: {
        main: "#293845",
      },
      info: {
        main: "#FFFFFF",
      },
      graymouse: palette.augmentColor({ color: { main: "#374151" } }),
      brownfox: createColor("#663D0C"),
      bluesea: createColor("#1964B4"),
    },
    typography: {
      fontFamily: ["Inter"].join(","),
      h3: { fontWeight: 700, fontSize: 36 },
      h4: { fontWeight: 700, fontSize: 20 },
      h6: { fontWeight: 600 },
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: "navLogo" },
            style: {
              fontSize: 20,
              fontWeight: 800,
              lineHeight: "24px",
            },
          },
          {
            props: { variant: "apph1Bold" },
            style: {
              fontSize: 36,
              fontWeight: 700,
              lineHeight: "40px",
              letterSpacing: "-0.03em",
            },
          },
          {
            props: { variant: "apph2" },
            style: {
              fontSize: 20,
              fontWeight: 400,
              lineHeight: "28px",
            },
          },
          {
            props: { variant: "apph2Bold" },
            style: {
              fontSize: 20,
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
          {
            props: { variant: "apph3Semibold" },
            style: {
              fontSize: 20,
              fontWeight: 600,
              lineHeight: "24.2px",
            },
          },
          {
            props: { variant: "textBase" },
            style: {
              fontSize: 16,
              fontWeight: 400,
              lineHeight: "24px",
            },
          },
          {
            props: { variant: "textSmall" },
            style: {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "20px",
            },
          },
          {
            props: { variant: "textSmallBold" },
            style: {
              fontSize: 14,
              fontWeight: 800,
              lineHeight: "20px",
            },
          },
          {
            props: { variant: "textMedium" },
            style: {
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "24px",
            },
          },
          {
            props: { variant: "textBold" },
            style: {
              fontSize: 16,
              fontWeight: 700,
              lineHeight: "24px",
            },
          },
          {
            props: { variant: "apph24" },
            style: {
              fontSize: 24,
              fontWeight: 400,
              lineHeight: "32px",
            },
          },
          {
            props: { variant: "brownfox" },
            style: {
              color: "#663D0C",
            },
          },
          {
            props: { variant: "graymouse" },
            style: {
              color: "#374151",
            },
          },
          {
            props: { variant: "bluesea" },
            style: {
              color: "#1964B4",
            },
          },
        ],
      },
    },
  });

  useEffect(() => {
    const localLanguage = localStorage.getItem("savedLanguage");
    if (localLanguage != null) {
      i18n.changeLanguage(localLanguage);
    }
  }, []);

  return (
    <div>
      <HelmetProvider>
        <Helmet></Helmet>
        <UiContextProvider>
          <ThemeProvider theme={whistleblowTheme}>
            <AlertProvider>
              <BrowserRouter key={i18next.language} basename="/">
                <>
                  <Content />
                </>
              </BrowserRouter>
              <AlertsBar />
            </AlertProvider>
          </ThemeProvider>
        </UiContextProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
