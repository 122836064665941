import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { defineStyles } from "../../styles/defineStyles";
import { useRequiredValidation } from "../models/settings";
import { useEmailValidation } from "../form/validations/useEmailValidation";

const CustomDialog: React.FC<{
  openDialog: boolean;
  closed: () => void;
  title: string;
  subtitle: string;
  commentLabel?: string;
  commentPlaceholder?: string;
  buttonText: string;
  onButtonClick: (
    comment?: string,
    isCheckedNotificationSend?: boolean
  ) => void;
  isEmail?: boolean;
  rowNumber?: number;
  commentText?: string;
  notificationEmail?: string;
}> = (props) => {
  const styles = defineStyles({
    main: {},
    comment: {
      mt: 2,
      width: "100%",
    },
    buttons: {
      mt: 2,
    },
  });
  const { t } = useTranslation();
  const requestData = useParams();
  const requestId = requestData.id;
  const required = useRequiredValidation();
  const emailValidation = useEmailValidation();
  const [open, setOpen] = useState<boolean>(props.openDialog);
  const handleClose = () => {
    setOpen(false);
    props.closed();
  };
  const submitHandler = (data: {
    deniedComment: string;
    isCheckedNotificationSend?: boolean;
  }) => {
    props.onButtonClick(
      data.deniedComment,
      data.isCheckedNotificationSend ? data.isCheckedNotificationSend : null
    );
  };
  let textValidation = { ...required };
  if (props.isEmail) {
    textValidation = { ...required, ...emailValidation };
  }

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} sx={styles.main}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Typography>{props.subtitle}</Typography>
          <FormContainer
            defaultValues={{
              deniedComment: props.commentText ? props.commentText : undefined,
              isCheckedNotificationSend: props.notificationEmail ? true : false,
            }}
            onSuccess={submitHandler}
          >
            {props.commentLabel && props.commentLabel.length > 0 && (
              <TextFieldElement
                name="deniedComment"
                label={props.commentLabel}
                id="deniedComment"
                placeholder={props.commentPlaceholder}
                validation={textValidation}
                multiline
                rows={props.rowNumber ? props.rowNumber : 2}
                sx={styles.comment}
              />
            )}
            {props.commentText && props.commentText.length > 0 && (
              <TextFieldElement
                name="deniedComment"
                id="deniedComment"
                multiline
                rows={props.rowNumber ? props.rowNumber : 2}
                sx={styles.comment}
                disabled
              />
            )}
            {props.notificationEmail && (
              <Stack sx={{ mt: 2 }}>
                <CheckboxElement
                  name="isCheckedNotificationSend"
                  label={
                    t("whistleblow.dialog.sendNotification") +
                    props.notificationEmail
                  }
                  sx={{}}
                />
              </Stack>
            )}
            <Stack direction="row" sx={styles.buttons}>
              <Button onClick={handleClose} variant="text" color="error">
                {t("whistleblow.common.discard")}
              </Button>
              <div className="space"></div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
              >
                {props.buttonText}
              </Button>
            </Stack>
          </FormContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CustomDialog;
