import { Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import UiContext from "../contexts/UiContext";
import { AsyncButton } from "../form/AsyncButton";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import Navigation from "../Navigation";
import NotAllowed from "../ui/NotAllowed";
import SmallFooter from "../ui/SmallFooter";

const NewClient: React.FC = () => {
  const styles = defineStyles({
    main: {
      mt: 9,
      ml: "calc((100vw - 788px)/2)",
      alignItem: "center",
      width: "500px",
    },
    formItem: {
      width: "100%",
      mt: 3,
    },
  });

  const required = useRequiredValidation();
  const ctx = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = "/app/admin/";
  const { refresh } = useContext(UiContext);

  const { createClient } = useApiClient();

  const [createClientCall, createClientState] = useSafeAsync(createClient);

  const submitHandler = async (data: {
    clientName: string;
    clientId: string;
    clientIco: string;
  }) => {
    const result = await createClientCall({
      clientId: data.clientId,
      name: data.clientName,
      ico: data.clientIco,
    });
    if (typeof result === "object" && result) {
      refresh();
      navigate(path);
    }
  };

  return (
    <Stack className="main">
      <Navigation />
      {(!ctx.isLoggedIn ||
        (ctx.isLoggedIn && ctx.loggedUser.userDTO.role !== "SUPER_ADMIN")) && (
        <NotAllowed />
      )}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "SUPER_ADMIN" && (
        <Stack
          className="form_600 px_main"
          sx={{ mt: 14, mb: 8, alignSelf: "center" }}
        >
          <Typography variant="apph1Bold">
            {t("whistleblow.client.newClient")}
          </Typography>
          <FormContainer defaultValues={{}} onSuccess={submitHandler}>
            <TextFieldElement
              name="clientId"
              id="clientId"
              validation={required}
              label={t("whistleblow.client.id", "Url id of a client")}
              sx={styles.formItem}
            />
            <TextFieldElement
              name="clientName"
              id="clientName"
              validation={required}
              label={t("whistleblow.client.name", "Client name")}
              sx={styles.formItem}
            />
            <TextFieldElement
              name="clientIco"
              id="clientIco"
              validation={required}
              label={t("whistleblow.client.ico", "Company ID")}
              sx={styles.formItem}
            />
            <Stack direction="row" sx={styles.formItem}>
              <div className="space"></div>
              <AsyncButton variant="contained" state={createClientState}>
                {t("whistleblow.client.add", "Add client")}
              </AsyncButton>
            </Stack>
          </FormContainer>
        </Stack>
      )}
      <div className="space" />
      <SmallFooter />
    </Stack>
  );
};

export default NewClient;
