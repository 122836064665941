import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { Controller, ControllerProps } from "react-hook-form";

type DateElementProps = {
  name: string;
  label: string;
  helperText?: string;
  validation?: ControllerProps["rules"];
};

export const WhistleDatePickerElement = (props: DateElementProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={props.name}
        rules={props.validation}
        render={({
          field: { value, onChange },
          fieldState: { invalid, error },
        }) => (
          <DatePicker
            value={value ? value : null}
            onChange={(v) => onChange(v)}
            label={props.label}
            inputFormat="D.M.YYYY"
            renderInput={(params: any) => (
              <TextField
                {...params}
                error={invalid}
                helperText={error ? error.message : props.helperText}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};
