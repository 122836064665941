import { useTranslation } from "react-i18next";

function isValidPhone(value: string) {
  if (value == null) return false;
  if (value.startsWith("+420")) {
    return /^\+420\d{9}$/i.test(value);
  }
  if (/^\+\d{10,16}$/i.test(value)) return true;
  return /^\d{9}$/i.test(value);
}

export const normalizePhoneNumber = (value?: string) => {
  if (!value) return value;
  const stripped = value.trim().replace(/[-/. ]/g, "");
  if (stripped.length === 9) return `+420${stripped}`;
  return stripped;
};

export const usePhoneNumberValidation = () => {
  const { t } = useTranslation();

  return {
    validate: (value?: string) => {
      if (!value) return null;
      return isValidPhone(normalizePhoneNumber(value))
        ? undefined
        : t(
            "common.error.phoneNumber" /* 
            'Použijte některý z běžných formátů: +111 222 333 444, 111 222 333, 1112223333 apod. Případný mezinárodní volací kód musí začínat znakem "+".' */,
            'Use one of these phone number variation: +111 222 333 444, 111 222 333, 111222333 etc. For another country use code started with "+".'
          );
    },
  };
};
