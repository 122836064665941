import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  CheckboxElement,
  FormContainer,
  PasswordElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { defineStyles } from "../../styles/defineStyles";
import { AsyncButton } from "../form/AsyncButton";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import { useRequiredValidation } from "../models/settings";

const LoginSetPassword: React.FC<{
  userId: number;
  token: string;
}> = (props) => {
  const styles = defineStyles({
    all: {
      width: "100vw",
      height: "100vh",
      position: "relative",
    },
    card: {
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "350px",
      minHeight: "400px",
      transform: "translate(-50%,-50%)",
      p: 2,
    },
    description: {
      mt: 0.5,
    },
    descriptionBold: {
      fontWeight: "bold",
    },
    agreement: {
      mt: 2,
      mb: 2,
    },
    formGroup: {},
    textField: {
      mb: 2,
    },
    button: {
      width: "100%",
      mb: 2,
    },
    link: {
      pl: 1,
    },
  });

  const [passwordsAreSame, setPasswordsAreSame] = useState<boolean>(true);

  const { t } = useTranslation();

  const required = useRequiredValidation();
  const minPasswordLength = useMinLengthValidation(8);
  const api = useApiClient();
  const [setUserPasswordCall, setUserPasswordState] = useSafeAsync(
    api.setUserPassword
  );

  const data = useParams();
  const clientId = data.companyId;

  const accountActivationHandler = async (data: {
    newPassword: string;
    newPasswordAgain: string;
    agreement: boolean;
  }) => {
    if (data.newPassword !== data.newPasswordAgain) {
      return setPasswordsAreSame(false);
    }
    const result = await setUserPasswordCall({
      userId: props.userId,
      token: props.token,
      password: data.newPassword,
    });
    if (typeof result === "boolean" && result) {
      window.location.href = "/app/" + clientId + "/admin";
      return setPasswordsAreSame(true);
    }
    return setPasswordsAreSame(true);
  };

  return (
    <Box sx={{ mt: 9 }}>
      <Card sx={styles.card} elevation={3}>
        <CardHeader
          title={t("whistleblow.login.newAccount")}
          subheader={
            <Stack>
              <Typography sx={styles.description} variant="body2">
                {t("whistleblow.login.activation")}
              </Typography>
              <Typography sx={styles.description} variant="body2">
                {t("whistleblow.login.activationDescription")}
              </Typography>
            </Stack>
          }
        />
        <CardContent>
          <FormContainer
            defaultValues={{}}
            onSuccess={accountActivationHandler}
          >
            <PasswordElement
              name="newPassword"
              validation={{ ...required, ...minPasswordLength }}
              label={t("whistleblow.login.newPassword")}
              sx={styles.textField}
            />
            <PasswordElement
              name="newPasswordAgain"
              validation={{ ...required, ...minPasswordLength }}
              label={t("whistleblow.login.newPasswordAgain")}
              sx={styles.textField}
            />
            {!passwordsAreSame && (
              <Typography variant="body1" color="error">
                {t("whistleblow.login.notSamePasswords")}
              </Typography>
            )}
            <CheckboxElement
              name="agreement"
              validation={required}
              label={
                <React.Fragment>
                  <Typography component="span">
                    {t("whistleblow.login.agreementPart1")}{" "}
                  </Typography>
                  <Link href="#" target="_blank">
                    {t("whistleblow.login.agreementPart2")}
                  </Link>
                </React.Fragment>
              }
              sx={styles.agreement}
            />
            <AsyncButton
              variant="contained"
              sx={styles.button}
              state={setUserPasswordState}
            >
              {t("whistleblow.login.activateAccount")}
            </AsyncButton>
          </FormContainer>
        </CardContent>
        {/* <CardActions>
          <Typography component="span" color="primary">
            {t("whistleblow.login.doYouHaveAccount")}
          </Typography>
          <Link href="/" underline="hover" sx={styles.link}>
            {t("whistleblow.login.login")}
          </Link>
        </CardActions> */}
      </Card>
    </Box>
  );
};

export default LoginSetPassword;
