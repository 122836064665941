import { Button, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import UserTable from "./UserTable";

const UserPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const data = useParams();
  const clientId = data.companyId;

  const addNewUserHandler = () => {
    navigate("/app/admin/" + clientId + "/newUser");
  };

  return (
    <Stack direction="column" sx={{ mb: 8 }}>
      <Stack direction="row">
        <div className="space"></div>
        <Button
          variant="contained"
          onClick={addNewUserHandler}
          sx={{ alignSelf: "right" }}
        >
          {t("whistleblow.user.add", "Add")}
        </Button>
      </Stack>
      <UserTable />
    </Stack>
  );
};

export default UserPage;
