import { ArrowForward } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { AsyncButton } from "../form/AsyncButton";
import { LoadingGuard } from "../form/LoadingGuard";
import { useEmailValidation } from "../form/validations/useEmailValidation";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import Navigation from "../Navigation";
import { renderStateChip } from "../report/ReportTable";
import SmallFooter from "../ui/SmallFooter";
import AlertContext from "../contexts/AlertContext";

const CheckStatus: React.FC = () => {
  const messageTime = 1500;
  const buttonWidth = "100%";
  const marginTop = 14;
  const [step, setStep] = useState<number>(0);
  const { t } = useTranslation();
  const required = useRequiredValidation();
  const { showSuccess } = useContext(AlertContext);
  const api = useApiClient();
  const data = useParams();
  const clientId = data.companyId;
  const [reportByAccessCodeCall, reportByAccessCodeState] = useSafeAsync(
    api.getReportByAccessCode
  );
  const [
    sendEmailForStatusChangeNotificationCall,
    sendEmailForStatusChangeNotificationState,
  ] = useSafeAsync(api.sendEmailForStatusChangeAnonymous);

  const emailValidation = useEmailValidation();
  const navigate = useNavigate();

  const sendAccessCodeHandler = async (data: { accessCode: string }) => {
    /// tady si nechame poslal report pro uzivatele
    const result = await reportByAccessCodeCall({
      clientId: clientId,
      code: data.accessCode,
    });
    if (typeof result === "object" && result) {
      setStep(step + 1);
    }
  };

  const signUpForStatusChangeNotificationsHandler = async (data: {
    statusChangeNotificationEmail: string;
  }) => {
    //tady se prihlasi k odberu notifikaci pri zmene stavu
    const result = await sendEmailForStatusChangeNotificationCall({
      clientId: clientId,
      code: reportByAccessCodeState.value.code,
      email: data.statusChangeNotificationEmail,
    });
    if (typeof result === "boolean" && result) {
      showSuccess(
        t("whistleblow.message.signUpForNotifications"),
        "",
        messageTime
      );
    }
  };

  return (
    <Stack direction="column" className="main">
      <Navigation />
      {step === 0 && (
        <Stack direction="column" className="px_main">
          <Typography
            variant="apph1Bold"
            sx={{ mt: marginTop, alignSelf: "center", textAlign: "center" }}
          >
            {t("whistleblow.status.getStatus")}
          </Typography>
          <Stack
            direction="column"
            className="form_400"
            sx={{ mt: 5, alignSelf: "center" }}
          >
            <FormContainer defaultValues={{}} onSuccess={sendAccessCodeHandler}>
              <Stack direction="column" sx={{ alignItems: "center" }}>
                <TextFieldElement
                  name="accessCode"
                  placeholder={t("whistleblow.status.enterCode")}
                  validation={required}
                  sx={{ width: "100%" }}
                  autoComplete="off"
                />
                <Typography variant="textSmall" sx={{ mt: 1 }}>
                  {t("whistleblow.status.enterCodeTip")}
                </Typography>
                <AsyncButton
                  state={reportByAccessCodeState}
                  variant="contained"
                  sx={{ mt: 5, width: buttonWidth }}
                >
                  <Stack direction="row">
                    <Typography variant="textBase">
                      {t("whistleblow.common.continue")}
                    </Typography>
                    <ArrowForward sx={{ ml: 1 }} />
                  </Stack>
                </AsyncButton>
              </Stack>
            </FormContainer>
          </Stack>
        </Stack>
      )}
      {step === 1 && (
        <Stack direction="column" className="px_main" sx={{ mb: 8 }}>
          {reportByAccessCodeState.value && (
            <LoadingGuard state={reportByAccessCodeState}>
              <Stack
                direction="row"
                sx={{ mt: marginTop, alignSelf: "center", textAlign: "center" }}
              >
                <Typography variant="apph1Bold" sx={{ mr: 1 }}>
                  {t("whistleblow.status.report")} {"#"}
                  {reportByAccessCodeState.value.code}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                className="form_500"
                sx={{ mt: 5, alignSelf: "center", width: "100%" }}
              >
                <Stack direction="row" sx={{ alignSelf: "center" }}>
                  <Typography variant="textBase" sx={{ mr: 1 }}>
                    {t("whistleblow.status.recievedOn")}
                  </Typography>
                  <Typography variant="textMedium">
                    {dayjs(reportByAccessCodeState.value.createDate).format(
                      "DD. MM. YYYY HH:mm"
                    )}
                  </Typography>
                </Stack>
                <TextField
                  id="reportDescription"
                  value={reportByAccessCodeState.value.incidentDescription}
                  multiline
                  rows={5}
                  disabled
                  inputProps={{ readOnly: true }}
                  sx={{ mt: 5 }}
                />
                <Stack
                  direction="row"
                  sx={{ mt: 5, alignSelf: "center", alignItems: "center" }}
                >
                  <Typography variant="textBase" sx={{ mr: 1 }}>
                    {t("whistleblow.common.status")}
                  </Typography>
                  {renderStateChip(reportByAccessCodeState.value.status, true)}
                  {/* <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    {reportByAccessCodeState.value.status}
                  </Typography> */}
                </Stack>
                {reportByAccessCodeState.value.publicComment && (
                  <TextField
                    id="publicComment"
                    label={t("whistleblow.assessment.publicComment")}
                    value={reportByAccessCodeState.value.publicComment}
                    multiline
                    rows={5}
                    inputProps={{ readOnly: true }}
                    sx={{ mt: 5, alignSelf: "center", width: "100%" }}
                  />
                )}
                {reportByAccessCodeState.value.publicAssessment && (
                  <TextField
                    id="publicAssessment"
                    label={t("whistleblow.assessment.publicAssessment")}
                    value={reportByAccessCodeState.value.publicAssessment}
                    multiline
                    rows={5}
                    inputProps={{ readOnly: true }}
                    sx={{ mt: 5, alignSelf: "center", width: "100%" }}
                  />
                )}
                {/* {reportByAccessCodeState.value.status !== "CLOSED" && (
                  <Stack
                    direction="column"
                    sx={{
                      mt: 5,
                      alignSelf: "center",
                      border: "1px solid rgba(0,0,0,0.3)",
                      width: "100%",
                    }}
                  >
                    <Typography variant="textMedium" sx={{ mt: 3, mx: 3 }}>
                      {t("whistleblow.status.getStatusChangeNotification")}
                    </Typography>
                    <FormContainer
                      defaultValues={{}}
                      onSuccess={signUpForStatusChangeNotificationsHandler}
                    >
                      <TextFieldElement
                        name="statusChangeNotificationEmail"
                        label={t("whistleblow.common.email")}
                        validation={emailValidation}
                        sx={{ mt: 3, mb: 1, mx: 3, width: "90%" }}
                      />
                       <Typography variant="textSmallBold">
                        {t(
                          "whistleblow.status.statusChangeNotificationWarning"
                        )}
                      </Typography>
                      <AsyncButton
                        sx={{ my: 3, mx: 3, width: "90%", alignSelf: "center" }}
                      >
                        <Typography variant="textBase">
                          {t("whistleblow.common.save")}
                        </Typography>
                      </AsyncButton>
                    </FormContainer>
                  </Stack>
                )}*/}
              </Stack>
            </LoadingGuard>
          )}
        </Stack>
      )}
      <div className="space"></div>
      <SmallFooter />
    </Stack>
  );
};

export default CheckStatus;
