import {
  Divider,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";

import { useApiClient } from "../../api/useApiClient";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import AuthContext from "../contexts/AuthContext";
import { LoadingGuard } from "../form/LoadingGuard";
import Navigation from "../Navigation";
import SmallFooter from "../ui/SmallFooter";
import Unavailable from "../ui/Unavailable";
import { AsyncButton } from "../form/AsyncButton";
import UserPage from "../User/UserPage";
import AlertContext from "../contexts/AlertContext";
import UiContext from "../contexts/UiContext";
import { useRequiredValidation } from "../models/settings";
import { usePhoneNumberValidation } from "../form/validations/usePhoneNumberValidation";

export const ClientSettings: React.FC = () => {
  const columnWidth = 350;
  const messageTime = 1500;
  const ctx = useContext(AuthContext);
  const { t } = useTranslation();
  const api = useApiClient();
  const data = useParams();
  const clientId = data.companyId;
  const selectedMenu = parseInt(data.menuId);
  const menuPath = "/app/" + clientId + "/settings/";
  const required = useRequiredValidation();
  const phoneValidation = usePhoneNumberValidation();
  const { showSuccess } = useContext(AlertContext);
  const { refresh } = useContext(UiContext);
  const navigate = useNavigate();

  const [getClientCall, getClientState] = useSafeAsync(api.getClientInfoById);
  const [updateClientInfoCall, updateClientInfoState] = useSafeAsync(
    api.updateClientInfo
  );
  const [getAllUsersForClientCall, getAllUsersForClientState] = useSafeAsync(
    api.findAllUsersByClientId
  );
  const [updateUserCall, updateUserState] = useSafeAsync(api.updateUser);

  const allLanguages = [
    { id: "en", label: t("whistleblow.language.english") },
    { id: "cs", label: t("whistleblow.language.czech") },
  ];

  //"AML" | "DATA_PROTECTION" | "SAFETY" | "FINANCIAL_MISCONDUCT" | "WORKPLACE_MISCONDUCT" |
  // "CORRUPTION" | "REGULATORY_AND_LEGAL_VIOLATION" | "CYBERSECURITY" | "ENVIRONMENT_AND_SUSTAINABILITY" | "DISCRIMINATION_AND_HARASSMENT" |
  // "PROCUREMENT_AND_CONTRACTING" | "INTELLECTUAL_PROPERTY" | "CONFLICT_OF_INTEREST" | "HEALTH_AND_SAFETY" | "OTHER"
  const allCategories = [
    { id: "AML", label: t("whistleblow.category.aml") },
    { id: "DATA_PROTECTION", label: t("whistleblow.category.dataProtection") },
    { id: "SAFETY", label: t("whistleblow.category.safety") },
    {
      id: "FINANCIAL_MISCONDUCT",
      label: t("whistleblow.category.financialMisconduct"),
    },
    {
      id: "WORKPLACE_MISCONDUCT",
      label: t("whistleblow.category.workplaceMisconduct"),
    },
    { id: "CORRUPTION", label: t("whistleblow.category.corruption") },
    {
      id: "REGULATORY_AND_LEGAL_VIOLATION",
      label: t("whistleblow.category.reqularAndLegalViolation"),
    },
    { id: "CYBERSECURITY", label: t("whistleblow.category.cyberSecurity") },
    {
      id: "ENVIRONMENT_AND_SUSTAINABILITY",
      label: t("whistleblow.category.environment"),
    },
    {
      id: "DISCRIMINATION_AND_HARASSMENT",
      label: t("whistleblow.category.discrimination"),
    },
    {
      id: "PROCUREMENT_AND_CONTRACTING",
      label: t("whistleblow.category.procurement"),
    },
    {
      id: "INTELLECTUAL_PROPERTY",
      label: t("whistleblow.category.intelectualProperty"),
    },
    {
      id: "CONFLICT_OF_INTEREST",
      label: t("whistleblow.category.conflictOfInterest"),
    },
    {
      id: "HEALTH_AND_SAFETY",
      label: t("whistleblow.category.healthAndSafety"),
    },
    { id: "OTHER", label: t("whistleblow.category.other") },
    { id: "NOT_SET", label: t("whistleblow.category.notSet") },
  ];

  const allPriorities = [
    { id: "LOW", label: t("whistleblow.priority.low") },
    { id: "MEDIUM", label: t("whistleblow.priority.medium") },
    { id: "HIGH", label: t("whistleblow.priority.high") },
    { id: "NOT_SET", label: t("whistleblow.priority.notSet") },
  ];

  const allClientUsers = useMemo(() => {
    if (getAllUsersForClientState.value == null) return [];
    return getAllUsersForClientState.value.data.map((user) => {
      return { id: user.id, label: user.firstName + " " + user.lastName };
    });
  }, [getAllUsersForClientState.value]);

  const showProfileHandler = () => {
    navigate(menuPath + "0");
  };

  const showContactHandler = () => {
    navigate(menuPath + "1");
  };

  const showUsersHandler = () => {
    navigate(menuPath + "2");
  };

  const showReportsHandler = () => {
    navigate(menuPath + "3");
  };

  const changeProfileHandler = async (data: any) => {
    // ulozime zmeny na profile zalozce
    const result = await updateClientInfoCall({
      clientId: clientId,
      ico: data.ico,
      name: data.clientName,
      address: {
        street: data.street,
        streetNumber: data.streetNumber,
        city: data.city,
        zip: data.zip,
        country: data.country,
      },
      officerAddress: {
        street: data.officirstreet,
        streetNumber: data.officirstreetNumber,
        city: data.officircity,
        zip: data.officirzip,
        country: data.officircountry,
      },
      defaultLanguage: data.defaultLanguage,
      daysToSolve: getClientState.value.daysToSolve,
      defaultOfficerId: getClientState.value.officer.id,
      defaultCategory: getClientState.value.defaultCategory,
      defaultPriority: getClientState.value.defaultPriority,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", 1500);
      setTimeout(() => refresh(), 1500);
    }
  };

  const setSelectedOfficierHandler = async (data: any) => {
    const result = await updateClientInfoCall({
      clientId: clientId,
      name: getClientState.value.name,
      ico: getClientState.value.ico,
      defaultOfficerId: data,
      address: getClientState.value.address,
      officerAddress: getClientState.value.officerAddress,
      daysToSolve: getClientState.value.daysToSolve,
      defaultCategory: getClientState.value.defaultCategory,
      defaultPriority: getClientState.value.defaultPriority,
      defaultLanguage: getClientState.value.defaultLanguage,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", 1500);
      setTimeout(() => refresh(), 1500);
    }
  };

  const reportSettingHandler = async (data: any) => {
    const result = await updateClientInfoCall({
      clientId: clientId,
      name: getClientState.value.name,
      ico: getClientState.value.ico,
      address: getClientState.value.address,
      officerAddress: getClientState.value.officerAddress,
      daysToSolve: data.daysToSolve,
      defaultOfficerId: data.defaultOfficier,
      defaultCategory: data.category !== "NOT_SET" ? data.category : null,
      defaultPriority: data.priority !== "NOT_SET" ? data.priority : null,
      defaultLanguage: getClientState.value.defaultLanguage,
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", 1500);
      setTimeout(() => refresh(), 1500);
    }
  };

  const updateUserInfoHandler = async (data: {
    firstName: string;
    lastName: string;
    phone: string;
  }) => {
    // updatneme User info
    const result = await updateUserCall({
      clientId: clientId,
      userId: getClientState.value.officer.id,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      role: "ADMIN",
    });
    if (typeof result === "object" && result) {
      showSuccess(t("whistleblow.message.save"), "", messageTime);
      setTimeout(() => {
        refresh();
      }, messageTime);
    }
  };

  useEffect(() => {
    if (ctx.isLoggedIn) {
      getClientCall({ id: clientId });
      getAllUsersForClientCall({ clientId: clientId });
    }
  }, [clientId, getClientCall, getAllUsersForClientCall, ctx.isLoggedIn]);

  return (
    <Stack className="main">
      <Navigation />
      {!ctx.isLoggedIn && <Unavailable />}
      {ctx.isLoggedIn && (
        <Stack className="px_main" sx={{ mt: 14, mb: 8 }}>
          <Typography variant="apph1Bold" sx={{}}>
            {t("whistleblow.settings.client.title")}
          </Typography>
          <Stack
            sx={{
              mt: 4.5,
              flexDirection: { md: "column", lg: "row" },
            }}
          >
            <List
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "flex",
                  lg: "inline-block",
                },
                flexDirection: {
                  xs: "row",
                  sm: "row",
                  md: "row",
                  lg: "column",
                },
                flexGrow: { xs: 1, sm: 1, md: 1, lg: 0 },
                width: { smd: "auto", lg: "300px" },
              }}
            >
              <ListItemButton
                id="profile"
                onClick={showProfileHandler}
                selected={selectedMenu === 0}
              >
                {t("whistleblow.settings.client.profile")}
              </ListItemButton>
              <ListItemButton
                id="contact"
                onClick={showContactHandler}
                selected={selectedMenu === 1}
              >
                {t("whistleblow.settings.client.contact")}
              </ListItemButton>
              <ListItemButton
                id="users"
                onClick={showUsersHandler}
                selected={selectedMenu === 2}
              >
                {t("whistleblow.settings.client.users")}
              </ListItemButton>
              <ListItemButton
                id="reports"
                onClick={showReportsHandler}
                selected={selectedMenu === 3}
              >
                {t("whistleblow.settings.client.reports")}
              </ListItemButton>
            </List>
            <Stack sx={{ ml: { md: 0, lg: 5 }, mt: { md: 2, lg: 0 } }}>
              {selectedMenu === 0 && (
                <Stack className="form_600">
                  <Typography variant="apph2Bold">
                    {t("whistleblow.settings.client.profile")}
                  </Typography>
                  <LoadingGuard state={getClientState}>
                    {getClientState.value && (
                      <FormContainer
                        defaultValues={{
                          clientName: getClientState.value.name,
                          ico: getClientState.value.ico
                            ? getClientState.value.ico
                            : "",
                          street:
                            getClientState.value.address &&
                            getClientState.value.address.street
                              ? getClientState.value.address.street
                              : "",
                          streetNumber:
                            getClientState.value.address &&
                            getClientState.value.address.streetNumber
                              ? getClientState.value.address.streetNumber
                              : "",
                          city:
                            getClientState.value.address &&
                            getClientState.value.address.city
                              ? getClientState.value.address.city
                              : "",
                          zip:
                            getClientState.value.address &&
                            getClientState.value.address.zip
                              ? getClientState.value.address.zip
                              : "",
                          country:
                            getClientState.value.address &&
                            getClientState.value.address.country
                              ? getClientState.value.address.country
                              : "-",
                          officirstreet:
                            getClientState.value.officerAddress &&
                            getClientState.value.officerAddress.street
                              ? getClientState.value.officerAddress.street
                              : "",
                          officirstreetNumber:
                            getClientState.value.officerAddress &&
                            getClientState.value.officerAddress.streetNumber
                              ? getClientState.value.officerAddress.streetNumber
                              : "",
                          officircity:
                            getClientState.value.officerAddress &&
                            getClientState.value.officerAddress.city
                              ? getClientState.value.officerAddress.city
                              : "",
                          officirzip:
                            getClientState.value.officerAddress &&
                            getClientState.value.officerAddress.zip
                              ? getClientState.value.officerAddress.zip
                              : "",
                          officircountry:
                            getClientState.value.officerAddress &&
                            getClientState.value.officerAddress.country
                              ? getClientState.value.officerAddress.country
                              : "-",
                          defaultLanguage: getClientState.value.defaultLanguage
                            ? getClientState.value.defaultLanguage
                            : "en",
                        }}
                        onSuccess={changeProfileHandler}
                      >
                        <Stack>
                          <TextFieldElement
                            name="clientName"
                            label={t("whistleblow.settings.client.companyName")}
                            sx={{ mt: 2 }}
                          />
                          <TextField
                            id="domain"
                            label={t("whistleblow.settings.client.domain")}
                            value={t("whistleblow.domain") + clientId}
                            disabled
                            inputProps={{ readOnly: true }}
                            sx={{ mt: 2 }}
                          />

                          <TextFieldElement
                            name="ico"
                            label={t("whistleblow.settings.client.ico")}
                            className="form_200"
                            sx={{ mt: 2 }}
                          />
                          <Typography variant="textBold" sx={{ mt: 2 }}>
                            {t("whistleblow.settings.client.address")}
                          </Typography>
                          <Stack
                            sx={{
                              mt: 2,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <TextFieldElement
                              name="street"
                              label={t("whistleblow.settings.client.street")}
                            />
                            <TextFieldElement
                              name="streetNumber"
                              label={t(
                                "whistleblow.settings.client.streetNumber"
                              )}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              mt: 2,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <TextFieldElement
                              name="city"
                              label={t("whistleblow.settings.client.city")}
                              sx={{}}
                            />
                            <TextFieldElement
                              name="zip"
                              label={t("whistleblow.settings.client.zip")}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                            <TextFieldElement
                              name="country"
                              label={t("whistleblow.settings.client.country")}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                          </Stack>
                          <Typography variant="textBold" sx={{ mt: 2 }}>
                            {t("whistleblow.settings.client.officirAddress")}
                          </Typography>
                          <Stack
                            sx={{
                              mt: 2,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <TextFieldElement
                              name="officirstreet"
                              label={t(
                                "whistleblow.settings.client.officirStreet"
                              )}
                            />
                            <TextFieldElement
                              name="officirstreetNumber"
                              label={t(
                                "whistleblow.settings.client.officirStreetNumber"
                              )}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                          </Stack>
                          <Stack
                            sx={{
                              mt: 2,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <TextFieldElement
                              name="officircity"
                              label={t(
                                "whistleblow.settings.client.officirCity"
                              )}
                              sx={{}}
                            />
                            <TextFieldElement
                              name="officirzip"
                              label={t(
                                "whistleblow.settings.client.officirZip"
                              )}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                            <TextFieldElement
                              name="officircountry"
                              label={t(
                                "whistleblow.settings.client.officirCountry"
                              )}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                              }}
                            />
                          </Stack>
                          <SelectElement
                            name="defaultLanguage"
                            label={t(
                              "whistleblow.settings.client.defaultLanguage"
                            )}
                            options={allLanguages}
                            className="form_200"
                            sx={{ mt: 2 }}
                          />
                          <Stack direction="row" sx={{ mt: 2 }}>
                            <div className="space"></div>
                            <AsyncButton state={updateClientInfoState}>
                              {t("whistleblow.common.saveChanges")}
                            </AsyncButton>
                          </Stack>
                        </Stack>
                      </FormContainer>
                    )}
                  </LoadingGuard>
                </Stack>
              )}
              {selectedMenu === 1 && (
                <Stack sx={{}}>
                  <Typography variant="apph2Bold">
                    {t("whistleblow.settings.client.contact")}
                  </Typography>
                  <LoadingGuard state={getClientState}>
                    {getClientState.value && (
                      <React.Fragment>
                        <FormContainer
                          defaultValues={{
                            defaultOfficier: getClientState.value.officer
                              ? getClientState.value.officer.id
                              : undefined,
                          }}
                          onSuccess={setSelectedOfficierHandler}
                        >
                          <Stack
                            sx={{
                              my: 2,
                              flexDirection: { xs: "column", sm: "row" },
                              alignItems: { xs: "left", sm: "center" },
                            }}
                          >
                            <SelectElement
                              name="defaultOfficier"
                              label={t(
                                "whistleblow.settings.client.contactOfficier"
                              )}
                              options={allClientUsers}
                              onChange={setSelectedOfficierHandler}
                              sx={{ minWidth: "200px" }}
                            />

                            {/* <AsyncButton
                            sx={{
                              ml: { xs: 0, sm: 2 },
                              mt: { xs: 2, sm: 0 },
                            }}
                          >
                            {t("whistleblow.common.selectOfficier")}
                          </AsyncButton> */}
                          </Stack>
                        </FormContainer>
                        {getClientState.value.officer && (
                          <React.Fragment>
                            <Divider />
                            <FormContainer
                              defaultValues={{
                                firstName:
                                  getClientState.value.officer.firstName,
                                lastName: getClientState.value.officer.lastName,
                                phone: getClientState.value.officer.phone,
                              }}
                              onSuccess={updateUserInfoHandler}
                            >
                              <Stack sx={{ mt: 2, mb: 2, alignItems: "left" }}>
                                <TextFieldElement
                                  name="firstName"
                                  label={t("whistleblow.account.firstName")}
                                  validation={required}
                                  sx={{
                                    width: { sm: "auto", md: columnWidth },
                                  }}
                                />
                              </Stack>
                              <Stack sx={{ my: 2, alignItems: "left" }}>
                                <TextFieldElement
                                  name="lastName"
                                  label={t("whistleblow.account.lastName")}
                                  validation={required}
                                  sx={{
                                    width: { sm: "auto", md: columnWidth },
                                  }}
                                />
                              </Stack>
                              <Stack
                                sx={{
                                  my: 2,
                                  alignItems: "left",
                                  flexDirection: "column",
                                }}
                              >
                                <TextFieldElement
                                  name="phone"
                                  label={t("whistleblow.account.phone")}
                                  validation={{
                                    ...required,
                                    ...phoneValidation,
                                  }}
                                  sx={{
                                    width: { sm: "auto", md: columnWidth },
                                  }}
                                />
                                <Stack
                                  direction="row"
                                  sx={{
                                    mt: 2,
                                    width: { sm: "auto", md: columnWidth },
                                  }}
                                >
                                  <div className="space" />
                                  <AsyncButton state={updateUserState}>
                                    <Typography variant="textBase">
                                      {t("whistleblow.common.save")}
                                    </Typography>
                                  </AsyncButton>
                                </Stack>
                              </Stack>
                            </FormContainer>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    )}
                  </LoadingGuard>
                </Stack>
              )}
              {selectedMenu === 2 && (
                <Stack sx={{ width: { sm: "auto", md: "620px" } }}>
                  <Typography variant="apph2Bold" sx={{}}>
                    {t("whistleblow.settings.client.users")}
                  </Typography>
                  <UserPage />
                </Stack>
              )}
              {selectedMenu === 3 && (
                <Stack sx={{ width: { sm: "auto", md: "600px" } }}>
                  <Typography variant="apph2Bold">
                    {t("whistleblow.settings.client.reports")}
                  </Typography>
                  <LoadingGuard state={getClientState}>
                    {getClientState.value && (
                      <FormContainer
                        defaultValues={{
                          daysToSolve: getClientState.value.daysToSolve,
                          defaultOfficier: getClientState.value.officer
                            ? getClientState.value.officer.id
                            : undefined,
                          priority: getClientState.value.defaultPriority
                            ? getClientState.value.defaultPriority
                            : "NOT_SET",
                          category: getClientState.value.defaultCategory
                            ? getClientState.value.defaultCategory
                            : "NOT_SET",
                        }}
                        onSuccess={reportSettingHandler}
                      >
                        <Stack>
                          <TextFieldElement
                            name="daysToSolve"
                            label={t("whistleblow.settings.client.daysToSolve")}
                            sx={{ mt: 2 }}
                          />
                          <SelectElement
                            name="defaultOfficier"
                            label={t(
                              "whistleblow.settings.client.defaultOfficier"
                            )}
                            sx={{ mt: 2 }}
                            options={allClientUsers}
                          />
                          <Stack
                            sx={{
                              mt: 2,
                              flexDirection: { xs: "column", sm: "row" },
                            }}
                          >
                            <SelectElement
                              name="priority"
                              label={t("whistleblow.settings.client.priority")}
                              sx={{ width: { xs: "auto", sm: "200px" } }}
                              options={allPriorities}
                            />
                            <SelectElement
                              name="category"
                              label={t("whistleblow.settings.client.category")}
                              sx={{
                                ml: { xs: 0, sm: 2 },
                                mt: { xs: 2, sm: 0 },
                                width: { xs: "auto", sm: "200px" },
                              }}
                              options={allCategories}
                            />
                          </Stack>
                          <Stack direction="row" sx={{ mt: 2 }}>
                            <div className="space"></div>
                            <AsyncButton sx={{}}>
                              {t("whistleblow.common.saveChanges")}
                            </AsyncButton>
                          </Stack>
                        </Stack>
                      </FormContainer>
                    )}
                  </LoadingGuard>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}
      <div className="space" />
      <SmallFooter />
    </Stack>
  );
};
