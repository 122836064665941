import { TextField } from "@mui/material";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { Controller, ControllerProps } from "react-hook-form";

type DateElementProps = {
  name: string;
  dateLabel: string;
  timeLabel: string;
  helperTextDate?: string;
  helperTextTime?: string;
  validation?: ControllerProps["rules"];
  disabledInput?: boolean;
};

export const WhistleDateTimePickerElement = (props: DateElementProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={props.name}
        rules={props.validation}
        render={({
          field: { value, onChange },
          fieldState: { invalid, error },
        }) => (
          <React.Fragment>
            <DatePicker
              value={value ? value : null}
              onChange={(v) => onChange(v)}
              label={props.dateLabel}
              inputFormat="DD.MM.YYYY"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={invalid}
                  helperText={error ? error.message : props.helperTextDate}
                />
              )}
            />
            <TimePicker
              value={value ? value : null}
              onChange={(v) => onChange(v)}
              label={props.timeLabel}
              ampm={false}
              inputFormat="HH:mm"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  error={invalid}
                  helperText={error ? error.message : props.helperTextTime}
                />
              )}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};
