import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Link,
  Stack,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  FormContainer,
  PasswordElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { defineStyles } from "../../styles/defineStyles";
import AuthContext from "../contexts/AuthContext";
import { useRequiredValidation } from "../models/settings";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import {
  ChangePasswordDTO,
  ChangePasswordRequestDTO,
  LoginUserDTO,
} from "../../api/types";
import AlertContext from "../contexts/AlertContext";
import { useMinLengthValidation } from "../form/validations/useMinLengthValidation";
import { useMaxLengthValidation } from "../form/validations/useMaxLengthValidation";
import UiContext from "../contexts/UiContext";
import Navigation from "../Navigation";

import GoogleButton, { GoogleScript } from "./GoogleButton";
import "../../index.css";

type LoginPhase = "login" | "request" | "change";
type LoginFormType =
  | LoginUserDTO
  | ChangePasswordDTO
  | ChangePasswordRequestDTO;

const style = defineStyles({
  all: {
    width: "100vw",
    height: "100vh",
    position: "relative",
  },
  card: {
    mt: 14,
    alignSelf: "center",
    width: { xs: "300px", sm: "350px" },
    height: "auto",
  },
  formGroup: {},
  textField: {
    mb: 2,
    width: "100%",
  },
  button: {
    width: "100%",
    mb: 2,
  },
  link: {
    pl: 2,
  },
});

const Login = () => {
  const ctx = useContext(AuthContext);
  const { refresh } = useContext(UiContext);
  const api = useApiClient();
  const { t } = useTranslation();
  const { showSuccess } = useContext(AlertContext);
  const [phase, setPhase] = useState<LoginPhase>("login");

  const gotoRequest = useCallback(() => setPhase("request"), []);
  const gotoLogin = useCallback(() => setPhase("login"), []);
  const gotoChange = useCallback(() => setPhase("change"), []);

  const isLogin = phase === "login";
  const isRequest = phase === "request";
  const isChange = phase === "change";

  const [loginCall, loginState] = useSafeAsync(api.loginUser);
  const [changePasswordRequestCall, changePasswordRequestState] = useSafeAsync(
    api.changePasswordRequest
  );
  const [changePasswordCall, changePasswordState] = useSafeAsync(
    api.changePassword
  );

  const required = useRequiredValidation();
  const minPasswordLength = useMinLengthValidation(8);
  const maxPasswordLength = useMaxLengthValidation(128);

  useEffect(() => {
    if (loginState.value) {
      ctx.setLoggedUser(loginState.value);
    }
  }, [ctx, loginState.value]);

  const handleForm = useCallback(
    async (data: LoginFormType) => {
      if (isLogin) {
        loginCall(data as LoginUserDTO);
      }
      if (isRequest) {
        changePasswordRequestCall(data as ChangePasswordRequestDTO);
        gotoChange();
      }
      if (isChange) {
        const result = await changePasswordCall(data as ChangePasswordDTO);
        if (typeof result === "boolean" && result) {
          showSuccess(
            t("whistleblow.login.passwordChanged", "Password was changed"),
            t("whistleblow.login.youCanLog", "You can login now.")
          );
          gotoLogin();
        }
      }
    },
    [
      changePasswordCall,
      changePasswordRequestCall,
      gotoChange,
      gotoLogin,
      isChange,
      isLogin,
      isRequest,
      loginCall,
      showSuccess,
      t,
    ]
  );

  return (
    <Stack className="main" sx={{ mb: 8 }}>
      <GoogleScript />
      <Card sx={style.card} elevation={3}>
        {isLogin && (
          <CardHeader
            title={t("whistleblow.login.login", "Login")}
            subheader=""
          />
        )}
        {isRequest && (
          <CardHeader
            title={t(
              "whistleblow.login.requestTitle",
              "Step 1/2 - sending code"
            )}
            subheader={t(
              "whistleblow.login.requestDescription",
              "Put you email address, we send you onetime code for password change. Dont show this code to anyone!"
            )}
          />
        )}
        {isChange && (
          <CardHeader
            title={t(
              "whistleblow.login.changeTitle",
              "Step 2/2 - password change"
            )}
            subheader={t(
              "whistleblow.login.changeDescription",
              "Enter the code, we have send to your email."
            )}
          />
        )}

        <CardContent>
          <FormContainer defaultValues={{}} onSuccess={handleForm}>
            {(isLogin || isRequest) && (
              <TextFieldElement
                name="email"
                validation={required}
                label={t("whistleblow.common.email", "Email")}
                autoComplete={"email"}
                sx={style.textField}
              />
            )}
            {isLogin && (
              <PasswordElement
                name="password"
                validation={{
                  ...required,
                  ...minPasswordLength,
                  ...maxPasswordLength,
                }}
                label={t("whistleblow.login.password", "Password")}
                autoComplete={"current-password"}
                sx={style.textField}
              />
            )}
            {isChange && (
              <TextFieldElement
                name="token"
                validation={required}
                label={t(
                  "whistleblow.login.passwordChangeCode",
                  "Code for password change"
                )}
                autoComplete={"one-time-code"}
                sx={style.textField}
              />
            )}
            {isChange && (
              <PasswordElement
                name="newPassword"
                validation={{
                  ...required,
                  ...minPasswordLength,
                  ...maxPasswordLength,
                }}
                label={t("whistleblow.login.newPassword", "New password")}
                autoComplete={"new-password"}
                sx={style.textField}
              />
            )}
            <Button type="submit" variant="contained" sx={style.button}>
              {isLogin && t("whistleblow.login.login", "Login")}
              {isRequest && t("whistleblow.login.getCode", "Get code")}
              {isChange &&
                t("whistleblow.login.changePassword", "Change password")}
            </Button>

            {/*  {isLogin && <GoogleButton sx={style.button}></GoogleButton>} */}
          </FormContainer>
        </CardContent>
        <CardActions>
          {isLogin && (
            <Link underline="hover" sx={style.link} onClick={gotoRequest}>
              {t("whistleblow.login.forgottenPassword", "Forgotten password")}
            </Link>
          )}
          {!isLogin && (
            <Link underline="hover" sx={style.link} onClick={gotoLogin}>
              {t("whistleblow.login.backToLogin", "Back to login")}
            </Link>
          )}
        </CardActions>
      </Card>
    </Stack>
  );
};

export default Login;
