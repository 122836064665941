import { Divider, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

import { defineStyles } from "../../styles/defineStyles";
import CustomDialog from "../ui/CustomDialog";
import { useSafeAsync } from "../../hooks/useSafeAsync";
import { useApiClient } from "../../api/useApiClient";
import { LoadingGuard } from "../form/LoadingGuard";
import UiContext from "../contexts/UiContext";
import { useRequiredValidation } from "../form/validations/useRequiredValidation";
import { AsyncButton } from "../form/AsyncButton";
import AlertContext from "../contexts/AlertContext";
import UserPage from "../User/UserPage";
import Navigation from "../Navigation";
import AuthContext from "../contexts/AuthContext";
import NotAllowed from "../ui/NotAllowed";
import SmallFooter from "../ui/SmallFooter";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ClientDetail: React.FC = () => {
  const styles = defineStyles({
    infoItem: {
      mt: 3,
      width: "100%",
    },
    mainTabs: {
      width: "100%",
    },
    tabs: {},
    icon: {
      height: "24px",
      width: "24px",
      m: 0,
    },
  });

  const urlData = useParams();
  const api = useApiClient();
  const companyId = urlData.companyId;
  const [currentCompanyCall, currentCompanyState] = useSafeAsync(
    api.findClientById
  );
  const ctx = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { refresh } = useContext(UiContext);
  const required = useRequiredValidation();
  const { showSuccess } = useContext(AlertContext);

  const [updateClientCall, updateClientState] = useSafeAsync(api.updateClient);
  //const [deleteClientCall, deleteClientState] = useSafeAsync(api.deleteClient);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const onRemoveFuncitonHandler = () => {
    setShowDialog(true);
  };

  const closeHandler = () => {
    setShowDialog(false);
  };

  const changeClientHanddler = useCallback(
    async (data: {
      clientId: string;
      clientName: string;
      clientIco: string;
    }) => {
      console.log(JSON.stringify(data));
      //zmena nazvu/popisu spolecnosti
      const result = await updateClientCall({
        clientId: data.clientId,
        name: data.clientName,
        ico: data.clientIco,
      });
      if (typeof result === "object" && result) {
        refresh();
      }
    },
    [updateClientCall, refresh]
  );

  const onRemoveCompanyHandler = async () => {
    setShowDialog(false);
    // remove company and request+events
    /* const result = await deleteClientCall({ clientId: companyId });
    if (typeof result === "boolean" && result) {
      showSuccess(
        t("whistleblow.client.client", "Client") + " " +
          currentCompanyState.value.name +
          " " + t("whistleblow.client.wasDeleted", "was deleted."),
        ""
      );
    }
    if (result !== false) {
      navigate(-1);
    } */
  };

  const refreshClientRequestsHandler = () => {
    refresh();
  };

  useEffect(() => {
    currentCompanyCall({ id: companyId });
  }, [currentCompanyCall, companyId]);

  return (
    <Stack className="main">
      <Navigation />
      {(!ctx.isLoggedIn ||
        (ctx.isLoggedIn && ctx.loggedUser.userDTO.role !== "SUPER_ADMIN")) && (
        <NotAllowed />
      )}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "SUPER_ADMIN" && (
        <Stack className="px_main" sx={{ mt: 14 }}>
          {/* <Stack direction="row" sx={{ width: "100vw" }}>
            <div className="space"></div>
            <Button
              variant="contained"
              color="error"
              onClick={onRemoveFuncitonHandler}
              sx={{ mt: 5, mr: 5 }}
            >
              {t("whistleblow.client.remove", "Remove client")}
            </Button>
          </Stack> */}
          <LoadingGuard state={currentCompanyState}>
            {currentCompanyState.value && (
              <Stack className="form_600" sx={{ alignSelf: "center" }}>
                <Typography variant="apph1Bold">
                  {t("whistleblow.client.basicInfo", "About company")}
                </Typography>
                <FormContainer
                  defaultValues={{
                    clientId: currentCompanyState.value.clientId,
                    clientName: currentCompanyState.value.name,
                    clientIco: currentCompanyState.value.ico
                      ? currentCompanyState.value.ico
                      : "-",
                  }}
                  onSuccess={changeClientHanddler}
                >
                  <TextFieldElement
                    variant="outlined"
                    name="clientId"
                    id="clientId"
                    validation={required}
                    label={t("whistleblow.client.id", "Url id of a client")}
                    sx={styles.infoItem}
                  />
                  <TextFieldElement
                    variant="outlined"
                    name="clientName"
                    id="clientName"
                    validation={required}
                    label={t("whistleblow.client.name", "Client name")}
                    sx={styles.infoItem}
                  />
                  <TextFieldElement
                    variant="outlined"
                    name="clientIco"
                    id="clientIco"
                    validation={required}
                    label={t("whistleblow.client.ico", "Company ID")}
                    sx={styles.infoItem}
                  />
                  <Stack direction="row" sx={styles.infoItem}>
                    <div className="space"></div>
                    <AsyncButton state={updateClientState}>
                      {t("whistleblow.common.saveChanges", "Save changes")}
                    </AsyncButton>
                  </Stack>
                </FormContainer>
              </Stack>
            )}
          </LoadingGuard>
          <Divider sx={{ my: 3 }} />
          <Stack
            sx={{
              alignSelf: "center",
              width: { xs: "100%", sm: "100%", md: "600px" },
            }}
          >
            <UserPage />
          </Stack>
        </Stack>
      )}
      {showDialog && (
        <CustomDialog
          openDialog={showDialog}
          closed={closeHandler}
          title={t("whistleblow.client.remove", "Remove client")}
          subtitle={t(
            "whistleblow.client.removeDescription",
            "On confirm will be client, all users, reports and all history removed. Are you sure with these action?"
          )}
          commentLabel=""
          commentPlaceholder=""
          buttonText={t("whistleblow.client.removeButton", "Remove client")}
          onButtonClick={onRemoveCompanyHandler}
        />
      )}
      <div className="space"></div>
      <SmallFooter />
    </Stack>
  );
};

export default ClientDetail;
