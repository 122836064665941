import { Stack } from "@mui/material";
import React, { useContext } from "react";

import AuthContext from "../contexts/AuthContext";
import Navigation from "../Navigation";
import SmallFooter from "../ui/SmallFooter";

import AdminSettings from "./AdminSettings";

export const AppSettings: React.FC = () => {
  const ctx = useContext(AuthContext);

  return (
    <Stack className="main">
      <Navigation />
      {ctx.isLoggedIn && <AdminSettings />}
      <SmallFooter />
    </Stack>
  );
};
