import {
  Balance,
  LockOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";

import { defineStyles } from "../../styles/defineStyles";

const InfoItem: React.FC<{
  title: string;
  description?: string;
  icon?: "balance" | "lock" | "shield";
}> = (props) => {
  const styles = defineStyles({
    icon: {
      width: { xs: "36px", sm: "36px", md: "48px" },
      height: { xs: "36px", sm: "36px", md: "48px" },
      mr: { xs: 2, sm: 2, md: 5 },
    },
  });

  let icon = null;
  if (props.icon == null) {
    icon = <></>;
  } else {
    if (props.icon === "balance") icon = <Balance sx={styles.icon} />;
    if (props.icon === "lock") icon = <LockOutlined sx={styles.icon} />;
    if (props.icon === "shield")
      icon = <VerifiedUserOutlined sx={styles.icon} />;
  }

  return (
    <Stack direction="row" className="form_600" sx={{ mt: 5 }}>
      {props.icon && (
        <Stack direction="column">
          {icon}
          <div className="space"></div>
        </Stack>
      )}
      <Stack direction="column">
        <Typography variant="apph3Semibold">{props.title}</Typography>
        {props.description && (
          <Typography variant="textBase" sx={{ mt: 2 }}>
            {props.description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default InfoItem;
