import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";

import { defineStyle } from "../styles/defineStyles";

import { AppSettings } from "./settings/AppSettings";
import Anonymous from "./Anonymous";
import SuperAdminPage from "./superAdmin/SuperAdminPage";
import AdminSettings from "./settings/AdminSettings";
import NewReport from "./report/NewReport";
import Unavailable from "./ui/Unavailable";
import CheckStatus from "./status/CheckStatus";
import ClientDetail from "./clients/ClientDetail";
import UserDetail from "./User/UserDetail";
import NewClient from "./clients/NewClient";
import NewUser from "./User/NewUser";
import AdminPage from "./admin/AdminPage";
import ReportDetail from "./report/ReportDetail";
import ReportPage from "./report/ReportPage";
import { ClientSettings } from "./settings/ClientSettings";

const style = defineStyle({
  width: "100vw",
  pt: 8,
});

export const Content: React.FC = () => {
  return (
    <Box>
      <Routes>
        <Route path="*" element={<Unavailable />} />
        <Route path="/app/:companyId" element={<Anonymous />} />
        <Route
          path="/app/:companyId/settings/:menuId"
          element={<ClientSettings />}
        />
        <Route path="/app/:companyId/newReport" element={<NewReport />} />
        <Route path="/app/:companyId/status" element={<CheckStatus />} />
        <Route path="/app/:companyId/admin" element={<AdminPage />} />
        <Route path="/app/:companyId/admin/reports" element={<AdminPage />} />
        <Route
          path="/app/:companyId/admin/reports/:reportId/:menuId"
          element={<ReportDetail />}
        />
        <Route
          path="/app/:companyId/admin/settings"
          element={<AppSettings />}
        />
        <Route path="/app/admin" element={<SuperAdminPage />} />
        <Route path="/app/admin/newClient" element={<NewClient />} />
        {/* <Route path="/app/admin/settings" element={<AppSettings />} /> */}
        <Route path="/app/admin/:companyId" element={<ClientDetail />} />
        <Route path="/app/admin/:companyId/newUser" element={<NewUser />} />
        <Route path="/app/admin/:companyId/:userId" element={<UserDetail />} />
      </Routes>
    </Box>
  );
};
