import { Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import Login from "../login/Login";
import AuthContext from "../contexts/AuthContext";
import Navigation from "../Navigation";
import Unavailable from "../ui/Unavailable";
import ReportPage from "../report/ReportPage";
import SmallFooter from "../ui/SmallFooter";
import LoginSetPassword from "../login/LoginSetPassword";

const AdminPage: React.FC = () => {
  const ctx = useContext(AuthContext);
  const data = useParams();
  const clientId = data.companyId;
  const userId = parseInt(
    new URL(window.location.href).searchParams.get("userId")
  )
    ? parseInt(new URL(window.location.href).searchParams.get("userId"))
    : null;
  const token = new URL(window.location.href).searchParams.get("token");

  return (
    <Stack className="main">
      <Navigation />
      {userId != null && token != null && !ctx.isLoggedIn && (
        <LoginSetPassword userId={userId} token={token} />
      )}
      {!ctx.isLoggedIn && userId == null && token == null && <Login />}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.clientId === clientId && (
        <ReportPage />
      )}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.clientId !== clientId && (
        <Unavailable />
      )}
      <div className="space"></div>
      <SmallFooter />
    </Stack>
  );
};

export default AdminPage;
