import { Chip, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { defineStyles } from "../../styles/defineStyles";

const Chips: React.FC<{
  chip: { label: string; bgColor: string; isWhiteColor: boolean };
}> = (props) => {
  const styles = defineStyles({
    chips: {
      flexWrap: "wrap",
    },
  });

  const { t } = useTranslation();

  /* let labelTitle = "";
  if (props.chip.label === "closed") {
    labelTitle = t("whistleblow.status.closed");
  } else if (props.chip.label === "underReview") {
    labelTitle = t("whistleblow.status.underReview");
  } else if (props.chip.label === "underReviewWhistleblower") {
    labelTitle = t("whistleblow.status.underReviewWhistleblower");
  } else if (props.chip.label === "dismissed") {
    labelTitle = t("whistleblow.status.dismissed");
  } else if (props.chip.label === "new") {
    labelTitle = t("whistleblow.status.new");
  } else if (props.chip.label === "newWhistleblower") {
    labelTitle = t("whistleblow.status.newWhistleblower");
  } else if (props.chip.label === "closedWithAction") {
    labelTitle = t("whistleblow.status.closedWithAction");
  } */

  return (
    <Stack direction="row" sx={styles.chips}>
      <Chip
        label={t(props.chip.label)}
        variant="outlined"
        sx={{
          bgcolor: props.chip.bgColor,
          color: props.chip.isWhiteColor ? "white" : "black",
        }}
      />
    </Stack>
  );
};

export default Chips;
