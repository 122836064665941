import { Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Unavailable: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.location.href = t("whistleblow.common.redirectUnavailableURL");
  }, [t]);
  return (
    <Stack className="main px_main" sx={{ mt: 14, mb: 8 }}>
      {/* <Typography className="px_main" variant="textBold" sx={{ mt: 14 }}>
        {t(
          "whistleblow.common.unavailable",
          "Site is not available with this URL."
        )}
      </Typography>
      <div className="space" /> */}
    </Stack>
  );
};

export default Unavailable;
