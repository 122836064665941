import { Button, Stack } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ClientsTable from "../clients/ClientsTable";
import AuthContext from "../contexts/AuthContext";
import Login from "../login/Login";
import Navigation from "../Navigation";
import NotAllowed from "../ui/NotAllowed";
import SmallFooter from "../ui/SmallFooter";

const SuperAdminPage: React.FC = () => {
  const ctx = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const addClientHandler = () => {
    navigate("/app/admin/newClient");
  };

  return (
    <Stack className="main">
      <Navigation />
      {!ctx.isLoggedIn && <Login />}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.role !== "SUPER_ADMIN" && (
        <NotAllowed />
      )}
      {ctx.isLoggedIn && ctx.loggedUser.userDTO.role === "SUPER_ADMIN" && (
        <Stack direction="column" className="px_main" sx={{ mt: 14, mb: 8 }}>
          <Stack direction="row" sx={{}}>
            <div className="space"></div>
            <Button variant="contained" onClick={addClientHandler} sx={{}}>
              {t("whistleblow.client.add", "Add client")}
            </Button>
          </Stack>
          <ClientsTable />
        </Stack>
      )}
      <div className="space" />
      <SmallFooter />
    </Stack>
  );
};

export default SuperAdminPage;
